import { OperationVariables, TypedDocumentNode } from "@apollo/client/core/types"
import { DocumentNode } from "@apollo/client/link/core/types"
import { useQuery } from "@apollo/client/react/hooks/useQuery"
import { QueryHookOptions, QueryResult } from "@apollo/client/react/types/types"


// May be used to managed loading, errors, etc
const useQueryWrapper = <TData = any, TVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
    return useQuery<TData, TVariables>(query, options)
}

export default useQueryWrapper
