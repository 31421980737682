import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { Fragment, ReactElement } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { renderRoutes } from 'react-router-config'
import { MatxSuspense } from '../../..'
import useNavigation, { Navigation, NavType } from '../../../../app/hooks/useNavigation'
import useSettings from '../../../../app/hooks/useSettings'
import { CustomerGeneric_customer } from '../../../../graphql/queries/typings/CustomerGeneric'
import { GetCustomers_Minimal_customers } from '../../../../graphql/queries/typings/GetCustomers_Minimal'
import SidenavTheme from '../MatxTheme/SidenavTheme/SidenavTheme'
import Footer from '../SharedCompoents/Footer'
import Layout1Sidenav from './Layout1Sidenav'
import Layout1Topbar from './Layout1Topbar'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    contentWrap: ({ width }: { width: string | number }) => {
        return {
            verticalAlign: 'top',
            marginLeft: width,
            transition: 'all 0.3s ease',
            // [theme.breakpoints.up("sm")]: {
            marginRight: 0,
            // },
        }
    },
    topbar: {
        top: 0,
        zIndex: 96,
        background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0))',
        transition: 'all 0.3s ease',
    },
    sidenavMobileOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        background: 'rgba(0, 0, 0, 0.54)',
        zIndex: 110,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
}))

export type CustomerForLayout = Pick<CustomerGeneric_customer, 'id'>

export type LayoutProps = {
    children: ReactElement
} & (
    | {
          zone: NavType.aea
          customers: GetCustomers_Minimal_customers[]
      }
    | {
          zone: NavType.aeaCustomer
          customers: GetCustomers_Minimal_customers[]
          customer: CustomerForLayout | null
      }
    | {
          zone: NavType.customer
          customer: CustomerForLayout | null
      }
)

const Layout = ({ children, ...props }: LayoutProps) => {
    const { settings } = useSettings()
    const { layout1Settings } = settings
    const {
        leftSidebar: { mode: sidenavMode, show: showSidenav },
    } = layout1Settings

    const { getAeaRootNavigations, getAeaCustomerNavigation, getCustomerNavigation } = useNavigation()

    const getCurrentNavigation = (): Navigation[] => {
        if (props.zone === NavType.aea) return getAeaRootNavigations()
        else if (props.zone === NavType.aeaCustomer) {
            if (!props.customer) return []

            const customerID = props.customer.id
            return getAeaCustomerNavigation(customerID)
        } else return getCustomerNavigation()
    }

    const getSidenavWidth = () => {
        switch (sidenavMode) {
            case 'full':
                return 'var(--sidenav-width)'
            case 'compact':
                return 'var(--sidenav-compact-width)'
            default:
                return '0px'
        }
    }

    const { updateSettings } = useSettings()

    const updateSidebarMode = (sidebarSettings: any) => {
        let activeLayoutSettingsName = settings.activeLayout + 'Settings'
        let activeLayoutSettings = settings[activeLayoutSettingsName as 'layout1Settings']

        updateSettings({
            ...settings,
            [activeLayoutSettingsName]: {
                ...activeLayoutSettings,
                leftSidebar: {
                    ...activeLayoutSettings.leftSidebar,
                    ...sidebarSettings,
                },
            },
        })
    }

    const sidenavWidth = getSidenavWidth()
    let classes = useStyles({ width: sidenavWidth })
    const theme = useTheme()

    const topbarTheme = settings.themes[layout1Settings.topbar.theme]
    const layoutClasses = `theme-${theme.palette.type} flex`

    const navigation = getCurrentNavigation()

    const closeSideBar = () => {
        updateSidebarMode({ mode: 'close' })
    }

    return (
        <div className={clsx('bg-default', layoutClasses)}>
            {showSidenav && sidenavMode !== 'close' && (
                <Fragment>
                    <div onClick={closeSideBar} className={classes.sidenavMobileOverlay} />
                    <SidenavTheme zone={props.zone}>
                        <Layout1Sidenav navigation={navigation} />
                    </SidenavTheme>
                </Fragment>
            )}

            <div className={clsx('flex-grow flex-column relative overflow-hidden h-full-screen', classes.contentWrap)}>
                {layout1Settings.topbar.show && layout1Settings.topbar.fixed && (
                    <ThemeProvider theme={topbarTheme}>
                        <Layout1Topbar />
                        {/* <Layout1Topbar fixed={true} className="elevation-z8" /> */}
                    </ThemeProvider>
                )}

                {settings.perfectScrollbar && (
                    <Scrollbar className="flex-grow flex-column relative h-full">
                        {layout1Settings.topbar.show && !layout1Settings.topbar.fixed && (
                            <ThemeProvider theme={topbarTheme}>
                                <Layout1Topbar />
                            </ThemeProvider>
                        )}
                        <div className="relative flex-grow">
                            <MatxSuspense>{renderRoutes(navigation)}</MatxSuspense>
                        </div>
                        {settings.footer.show && !settings.footer.fixed && <Footer />}
                    </Scrollbar>
                )}

                {!settings.perfectScrollbar && (
                    <div className="flex-grow flex-column relative h-full scroll-y">
                        {layout1Settings.topbar.show && !layout1Settings.topbar.fixed && (
                            <ThemeProvider theme={topbarTheme}>
                                <Layout1Topbar />
                            </ThemeProvider>
                        )}
                        <div className="relative flex-grow" style={{ backgroundColor: '#dee9f1' }}>
                            {/* <MatxSuspense>{renderRoutes(navigation)}</MatxSuspense> */}

                            {children}
                        </div>
                        {settings.footer.show && !settings.footer.fixed && <Footer />}
                    </div>
                )}

                {settings.footer.show && settings.footer.fixed && <Footer />}
            </div>
        </div>
    )
}

export default Layout
