import React from 'react'
import { GetContracts_contracts } from '../../../../../graphql/queries/typings/GetContracts'
import { BreadcrumbSegment } from '../../../../../matx/components/Breadcrumb'
import { Disaster_Full } from '../../../../helpers/data/models/disaster/Disaster'
import { DisasterEvent_Minimal } from '../../../../helpers/data/models/disaster/DisasterEvent'
import ErrorDisplay from '../../../../helpers/errors/ErrorDisplay'
import IconHelper from '../../../../helpers/IconHelper'
import { RouteDescriptor, useRouteInput } from '../../../../helpers/RoutesHelper'
import { useCommonDataLoadedExisting } from '../../../../hooks/useCommonData'
import { useContractsLoadedExisting } from '../../../../hooks/useContracts'
import useDisaster, { ProvideDisaster, useDisasterLoadedExisting } from '../../../../hooks/useDisaster'
import { useNavContext } from '../../../../hooks/useNavContext'
import CustomTabs, { BreacrumbGetter, CustomTab } from '../../../CustomTabs'
import LoadingDisplay from '../../../LoadingDisplay'
import DisasterDocuments from './documents/DisasterDocuments'
import DisasterElements from './elements/DisasterElements'
import DisasterGeneral from './general/DisasterGeneral'
import DisasterIndemnity from './indemnities/DisasterIndemnity'

const Disaster = <T extends { disasterId: string; tab?: DisasterTabName }>({
    route,
    getBreadcrumbSegments,
}: {
    route: RouteDescriptor<T>
    getBreadcrumbSegments: BreacrumbGetter<Disaster_Full>
}) => {
    const { disasterId, tab } = useRouteInput(route).params

    return (
        <ProvideDisaster disasterId={disasterId}>
            <DisasterLoading tab={tab} getBreadcrumbSegments={getBreadcrumbSegments} />
        </ProvideDisaster>
    )
}

export type DisasterTabName = 'general' | 'elements' | 'indemnisations' | 'documents'

type DisasterNavProps = {
    tab?: DisasterTabName
    getBreadcrumbSegments: BreacrumbGetter<Disaster_Full>
}

const DisasterLoading = (props: DisasterNavProps) => {
    const { disaster, disasterLoading, contractCoveredElements, contractCoveredElementsLoading } = useDisaster()
    const { contracts } = useContractsLoadedExisting()
    const { disastersEvents } = useCommonDataLoadedExisting()

    return disasterLoading || contractCoveredElementsLoading ? (
        <LoadingDisplay />
    ) : !disaster ? (
        <ErrorDisplay message={'Sinistre non chargé'} />
    ) : !contractCoveredElements ? (
        <ErrorDisplay message={'Eléments couverts par le contrat non chargés'} />
    ) : !disastersEvents ? (
        <ErrorDisplay message={'Evenements de sinistre non chargés'} />
    ) : (
        <DisasterZoneReady {...props} disastersEvents={disastersEvents} contracts={contracts} />
    )
}

type DisasterZoneReadyProps = DisasterNavProps & {
    disastersEvents: DisasterEvent_Minimal[]
    contracts: GetContracts_contracts[]
}

const DisasterZoneReady = ({ tab, getBreadcrumbSegments, disastersEvents, contracts }: DisasterZoneReadyProps) => {
    const { disaster } = useDisasterLoadedExisting()

    const { getDisasterRoute, getDisastersRoute } = useNavContext()

    const tabs: CustomTab<DisasterTabName>[] = [
        {
            id: 'general',
            label: 'Général',
            children: <DisasterGeneral disastersEvents={disastersEvents} contracts={contracts} />,
            to: getDisasterRoute(disaster.id),
        },
        {
            id: 'indemnisations',
            label: 'Indemnisations',
            children: <DisasterIndemnity />,
            to: getDisasterRoute(disaster.id, 'indemnisations'),
        },
        {
            id: 'elements',
            label: 'Eléments sinistrés',
            children: <DisasterElements />,
            to: getDisasterRoute(disaster.id, 'elements'),
        },
        {
            id: 'documents',
            label: 'Documents',
            children: <DisasterDocuments />,
            to: getDisasterRoute(disaster.id, 'documents'),
        },
    ]

    const breadcrumbSegments: BreadcrumbSegment[] = [
        {
            name: 'Sinistres',
            path: getDisastersRoute().pathname,
        },
        {
            name: disaster.title,
            icon: IconHelper.elementIcons.disasterIcon,
            path: getDisasterRoute(disaster.id).pathname,
        },
    ]

    return (
        <CustomTabs<Disaster_Full, DisasterTabName>
            breadcrumbSegments={breadcrumbSegments}
            activeTab={tab || 'general'}
            tabs={tabs}
        />
    )
}

export default Disaster
