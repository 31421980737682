import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    ThemeProvider,
} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useState } from 'react'
import { isStringInputValid } from '../../../utilsTs'
import useGetVehicles from '../../helpers/data/getVehicles'
import { Vehicle_Minimal } from '../../helpers/data/models/fleet/Vehicle'
import ErrorDisplay from '../../helpers/errors/ErrorDisplay'
import { useErrorService } from '../../helpers/errors/ErrorService'
import VehicleHelper from '../../helpers/VehicleHelper'
import { useCustomerLoadedExisting } from '../../hooks/useCustomer'
import { useDeclareDisaster } from '../../hooks/useMutations'
import useSettings from '../../hooks/useSettings'
import CustomButton from '../CustomButton'
import LoadingDisplay from '../LoadingDisplay'
import MultiSelectCheckbox from '../customInputs/MultiSelectCheckbox'

type DisasterFormDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

type DisasterFormData = {
    description: string | null
    estimation: 'lower' | 'between' | 'upper' | null
    vehiclesIds: string[]
    placesIds: string[]
    hasBodyDamages: boolean
    disasterType: string | null
}

const emptyDisaster: DisasterFormData = {
    description: null,
    estimation: null,
    vehiclesIds: [],
    placesIds: [],
    hasBodyDamages: false,
    disasterType: null,
}

const lightDisasterCategories: string[] = [
    'Bris de glace',
    'Accident de la circulation',
    'Dommages électriques',
    'Incendie, explosion',
    'Choc contre un véhicule terrestre à moteur',
    'Catastrophes naturelles',
    "Perte d'exploitation",
    'Responsabilité civile',
    'Emeutes',
    'Attentats ou actes de terrorisme',
    'Vol',
    'Vandalisme',
    'Dégât des eaux',
    'Protection juridique',
    'Inondation',
    'Tempête, ouragan, cyclone',
    'Grêle',
    'Bris de machines',
    'Perte de liquides',
    'Cyber risque',
    'Perte de marchandises sous température régulée',
]
    .sort()
    .concat(['Autres'])

const DisasterFormDialog = (props: DisasterFormDialogProps) => {
    const { settings } = useSettings()

    const { customer } = useCustomerLoadedExisting()
    const { vehicles, vehiclesLoading } = useGetVehicles({ customerId: customer.id })

    const children = vehiclesLoading ? (
        <LoadingDisplay />
    ) : !vehicles ? (
        <ErrorDisplay message="Erreur lors du chargement des véhicules" debug={vehicles} />
    ) : (
        <DisasterFormDialogReady vehicles={vehicles} {...props} />
    )

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <Dialog open={props.open} onClose={props.closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Déclaration d'un sinistre</DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

type DisasterFormDialogReadyProps = DisasterFormDialogProps & {
    vehicles: Vehicle_Minimal[]
}

const DisasterFormDialogReady = ({ open, closeDialog, onDone, vehicles }: DisasterFormDialogReadyProps) => {
    const [data, setData] = useState<DisasterFormData>(emptyDisaster)
    const [loading, setLoading] = useState<boolean>(false)

    const { settings } = useSettings()
    const { customer } = useCustomerLoadedExisting()
    const places = customer.places
    const declareDisasterMutation = useDeclareDisaster()

    const { errorAlert } = useErrorService()

    const handleChange = (value: string | number, prop: 'description' | 'estimation' | 'disasterType') => {
        setData((disasterData) => {
            return {
                ...disasterData,
                [prop]: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (data.description === null) return
        if (data.estimation === null) return
        if (data.disasterType === null) return

        setLoading(true)

        const estimationLabel =
            data.estimation === 'lower'
                ? 'Moins de 5.000€'
                : data.estimation === 'between'
                ? 'Entre 5.000 et 20.000€'
                : 'Plus de 20.000€'

        try {
            const response = await declareDisasterMutation.run({
                customerId: customer.id,
                description: data.description,
                placesInvolved: data.placesIds.length > 0,
                placesDescription: getPlacesAsDescription(),
                vehiclesInvolved: data.vehiclesIds.length > 0,
                vehiclesDescription: getVehiclesAsDescription(),
                bodyDamageInvolved: data.hasBodyDamages,
                estimatedAmount: estimationLabel,
                disasterType: data.disasterType,
            })

            onDone()
            setData(emptyDisaster)
            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const handleCheckboxChange = (checked: boolean, prop: 'vehicle' | 'bodyDamage') => {
        if (prop === 'vehicle') {
            if (checked) {
                setData((disasterData) => {
                    return {
                        ...disasterData,
                        isVehicleDisaster: true,
                        hasBodyDamages: false,
                    }
                })
            } else {
                setData((disasterData) => {
                    return {
                        ...disasterData,
                        isVehicleDisaster: false,
                    }
                })
            }
        } else {
            setData((disasterData) => {
                return {
                    ...disasterData,
                    isVehicleDisaster: true,
                    hasBodyDamages: checked,
                }
            })
        }
    }

    const handleElementChange = (selectedValues: string[], elementType: 'placesIds' | 'vehiclesIds') => {
        setData((oldData) => {
            return {
                ...oldData,
                [elementType]: selectedValues,
            }
        })
    }

    const getVehiclesLabel = (vehicleId: string): string => {
        const vehicle = vehicles.find((tempVehicle) => tempVehicle.id === vehicleId)

        if (!vehicle) return 'Véhicule non reconnu'
        else return VehicleHelper.getLabel(vehicle)
    }

    const getVehiclesAsDescription = (): string => {
        return data.vehiclesIds.map(getVehiclesLabel).join(', ')
    }

    const getPlacesLabel = (placeId: string): string => {
        const place = places.find((tempPlace) => tempPlace.id === placeId)

        if (!place) return 'Local non reconnu'
        else return place.title
    }

    const getPlacesAsDescription = (): string => {
        return data.placesIds.map(getPlacesLabel).join(', ')
    }

    const isFormValid =
        isStringInputValid(data.description) && data.estimation !== null && isStringInputValid(data.disasterType)

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Déclaration d'un sinistre</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Utilisez ce formulaire pour pré-déclarer un sinistre auprès de votre expert d'assuré.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="category"
                        label="Type de sinistre *"
                        type="text"
                        fullWidth
                        value={data.disasterType || ''}
                        onChange={(event) => handleChange(event.target.value, 'disasterType')}
                        select>
                        {lightDisasterCategories.map((category, index) => {
                            return (
                                <MenuItem value={category} key={index}>
                                    {category}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                    <TextField
                        margin="dense"
                        id="description"
                        label="Description du sinistre *"
                        type="text"
                        fullWidth
                        multiline={true}
                        value={data.description || ''}
                        onChange={(event) => handleChange(event.target.value, 'description')}
                    />
                    <FormControl component="fieldset" className="mt-5">
                        <FormLabel component="legend">Estimation du montant des dégâts *</FormLabel>
                        <RadioGroup
                            aria-label="estimation"
                            name="estimation"
                            value={data.estimation || ''}
                            onChange={(event) => handleChange(event.target.value, 'estimation')}>
                            <FormControlLabel value="lower" control={<Radio />} label="Moins de 5.000€" />
                            <FormControlLabel value="between" control={<Radio />} label="Entre 5.000 et 20.000€" />
                            <FormControlLabel value="upper" control={<Radio />} label="Plus de 20.000€" />
                        </RadioGroup>
                    </FormControl>
                    <MultiSelectCheckbox
                        selectTitle={places.length === 0 ? 'Aucun local disponible' : 'Locaux sinistrés'}
                        name="places"
                        value={data.placesIds}
                        onChange={(selectedValues) => handleElementChange(selectedValues, 'placesIds')}
                        getLabel={getPlacesLabel}
                        style={{ minWidth: 300 }}
                        disabled={places.length === 0}
                        availableIds={places.map((place) => place.id)}
                    />
                    <MultiSelectCheckbox
                        selectTitle={places.length === 0 ? 'Aucun véhicule disponible' : 'Véhicules sinistrés'}
                        name="vehicles"
                        value={data.vehiclesIds}
                        onChange={(selectedValues) => handleElementChange(selectedValues, 'vehiclesIds')}
                        getLabel={getVehiclesLabel}
                        style={{ minWidth: 300 }}
                        disabled={vehicles.length === 0}
                        availableIds={vehicles.map((vehicle) => vehicle.id)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={({ target: { checked } }) => {
                                    handleCheckboxChange(checked, 'bodyDamage')
                                }}
                                checked={data.hasBodyDamages}
                            />
                        }
                        label="Dommage corporel ?"
                    />
                </DialogContent>
                <DialogActions>
                    <CustomButton color="secondary" onClick={closeDialog}>
                        Annuler
                    </CustomButton>
                    <CustomButton
                        loading={loading}
                        variant="outlined"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!isFormValid}>
                        Valider
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default DisasterFormDialog
