import queries from '../../../graphql/queries'
import {
    GetGoods_Minimal,
    GetGoods_MinimalVariables,
    GetGoods_Minimal_goods
} from '../../../graphql/queries/typings/GetGoods_Minimal'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type GoodsData = {
    goods: GetGoods_Minimal_goods[] | null
    goodsLoading: boolean
}

type GetGoodsProps = {
    customerId: string
    enterpriseId?: string
}

const useGetGoods = ({ customerId, enterpriseId }: GetGoodsProps): GoodsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetGoods_Minimal, GetGoods_MinimalVariables>(queries.GetGoods_Minimal, {
        variables: {
            customerId,
            enterpriseId,
        },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const goods = responseData && responseData.goods ? responseData.goods : null

        return {
            goods,
            goodsLoading: false,
        }
    } else {
        return {
            goods: null,
            goodsLoading: true,
        }
    }
}

export default useGetGoods
