import { OperationVariables } from '@apollo/client/core/types'
import { MutationUpdaterFn } from '@apollo/client/core/watchQueryOptions'
import { DocumentNode } from '@apollo/client/link/core/types'
import { MutationTuple } from '@apollo/client/react/types/types'
import { get } from 'lodash'
import { ErrorBackEnd } from '../errors/ErrorService'

export type QueryToRefetch = ComputedQueryToRefetch | DocumentNode

function isQueryWithVariable(query: DocumentNode | ComputedQueryToRefetch): query is ComputedQueryToRefetch {
    if (get(query, 'variables')) return true
    return false
}

export type RunMutationParams<TData, TVariables> = {
    queriesToRefetch?: QueryToRefetch[]
    awaitRefetchQueries?: boolean
    optimisticResponse?: TData | ((vars: TVariables) => TData)
    update?: MutationUpdaterFn<TData>
    throwErrors?: boolean
}

export async function runMutation<
    TData,
    TVariables,
    TMutationName extends keyof TData,
    TMutationData = NonNullable<TData[TMutationName]> extends ErrorBackEnd ? TData[TMutationName] : never
>(
    runner: MutationTuple<TData, TVariables>[0],
    variables: TVariables,
    mutationName: TMutationName,
    mutationParams?: RunMutationParams<TData, TVariables>
): Promise<NonNullable<TMutationData>> {
    const {
        queriesToRefetch = [],
        awaitRefetchQueries = true,
        throwErrors = true,
        optimisticResponse,
        update,
    } = mutationParams
        ? mutationParams
        : {
              queriesToRefetch: [],
              awaitRefetchQueries: true,
              throwErrors: true,
              optimisticResponse: undefined,
              update: undefined,
          }

    // We parse every request to adopt base format
    const formattedQueriesToRefetch: Array<ComputedQueryToRefetch | { query: DocumentNode }> = queriesToRefetch.map(
        (query) => {
            if (isQueryWithVariable(query)) {
                return query
            } else {
                return { query }
            }
        }
    )

    const result = await runner({
        variables: variables,
        refetchQueries: formattedQueriesToRefetch,
        awaitRefetchQueries,
        optimisticResponse,
        update,
    })

    if (result.errors) {
        console.error(`Error(s) while running mutation ${mutationName} : `, result.errors)
        throw result.errors[0]
    }

    const data = result.data
    if (!data) throw new Error('No data')

    const mutationData = data[mutationName] as unknown as NonNullable<TMutationData> & ErrorBackEnd
    if (!mutationData) throw new Error('No mutation data')

    const errors = mutationData.errors
    if (errors && errors.length > 0) {
        throw errors[0]
    }

    return mutationData
}

type ComputedQueryToRefetch<TVariables = OperationVariables> = {
    query: DocumentNode
    variables: TVariables
    _private: 'Computed'
}

// Used to strongly type queries to refetch
export function createQueryToRefetch<TVariables = OperationVariables>(
    query: DocumentNode,
    variables: TVariables
): ComputedQueryToRefetch<TVariables> {
    return {
        query: query,
        variables: variables,
        _private: 'Computed',
    }
}
