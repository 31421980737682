import { IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography/Typography'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import queries from '../../../../../../../graphql/queries'
import {
    GetPreventionsForDMA,
    GetPreventionsForDMAVariables,
    GetPreventionsForDMA_customer_preventions,
} from '../../../../../../../graphql/queries/typings/GetPreventionsForDMA'
import { EnumDocumentParent } from '../../../../../../../graphql/typings/global_types'
import DateHelper from '../../../../../../helpers/DateHelper'
import ErrorDisplay from '../../../../../../helpers/errors/ErrorDisplay'
import { useErrorService } from '../../../../../../helpers/errors/ErrorService'
import { useCommonDataLoadedExisting } from '../../../../../../hooks/useCommonData'
import { useCustomerLoadedExisting } from '../../../../../../hooks/useCustomer'
import { useCreatePrevention } from '../../../../../../hooks/useMutations'
import useQueryWrapper from '../../../../../../hooks/useQueryWrapper'
import { DateRange } from '../../../../../DateCategoryInput'
import LoadingDisplay from '../../../../../LoadingDisplay'
import Spacer from '../../../../../Spacer'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'

type PreventionForDMA = GetPreventionsForDMA_customer_preventions

type DMA_PreventionProps = DMAButtonsProps & {
    data: DMA_Data
    setData: DMA_SetData
}

const DMA_Prevention = (props: DMA_PreventionProps) => {
    const { customer } = useCustomerLoadedExisting()

    const queryResult = useQueryWrapper<GetPreventionsForDMA, GetPreventionsForDMAVariables>(
        queries.GetPreventionsForDMA,
        {
            variables: {
                customerID: customer.id,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const hasLoadedOnce = useRef<boolean>(false)

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    useEffect(() => {
        if (!queryLoading) {
            hasLoadedOnce.current = true
        }
    }, [queryLoading])

    //TODO We don't manage yet the existing document
    return queryLoading && !hasLoadedOnce ? (
        <>
            <LoadingDisplay text="Chargement des préventions..." />
            <DMAButtons {...props} />
        </>
    ) : !responseData ? (
        <>
            <ErrorDisplay message="Erreur lors du chargement des préventions" debug={queryResult} />
            <DMAButtons {...props} />
        </>
    ) : (
        <DMA_PreventionReady {...props} preventions={responseData.customer.preventions} queryLoading={queryLoading} />
    )
}

type DMA_PreventionReadyProps = DMA_PreventionProps & {
    preventions: PreventionForDMA[]
    queryLoading: boolean
}

const DMA_PreventionReady = ({
    data,
    setData,
    preventions,
    queryLoading,
    ...buttonsProps
}: DMA_PreventionReadyProps) => {
    const { customer } = useCustomerLoadedExisting()
    const places = customer.places
    const { preventionCategories } = useCommonDataLoadedExisting()
    const { errorAlert } = useErrorService()

    const defaultPrevention: PreventionForDMA | null = useMemo(() => {
        if (data.docElementType !== EnumDocumentParent.Prevention || data.docElementId === null) return null

        const defaultPrev = preventions.find((prev) => prev.id === data.docElementId)

        if (!defaultPrev) {
            console.error("Can't find defaultPrevention : ", { data, preventions })
            errorAlert('Erreur lors de la recherche de préventions.')
            return null
        }

        return defaultPrev
    }, [data.docElementId, data.docElementType])

    const [placeID, setPlaceID] = useState<string | null>(defaultPrevention ? defaultPrevention.place.id : null)
    const [categoryID, setCategoryID] = useState<string | null>(
        defaultPrevention ? defaultPrevention.preventionCategory.id : null
    )

    const availablePreventions: PreventionForDMA[] = useMemo(() => {
        if (!placeID || !categoryID) return []

        return preventions.filter((prevention) => {
            return prevention.place.id === placeID && prevention.preventionCategory.id === categoryID
        })
    }, [preventions, placeID, categoryID])

    const handlePlaceChange = (value: string) => {
        setPlaceID(value)
    }

    const handleCategoryChange = (value: string) => {
        setCategoryID(value)
    }

    return (
        <>
            <TextField
                margin="dense"
                id="type"
                label={places.length > 0 ? 'Local visé par la prévention' : 'Aucun local disponible'}
                type="text"
                fullWidth
                value={placeID || ''}
                onChange={(event) => handlePlaceChange(event.target.value)}
                disabled={places.length === 0}
                select>
                {places.map((place) => {
                    return (
                        <MenuItem value={place.id} key={place.id}>
                            {place.title}
                        </MenuItem>
                    )
                })}
            </TextField>
            <TextField
                margin="dense"
                id="type"
                label={
                    preventionCategories.length > 0
                        ? 'Catégorie de prévention'
                        : 'Aucune catégorie de prévention disponible'
                }
                type="text"
                fullWidth
                value={categoryID || ''}
                onChange={(event) => handleCategoryChange(event.target.value)}
                disabled={preventionCategories.length === 0}
                select>
                {preventionCategories.map((category, index) => {
                    return (
                        <MenuItem value={category.id} key={category.id}>
                            {category.title}
                        </MenuItem>
                    )
                })}
            </TextField>
            {queryLoading && <LoadingDisplay />}
            {!queryLoading && (placeID === null || categoryID === null) && (
                <DMAButtons {...buttonsProps} canGoNext={false} />
            )}
            {!queryLoading && placeID !== null && categoryID !== null && (
                <DMA_Prevention_PeriodPicker
                    data={data}
                    setData={setData}
                    availablePreventions={availablePreventions}
                    placeID={placeID}
                    preventionCategoryID={categoryID}
                    {...buttonsProps}
                />
            )}
        </>
    )
}

enum PrevMode {
    CREATE = 'create',
    PICKER = 'picker',
}

type DMA_Prevention_PeriodPickerProps = Pick<DMA_PreventionProps, 'data' | 'setData'> &
    DMAButtonsProps & {
        availablePreventions: PreventionForDMA[]
        placeID: string
        preventionCategoryID: string
    }

const DMA_Prevention_PeriodPicker = ({
    data,
    setData,
    availablePreventions,
    placeID,
    preventionCategoryID,
    ...buttonsProps
}: DMA_Prevention_PeriodPickerProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { fromServertoString } = DateHelper()
    const createPreventionMutation = useCreatePrevention()
    const { errorAlert } = useErrorService()
    const { fromDateToServer } = DateHelper()

    const [loading, setLoading] = useState<boolean>(false)
    const [mode, setMode] = useState<PrevMode>(PrevMode.PICKER)
    const [prevID, setPrevID] = useState<string | null>(data.docElementId || null)

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleAddClick = () => {
        setMode(PrevMode.CREATE)
    }

    const handlePeriodChange = (value: string) => {
        setPrevID(value)
    }

    useEffect(() => {
        setData({
            docElementType: data.docElementType,
            docElementId: prevID,
        })
    }, [prevID])

    // We overload goNext to create the prevention
    const createPrevention = async (startAt: Date, endAt: Date) => {
        try {
            await createPreventionMutation.run({
                customerId: customer.id,
                placeId: placeID,
                preventionCategoryId: preventionCategoryID,
                description: 'Créé en ajoutant un document',
                startAt: fromDateToServer(startAt),
                endAt: fromDateToServer(endAt),
            })

            setMode(PrevMode.PICKER)
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const computePeriodLabel = (prev: PreventionForDMA): string => {
        return fromServertoString(prev.startAt) + ' - ' + fromServertoString(prev.endAt)
    }

    return (
        <>
            {loading && <LoadingDisplay text="Création de la prévention..." />}
            {!loading && mode === PrevMode.PICKER && (
                <>
                    <Grid container wrap="nowrap" alignItems="flex-end" spacing={2}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <TextField
                                margin="dense"
                                id="type"
                                label={
                                    availablePreventions.length > 0
                                        ? 'Périodes disponibles'
                                        : 'Aucune période disponible'
                                }
                                type="text"
                                fullWidth
                                value={prevID === null ? '' : prevID}
                                onChange={(event) => handlePeriodChange(event.target.value)}
                                disabled={availablePreventions.length === 0}
                                select>
                                {availablePreventions.map((prev, index) => {
                                    return (
                                        <MenuItem value={prev.id} key={prev.id}>
                                            {computePeriodLabel(prev)}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                endIcon={<AddIcon />}
                                onClick={handleAddClick}
                                onMouseDown={handleMouseDown}
                                size="small">
                                Créer
                            </Button>
                        </Grid>
                    </Grid>
                    <DMAButtons {...buttonsProps} />
                </>
            )}
            {!loading && mode === PrevMode.CREATE && (
                <DMA_Prevention_CreatePrevention
                    setMode={setMode}
                    {...buttonsProps}
                    createPrevention={createPrevention}
                />
            )}
        </>
    )
}

type DMA_Prevention_CreatePreventionProps = Omit<DMAButtonsProps, 'goNext'> & {
    setMode: Dispatch<SetStateAction<PrevMode>>
    createPrevention: (startAt: Date, endAt: Date) => Promise<void>
}

const DMA_Prevention_CreatePrevention = ({
    setMode,
    createPrevention,
    ...buttonsProps
}: DMA_Prevention_CreatePreventionProps) => {
    const { errorAlert } = useErrorService()

    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
    })

    const handleDateChange = (date: MaterialUiPickersDate, prop: 'startDate' | 'endDate') => {
        if (!date) return

        setDateRange((oldData) => {
            return {
                ...oldData,
                [prop]: date,
            }
        })
    }

    const onClose = () => {
        setMode(PrevMode.PICKER)
    }

    const goNext = () => {
        if (!dateRange.startDate || !dateRange.endDate) {
            errorAlert('Veuillez définir la période complète.')
            return
        }

        createPrevention(dateRange.startDate, dateRange.endDate)
    }

    return (
        <>
            <Spacer size={2} />
            <Grid container>
                <Grid item style={{ flexGrow: 1 }}>
                    <Typography variant="h6">Nouvelle période</Typography>
                </Grid>
                <Grid item>
                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <DatePicker
                margin="dense"
                id="startAt"
                label="Date de début *"
                value={dateRange.startDate}
                onChange={(date) => handleDateChange(date, 'startDate')}
                fullWidth
                format="dd/MM/yyyy"
                autoOk={true}
            />
            <DatePicker
                margin="dense"
                id="endAt"
                label="Date d'expiration *"
                value={dateRange.endDate}
                onChange={(date) => handleDateChange(date, 'endDate')}
                fullWidth
                format="dd/MM/yyyy"
                className="mb-5"
                autoOk={true}
            />
            <DMAButtons {...buttonsProps} goNext={goNext} />
        </>
    )
}

export default DMA_Prevention
