import { useMemo } from 'react'
import { BreadcrumbProps } from '../../../../matx/components/Breadcrumb'
import { Document_Minimal, Document_User } from '../../../helpers/data/models/Document'
import DocumentHelper from '../../../helpers/DocumentHelper'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import { RouteDescriptor, useRouteInput } from '../../../helpers/RoutesHelper'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import useDocuments from '../../../hooks/useDocuments'
import useNavContext from '../../../hooks/useNavContext'
import CustomTabs, { CustomTab } from '../../CustomTabs'
import DocumentsList from '../../documentsList/DocumentsList'
import LoadingDisplay from '../../LoadingDisplay'

type DocumentsProps<T extends { tab?: DocumentTabName }> = BreadcrumbProps & {
    route: RouteDescriptor<T>
}

export type DocumentTabName = 'all' | 'waiting_admin' | 'waiting_user'

const CustomerDocuments = <T extends { tab?: DocumentTabName }>(props: DocumentsProps<T>) => {
    const { customer } = useCustomerLoadedExisting()
    const { documents, documentsLoading } = useDocuments(customer.id)
    const { tab } = useRouteInput(props.route).params

    if (documentsLoading) {
        return <LoadingDisplay />
    } else if (!documents) {
        return <ErrorDisplay message="Erreur lors du chargement des documents" />
    } else return <CustomerDocumentsReady documents={documents} tab={tab} {...props} />
}

type CustomerDocumentsReadyProps = BreadcrumbProps & {
    tab: DocumentTabName | undefined
    documents: Document_User[]
}

const CustomerDocumentsReady = ({ documents, tab, ...props }: CustomerDocumentsReadyProps) => {
    const { pendingDocsAskedByAEA, pendingDocsAskedByUser, waitingAEAValidationDocs, confirmedDocs, rejectedDocs } =
        useMemo(() => DocumentHelper.splitDocuments(documents), [documents])
    const { getDocumentsRoute } = useNavContext()

    const tabs: CustomTab<DocumentTabName>[] = [
        {
            id: 'all',
            label: 'Général',
            children: <DocumentsList mode="confirmed" documents={confirmedDocs} showLink={true} />,
            to: getDocumentsRoute(),
        },
        {
            id: 'waiting_admin',
            label: `En attente d'AEA`,
            count: pendingDocsAskedByUser.length + waitingAEAValidationDocs.length,
            children: <DocumentsList mode="waiting_admin" documents={documents} showLink={true} />,
            to: getDocumentsRoute('waiting_admin'),
        },
        {
            id: 'waiting_user',
            label: `En attente du client`,
            count: pendingDocsAskedByAEA.length + rejectedDocs.length,
            children: <DocumentsList mode="waiting_user" documents={documents} showLink={true} />,
            to: getDocumentsRoute('waiting_user'),
        },
    ]

    return (
        <CustomTabs<Document_Minimal, DocumentTabName>
            breadcrumbSegments={props.routeSegments}
            activeTab={tab || 'all'}
            tabs={tabs}
        />
    )
}

export default CustomerDocuments
