import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { CustomerGeneric_customer } from '../../../../../graphql/queries/typings/CustomerGeneric'
import {
    DisasterGeneric_disaster,
    DisasterGeneric_disaster_disasterDamages,
    DisasterGeneric_disaster_payments,
} from '../../../../../graphql/queries/typings/DisasterGeneric'
import { EnumDisasterStatus } from '../../../../../graphql/typings/global_types'
import MoneyDisplayAnalyzer from '../../../../components/customInputs/MoneyDisplayAnalyzer'
import DataTable from '../../../../components/DataTable'
import DateHelper from '../../../../helpers/DateHelper'
import DisasterHelper from '../../../../helpers/DisasterHelper'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper from '../../../../helpers/RoutesHelper'

type DisasterData = {
    date: string
    title: string
    status: string
    amountNotPaid: number
    customer: string
    baseDisaster: DisasterForList
}

type AEADashboardDisastersProps = {
    disasters: DisasterForList[]
}

type DisasterDamageForList = Pick<DisasterGeneric_disaster_disasterDamages, 'id' | 'indemnity'>
type DisasterPaymentForList = Pick<DisasterGeneric_disaster_payments, 'id' | 'amount' | 'paid_at'>

type DisasterForList = Pick<DisasterGeneric_disaster, 'id' | 'status' | 'date' | 'title'> & {
    disasterDamages: DisasterDamageForList[]
    payments: DisasterPaymentForList[]
    customer: Pick<CustomerGeneric_customer, 'id' | 'title'>
}

const AEADashboardDisasters = ({ disasters }: AEADashboardDisastersProps) => {
    const { fromServertoString } = DateHelper()
    const history = useHistory()

    const getFormattedDisasters = useCallback(() => {
        const filteredDisasters = disasters.filter((disaster) => disaster.status !== EnumDisasterStatus.closed)

        const formattedDisasters: DisasterData[] = filteredDisasters.map((disaster) => {
            let amountNotPaid = 0

            if (disaster.status !== EnumDisasterStatus.closed) {
                let amountPaid = 0
                let duedIndemnitiesAmount = 0

                disaster.disasterDamages.forEach((damage) => {
                    duedIndemnitiesAmount += damage.indemnity || 0
                })

                disaster.payments.forEach((payment) => {
                    if (!payment.paid_at) return

                    amountPaid += payment.amount
                })

                amountNotPaid = duedIndemnitiesAmount - amountPaid
            }

            return {
                date: disaster.date,
                title: disaster.title,
                status: DisasterHelper.getDisasterStatusLabel(disaster.status),
                amountNotPaid,
                customer: disaster.customer.title,
                baseDisaster: disaster,
            }
        })

        return formattedDisasters
    }, [disasters])

    const formattedDisasters = getFormattedDisasters()

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'date',
                label: 'Date',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const disaster = formattedDisasters[dataIndex]
                        const formattedDate = fromServertoString(disaster.date)

                        return <div>{formattedDate}</div>
                    },
                },
            },
            {
                name: 'title',
                label: 'Libellé',
            },
            {
                name: 'customer',
                label: 'Client',
            },
            {
                name: 'status',
                label: 'Statut',
            },
            {
                name: 'amountNotPaid',
                label: 'Reste à percevoir',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const disaster = formattedDisasters[dataIndex]

                        return <MoneyDisplayAnalyzer value={disaster.amountNotPaid} />
                    },
                },
            },
        ],
        [disasters]
    )

    const onRowClick = (disasterData: DisasterData) => {
        const disaster = disasterData.baseDisaster

        history.push(
            RoutesHelper.views.AEA.customer.disasters.Disaster.to({
                customerId: disaster.customer.id,
                disasterId: disaster.id,
            })
        )
    }

    return (
        <DataTable<DisasterData>
            title={'Sinistres en cours'}
            icon={IconHelper.elementIcons.disasterIcon}
            data={formattedDisasters}
            columns={columns}
            showSearch={true}
            rowClick={onRowClick}
        />
    )
}

export default AEADashboardDisasters
