import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { ChangeEvent, useState } from 'react'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { PlaceGeneric_place } from '../../../../graphql/queries/typings/PlaceGeneric'
import { SiteGeneric_site } from '../../../../graphql/queries/typings/SiteGeneric'
import { isStringInputValid } from '../../../../utilsTs'
import { Place_Minimal } from '../../../helpers/data/models/Place'
import { Site_Minimal } from '../../../helpers/data/models/Site'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useCreatePlace, useUpdatePlace } from '../../../hooks/useMutations'
import { useSitesLoadedExisting } from '../../../hooks/useSites'
import CustomButton from '../../CustomButton'
import { Dialog } from '../../Dialog'

type PlaceDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
    place?: Place_Minimal
    placeEnterprise?: EnterpriseForDialog
}

type Data = {
    title: string | null
    address: string | null
    enterprise: EnterpriseForDialog | null
    wallsOwner: string | null
    site: Site_Minimal | null
}

const nullValue = 'null'

type PlaceForDialog = Pick<PlaceGeneric_place, 'id' | 'address' | 'wallsOwner'> & {
    site: Pick<SiteGeneric_site, 'id' | 'title'> | null
}

type EnterpriseForDialog = Pick<EnterpriseGeneric_enterprise, 'id' | 'address' | 'title'>

const PlaceDialog = ({ onDone, open, closeDialog, place, placeEnterprise }: PlaceDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { sites } = useSitesLoadedExisting()
    const { errorAlert } = useErrorService()

    const emptyPlace: Data = {
        title: null,
        address: null,
        enterprise: placeEnterprise ? placeEnterprise : null,
        wallsOwner: null,
        site: null,
    }

    const [loading, setLoading] = useState<boolean>(false)

    const createPlaceMutation = useCreatePlace()
    const updatePlaceMutation = useUpdatePlace()

    const enterprises = customer.enterprises

    const basePlace: Data =
        place && placeEnterprise
            ? {
                  title: place.title,
                  address: place.address,
                  enterprise: placeEnterprise,
                  wallsOwner: place.wallsOwner,
                  site: place.site,
              }
            : emptyPlace

    const [data, setData] = useState<Data>(basePlace)

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'address' | 'wallsOwner') => {
        setData({
            ...data,
            [prop]: value,
        })
    }

    const handleSiteChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const siteId = event.target.value

        const site = siteId === nullValue ? null : sites.find((tempSite) => tempSite.id === siteId)

        if (site === undefined) {
            errorAlert(`Erreur lors de la récupération du site ${siteId}`, siteId)
            return
        }

        setData({
            ...data,
            site: site,
        })
    }

    const handleEnterpriseChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const enterpriseId = event.target.value

        const enterprise = enterprises.find((tempEnterprise) => tempEnterprise.id === enterpriseId)

        if (!enterprise) {
            errorAlert(`Erreur lors de la récupération du site ${enterpriseId}`, enterpriseId)
            return
        }

        setData((oldData) => {
            return {
                ...oldData,
                enterprise: enterprise,
            }
        })
    }

    const handleDone = async () => {
        if (!data.title) return
        if (!data.address) return
        if (!data.enterprise) return
        if (!data.wallsOwner) return

        setLoading(true)

        try {
            if (place) {
                //Update
                const response = await updatePlaceMutation.run({
                    id: place.id,
                    title: data.title,
                    address: data.address,
                    enterpriseId: data.enterprise.id,
                    wallsOwner: data.wallsOwner,
                    siteId: data.site ? data.site.id : null,
                })

                if (response.place) {
                    onDone()
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du local'
                }
            } else {
                //Create
                const response = await createPlaceMutation.run({
                    customerId: customer.id,
                    title: data.title,
                    address: data.address,
                    enterpriseId: data.enterprise.id,
                    wallsOwner: data.wallsOwner,
                    siteId: data.site ? data.site.id : null,
                })

                if (response.place) {
                    onDone()
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du local'
                }

                setData(emptyPlace)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid =
        isStringInputValid(data.title) &&
        isStringInputValid(data.address) &&
        data.enterprise !== null &&
        isStringInputValid(data.wallsOwner)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un local</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="title"
                    label="Libellé *"
                    type="text"
                    fullWidth
                    value={data.title}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    name="address"
                    label="Adresse *"
                    type="text"
                    fullWidth
                    value={data.address}
                    onChange={(event) => handleChange(event.target.value, 'address')}
                />
                <TextField
                    margin="dense"
                    name="site"
                    label={sites.length > 0 ? 'Site' : 'Aucun site dans le projet'}
                    fullWidth
                    select
                    onChange={handleSiteChange}
                    value={data.site ? data.site.id : nullValue}
                    disabled={sites.length === 0}>
                    <MenuItem value={nullValue}>Aucun</MenuItem>
                    {sites.map((site: Site_Minimal) => {
                        return (
                            <MenuItem value={site.id} key={site.id}>
                                {site.title}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    name="enterprise"
                    margin="dense"
                    label="Entreprise exploitante"
                    fullWidth
                    select
                    onChange={handleEnterpriseChange}
                    value={data.enterprise ? data.enterprise.id : ''}>
                    {enterprises.map((enterprise) => {
                        return (
                            <MenuItem value={enterprise.id} key={enterprise.id}>
                                {enterprise.title}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    className="mb-5"
                    name="wallsOwner"
                    margin="dense"
                    label="Propriétaire des murs *"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value, 'wallsOwner')}
                    value={data.wallsOwner ? data.wallsOwner : ''}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleDone}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default PlaceDialog
