import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useHistory } from 'react-router'
import ErrorView from '../../components/ErrorView'
import { useErrorService } from '../../helpers/errors/ErrorService'
import RoutesHelper from '../../helpers/RoutesHelper'
import { useAuth } from '../../hooks/useAuth'
import { useUpdateUserPassword } from '../../hooks/useMutations'
import ExternalLinkLayout from './ExternalLinkLayout'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const PasswordChange = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const auth = useAuth()

    useEffect(() => {
        if (auth.loading) return
        if (auth.user) {
            auth.logout()
        }
    }, [auth])

    const content = useMemo(() => {
        if (auth.loading) return
        if (auth.user) return

        const userToken = urlParams.get('user_token')

        if (!userToken) {
            return (
                <ErrorView
                    message="Erreur - Lien erroné. Veuillez contacter AEA"
                    debug={{
                        queryString,
                        urlParams,
                    }}
                />
            )
        }

        return <PasswordChangeReady token={userToken} />
    }, [queryString, urlParams, auth])

    return <ExternalLinkLayout title="Nouveau mot de passe">{content}</ExternalLinkLayout>
}

type PasswordChangeReadyProps = {
    token: string
}

const PasswordChangeReady = ({ token }: PasswordChangeReadyProps) => {
    const history = useHistory()
    const classes = useStyles()

    const [password, setPassword] = useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const updateUserMutation = useUpdateUserPassword()

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordConfirmEqual', (value) => {
            if (value !== password) {
                return false
            }
            return true
        })
    }, [password])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        setErrorMessage(null)

        switch (name) {
            case 'password':
                setPassword(value)
                break
            case 'password_confirm':
                setPasswordConfirm(value)
                break
        }
    }

    const handleFormSubmit = async () => {
        if (password !== passwordConfirm) {
            setErrorMessage('Les deux mots de passe sont différents')
            return
        }

        setLoading(true)
        try {
            await updateUserMutation.run({
                userToken: token,
                newPassword: password,
                newPasswordConfirmation: passwordConfirm,
            })

            history.push(RoutesHelper.views.Customer.Home.to())
        } catch (e) {
            setErrorMessage('Une erreur est survenue. Contactez AEA')
        } finally {
            setLoading(false)
        }
    }

    return (
        <ValidatorForm onSubmit={handleFormSubmit}>
            <TextValidator
                className="mb-6 w-full"
                label="Password"
                variant="outlined"
                size="small"
                onChange={handleInputChange}
                name="password"
                type="password"
                value={password}
                validators={['required', 'matchRegexp:.{8}']}
                errorMessages={['Ce champ est requis', 'Votre mot de passe doit compter au moins 8 caractères']}
            />
            <TextValidator
                className="mb-6 w-full"
                label="Password (confirmation)"
                variant="outlined"
                size="small"
                onChange={handleInputChange}
                name="password_confirm"
                type="password"
                value={passwordConfirm}
                validators={['required', 'matchRegexp:.{8}', 'isPasswordConfirmEqual']}
                errorMessages={[
                    'Ce champ est requis',
                    'Votre mot de passe doit compter au moins 8 caractères',
                    'Les deux mots de passe doivent être identiques',
                ]}
            />

            {errorMessage && (
                <div className="mb-6">
                    <ErrorView message={errorMessage} />
                </div>
            )}

            <div className="flex flex-wrap items-center mb-4">
                <div className="relative">
                    <Button variant="contained" color="primary" disabled={loading} type="submit">
                        Confirmer
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </div>
        </ValidatorForm>
    )
}

export default PasswordChange
