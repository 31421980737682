import { Redirect } from 'react-router-dom'
import { EnumUser } from '../../graphql/typings/global_types'
import RoutesHelper from '../helpers/RoutesHelper'
import { useAuthUser } from '../hooks/useAuth'

/**
 * Re-routes to user-typed root component
 **/
const AuthenticatedRootSwitch = () => {
    const auth = useAuthUser()

    const routeToRedirectTo =
        auth.user.userType === EnumUser.admin ? RoutesHelper.views.AEA.Home.to() : RoutesHelper.views.Customer.Home.to()

    return <Redirect to={routeToRedirectTo} />
}

export default AuthenticatedRootSwitch
