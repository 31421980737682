import { Tooltip } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo } from 'react'
import { useHistory } from 'react-router'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import ContractHelper from '../../../../../helpers/ContractHelper'
import { HistoryRoute } from '../../../../../helpers/RoutesHelper'
import VehicleHelper from '../../../../../helpers/VehicleHelper'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useContractLoadedExisting } from '../../../../../hooks/useContract'
import useNavContext from '../../../../../hooks/useNavContext'
import DataTable from '../../../../DataTable'
import InfoPanel from '../../../../InfoPanel'

type FormattedElement = {
    title: string
    type: 'Véhicule' | 'Site' | 'Flotte' | 'Entreprise' | 'Local' | 'Bien'
    to?: HistoryRoute
}

const ContractElementsList = () => {
    const { contract } = useContractLoadedExisting()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const history = useHistory()

    const elements = useMemo(() => ContractHelper.getContractCoveredElements(contract), [contract])
    const { getEnterpriseRoute, getPlaceRoute, getEnterprisesRoute } = useNavContext()

    // const getElementsData = (): ElementCardData[] => {
    //     const elementsVisualItems: ElementCardData[] = []

    //     elements.enterprises.forEach((enterprise) => {
    //         elementsVisualItems.push({
    //             icon: IconHelper.elementIcons.enterpriseIcon,
    //             title: enterprise.title,
    //             to: getEnterpriseRoute(enterprise.id),
    //         })
    //     })

    //     // elements.sites.forEach((site) => {
    //     //     elementsVisualItems.push({
    //     //         icon: IconHelper.elementIcons.siteIcon,
    //     //         title: site.title,
    //     //         to:
    //     //     })
    //     // })

    //     elements.places.forEach((place) => {
    //         elementsVisualItems.push({
    //             icon: IconHelper.elementIcons.placeIcon,
    //             title: place.title,
    //             to: getPlaceRoute(place.id),
    //         })
    //     })

    //     elements.goods.forEach((good) => {
    //         const goodRoute = good.enterprise ? getEnterpriseRoute(good.enterprise.id, 'biens') : getEnterprisesRoute()

    //         elementsVisualItems.push({
    //             icon: IconHelper.elementIcons.goodIcon,
    //             title: good.title,
    //             to: goodRoute,
    //         })
    //     })

    //     elements.fleets.forEach((fleet) => {
    //         elementsVisualItems.push({
    //             icon: IconHelper.elementIcons.fleetIcon,
    //             title: fleet.title,
    //             to: getEnterpriseRoute(fleet.enterprise.id, 'vehicules'),
    //         })
    //     })

    //     elements.vehicles.forEach((vehicle) => {
    //         const vehicleRoute = vehicle.enterprise
    //             ? getEnterpriseRoute(vehicle.enterprise.id, 'vehicules')
    //             : getEnterprisesRoute()

    //         elementsVisualItems.push({
    //             icon: IconHelper.elementIcons.enterpriseIcon,
    //             title: VehicleHelper.getLabel(vehicle),
    //             to: vehicleRoute,
    //         })
    //     })

    //     return elementsVisualItems
    // }

    // const elementsData = getElementsData()

    // const renderElements = (elementsData: ElementCardData[]) => {
    //     if (elementsData.length === 0) {
    //         return (
    //             <Grid item>
    //                 <InfoPanel
    //                     icon={IconHelper.elementIcons.contractIcon}
    //                     text="Aucun élément saisi dans le contrat."
    //                 />
    //             </Grid>
    //         )
    //     }

    //     return elementsData.map((elementData, index) => {
    //         return (
    //             <Grid key={index} item xs={6} sm={6} md={3} lg={3}>
    //                 <ElementCard {...elementData} />
    //             </Grid>
    //         )
    //     })
    // }

    // const renderedElements = renderElements(elementsData)

    const getFormattedElements = (): FormattedElement[] => {
        const formattedElements: FormattedElement[] = []

        elements.enterprises.forEach((enterprise) => {
            formattedElements.push({
                title: enterprise.title,
                type: 'Entreprise',
                to: getEnterpriseRoute(enterprise.id),
            })
        })

        elements.sites.forEach((site) => {
            formattedElements.push({
                title: site.title,
                type: 'Site',
            })
        })

        elements.places.forEach((place) => {
            formattedElements.push({
                title: place.title,
                type: 'Local',
                to: getPlaceRoute(place.id),
            })
        })

        elements.goods.forEach((good) => {
            const goodRoute = good.enterprise ? getEnterpriseRoute(good.enterprise.id, 'biens') : getEnterprisesRoute()

            formattedElements.push({
                title: good.title,
                type: 'Bien',
                to: goodRoute,
            })
        })

        elements.fleets.forEach((fleet) => {
            formattedElements.push({
                title: fleet.title,
                type: 'Flotte',
                to: getEnterpriseRoute(fleet.enterprise.id, 'vehicules'),
            })
        })

        elements.vehicles.forEach((vehicle) => {
            const vehicleRoute = vehicle.enterprise
                ? getEnterpriseRoute(vehicle.enterprise.id, 'vehicules')
                : getEnterprisesRoute()

            formattedElements.push({
                title: VehicleHelper.getLabel(vehicle),
                type: 'Véhicule',
                to: vehicleRoute,
            })
        })

        return formattedElements
    }

    const formattedElements = useMemo(() => getFormattedElements(), [elements])

    const emptyText = `La liste des éléments couverts par le contrat est définie dans les garanties, créables
    et modifiables depuis l'onglet "Garanties"`

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'title',
                label: 'Libellé',
            },
            {
                name: 'type',
                label: 'Type',
            },
        ],
        [elements]
    )

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        const element = formattedElements[dataIndex]

        if (element.to === undefined) return null

        const route = element.to
        
        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title={'Voir'}>
                    <IconButton onClick={() => history.push(route)}>
                        <Icon>logout</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            {isAdmin && <InfoPanel icon="info" text={emptyText} />}
            {/* <Grid container spacing={6}>
                {renderedElements}
            </Grid> */}

            <DataTable<FormattedElement>
                title={'Eléments couverts par le contrat'}
                data={formattedElements}
                columns={columns}
                showSearch={true}
                actionsColumn={actionsColumn}
                // rowClick={onRowClick}
            />
        </Fragment>
    )
}

export default ContractElementsList
