import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MatxLayout } from '../../../../matx'
import LoadingDisplay from '../../../components/LoadingDisplay'
import NotFound from '../../../components/NotFound'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import RoutesHelper from '../../../helpers/RoutesHelper'
import useCommonData from '../../../hooks/useCommonData'
import useCustomers from '../../../hooks/useCustomers'
import { NavType } from '../../../hooks/useNavigation'
import AEAAdmins from './admins/AEAAdmins'
import AEACustomers from './customers/AEACustomers'
import AEADisasterCategories from './disasterCategories/AEADisasterCategories'
import AEAEvents from './events/AEAEvents'
import AEAGuaranteeCategories from './guaranteeCategories/AEAGuaranteeCategories'
import AEAHome from './home/AEAHome'
import AEAInsurances from './insurances/AEAInsurances'
import AEAPreventionCategories from './preventionCategories/AEAPreventionCategories'

const AEARootZone = () => {
    const { customers, customersLoading } = useCustomers()
    const { commonDataLoading } = useCommonData()

    const children =
        customersLoading || commonDataLoading ? (
            <LoadingDisplay />
        ) : !customers ? (
            <ErrorDisplay message="Customers must not be null here !" />
        ) : (
            <Switch>
                <Route exact path={RoutesHelper.views.AEA.Home.path} component={AEAHome} />
                <Route exact path={RoutesHelper.views.AEA.customers.Home.path} component={AEACustomers} />
                <Route exact path={RoutesHelper.views.AEA.admins.Home.path} component={AEAAdmins} />
                <Route exact path={RoutesHelper.views.AEA.insurances.Home.path} component={AEAInsurances} />
                <Route exact path={RoutesHelper.views.AEA.events.Home.path} component={AEAEvents} />
                <Route
                    exact
                    path={RoutesHelper.views.AEA.disasterCategories.Home.path}
                    component={AEADisasterCategories}
                />
                <Route
                    exact
                    path={RoutesHelper.views.AEA.guaranteeCategories.Home.path}
                    component={AEAGuaranteeCategories}
                />
                <Route
                    exact
                    path={RoutesHelper.views.AEA.preventionCategories.Home.path}
                    component={AEAPreventionCategories}
                />
                <Route component={NotFound} />
            </Switch>
        )

    return (
        <MatxLayout zone={NavType.aea} customers={customers || []}>
            {children}
        </MatxLayout>
    )
}

export default AEARootZone
