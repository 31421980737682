import { Redirect, Route, Switch } from 'react-router-dom'
import { EnumUser } from '../../../graphql/typings/global_types'
import { MatxLayout } from '../../../matx'
import LoadingDisplay from '../../components/LoadingDisplay'
import NotFound from '../../components/NotFound'
import ErrorDisplay from '../../helpers/errors/ErrorDisplay'
import RoutesHelper from '../../helpers/RoutesHelper'
import { useAuthUser } from '../../hooks/useAuth'
import useCommonData, { ProvideCommonData } from '../../hooks/useCommonData'
import useContracts, { ProvideContracts } from '../../hooks/useContracts'
import useCustomer, { ProvideCustomer } from '../../hooks/useCustomer'
import { ProvideDisasters, useDisasters } from '../../hooks/useDisasters'
import { NavType } from '../../hooks/useNavigation'
import { ProvideSites } from '../../hooks/useSites'
import CustomerAdminContract from './adminContract/CustomerAdminContract'
import CustomerContract from './contracts/CustomerContract'
import CustomerContracts from './contracts/CustomerContracts'
import HomeCustomer from './CustomerHome'
import CustomerDisaster from './disasters/CustomerDisaster'
import CustomerDisasters from './disasters/CustomerDisasters'
import CustomerDocuments from './documents/CustomerDocuments'
import CustomerEnterprise from './enterprises/CustomerEnterprise'
import CustomerEnterprises from './enterprises/CustomerEnterprises'
import CustomerOrgChart from './orgChart/CustomerOrgChart'
import CustomerPlace from './places/CustomerPlace'
import CustomerPlaces from './places/CustomerPlaces'
import CustomerPrevention from './preventions/CustomerPrevention'
import CustomerPreventions from './preventions/CustomerPreventions'
import CustomerRoadmap from './roadmap/CustomerRoadmap'
import CustomerUsers from './users/CustomerUsers'
import CustomerVehicle from './vehicles/CustomerVehicle'
import CustomerVehicles from './vehicles/CustomerVehicles'

const CustomerZone = () => {
    const auth = useAuthUser()

    const user = auth.user

    if (user.userType === EnumUser.user) {
        if (user.customers.length === 0) {
            return <ErrorDisplay message="Aucun client associé" debug={user} />
        }

        const customer = user.customers[0]

        return (
            <ProvideCommonData>
                <ProvideCustomer customerId={customer.id}>
                    <ProvideSites customerId={customer.id}>
                        <ProvideDisasters customerId={customer.id}>
                            <ProvideContracts customerId={customer.id}>
                                <CustomerZoneDataLoader />
                            </ProvideContracts>
                        </ProvideDisasters>
                    </ProvideSites>
                </ProvideCustomer>
            </ProvideCommonData>
        )
    } else if (user.userType === EnumUser.admin) {
        // AEA Redirect
        return <Redirect to={RoutesHelper.views.AEA.Home.to()} />
    } else {
        return <ErrorDisplay message="User type is unknown" debug={user} />
    }
}

const CustomerZoneDataLoader = () => {
    const auth = useAuthUser()
    const { customer, customerLoading } = useCustomer()
    const { disastersLoading } = useDisasters()
    const { commonDataLoading } = useCommonData()
    const { contractsLoading } = useContracts()

    const children =
        customerLoading || disastersLoading || commonDataLoading || contractsLoading ? (
            <LoadingDisplay />
        ) : !customer ? (
            <ErrorDisplay message={`Customer is null`} debug={auth} />
        ) : (
            <CustomerZoneReady />
        )

    return (
        <MatxLayout zone={NavType.customer} customer={customer}>
            {children}
        </MatxLayout>
    )
}

const CustomerZoneReady = () => {
    return (
        <Switch>
            <Route exact path={RoutesHelper.views.Customer.Home.path} component={HomeCustomer} />
            <Route exact path={RoutesHelper.views.Customer.AdminContract.Home.path} component={CustomerAdminContract} />
            <Route exact path={RoutesHelper.views.Customer.enterprises.Home.path} component={CustomerEnterprises} />
            <Route path={RoutesHelper.views.Customer.enterprises.Enterprise.path} component={CustomerEnterprise} />
            <Route path={RoutesHelper.views.Customer.orgChart.Home.path} component={CustomerOrgChart} />
            <Route exact path={RoutesHelper.views.Customer.contracts.Home.path} component={CustomerContracts} />
            <Route path={RoutesHelper.views.Customer.contracts.Contract.path} component={CustomerContract} />
            <Route exact path={RoutesHelper.views.Customer.vehicles.Home.path} component={CustomerVehicles} />
            <Route path={RoutesHelper.views.Customer.vehicles.Vehicle.path} component={CustomerVehicle} />
            <Route exact path={RoutesHelper.views.Customer.places.Home.path} component={CustomerPlaces} />
            <Route path={RoutesHelper.views.Customer.places.Place.path} component={CustomerPlace} />
            <Route exact path={RoutesHelper.views.Customer.disasters.Home.path} component={CustomerDisasters} />
            <Route path={RoutesHelper.views.Customer.disasters.Disaster.path} component={CustomerDisaster} />
            <Route exact path={RoutesHelper.views.Customer.Documents.path} component={CustomerDocuments} />
            <Route exact path={RoutesHelper.views.Customer.preventions.Home.path} component={CustomerPreventions} />
            <Route
                exact
                path={RoutesHelper.views.Customer.preventions.Prevention.path}
                component={CustomerPrevention}
            />
            <Route exact path={RoutesHelper.views.Customer.Roadmap.path} component={CustomerRoadmap} />
            <Route exact path={RoutesHelper.views.Customer.Users.path} component={CustomerUsers} />

            <Route component={NotFound} />
        </Switch>
    )
}

export default CustomerZone
