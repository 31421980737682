import DateFnsUtils from '@date-io/date-fns'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthGuard from './app/components/AuthGuard'
import NotFound from './app/components/NotFound'
import { SettingsProvider } from './app/contexts/SettingsContext'
import DateHelper from './app/helpers/DateHelper'
import { ProvideErrorService } from './app/helpers/errors/ErrorService'
import RoutesHelper from './app/helpers/RoutesHelper'
import { ProvideAuth } from './app/hooks/useAuth'
import { ProvideSnackBar } from './app/hooks/useSnackBar'
import AEAZone from './app/views/aea/AEAZone'
import AuthenticatedRootSwitch from './app/views/AuthenticatedRootSwitch'
import CustomerZone from './app/views/customer/CustomerZone'
import Login from './app/views/Login'
import { client } from './graphql/client'
import { GlobalCss, MatxTheme } from './matx'
import MatxSuspense from './matx/components/MatxSuspense/MatxSuspense'
import AccountConfirm from './app/views/external/AccountConfirm'
import PasswordChange from './app/views/external/PasswordChange'
import ForgotPassword from './app/views/ForgotPassword'
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'

const GlobalComponentWrapper = () => {
    const { locale } = DateHelper()

    const AuthenticatedComponent = (
        <Switch>
            <Route exact path={RoutesHelper.views.Root.path} component={AuthenticatedRootSwitch} />
            <Route path={RoutesHelper.bazeZones.aea} component={AEAZone} />
            <Route path={RoutesHelper.bazeZones.root} component={AEAZone} />
            <Route path={RoutesHelper.bazeZones.customer} component={CustomerZone} />
            <Route component={NotFound} />
        </Switch>
    )

    const NotAuthenticatedComponent = (
        <Switch>
            <Route component={Login} />
        </Switch>
    )

    const AuthGuardComponent = () => (
        <AuthGuard authenticated={AuthenticatedComponent} notAuthenticated={NotAuthenticatedComponent} />
    )

    return (
        <ApolloProvider client={client}>
            <SettingsProvider>
                <MatxTheme>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                        <Fragment>
                            <GlobalCss />
                            <Router>
                                <ProvideAuth>
                                    <MatxSuspense>
                                        <ProvideSnackBar>
                                            <ProvideErrorService>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path={RoutesHelper.views.external.AccountConfirm.path}
                                                        component={AccountConfirm}
                                                    />
                                                    <Route
                                                        exact
                                                        path={RoutesHelper.views.external.PasswordChange.path}
                                                        component={PasswordChange}
                                                    />
                                                    <Route
                                                        exact
                                                        path={RoutesHelper.views.ForgotPassword.path}
                                                        component={ForgotPassword}
                                                    />
                                                    <Route component={AuthGuardComponent} />
                                                </Switch>
                                            </ProvideErrorService>
                                        </ProvideSnackBar>
                                    </MatxSuspense>
                                </ProvideAuth>
                            </Router>
                        </Fragment>
                    </MuiPickersUtilsProvider>
                </MatxTheme>
            </SettingsProvider>
        </ApolloProvider>
    )
}

export default GlobalComponentWrapper
