import queries from '../../../graphql/queries'
import {
    GetCustomerDashboardData,
    GetCustomerDashboardDataVariables,
} from '../../../graphql/queries/typings/GetCustomerDashboardData'
import { CustomerDashboardData } from '../../helpers/data/models/CustomerDashboardData'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type GetCustomerDashboardQuery = {
    dashboardDataLoading: boolean
    dashboardData: CustomerDashboardData | null
}

export type GetCustomerDashboardDataProps = GetCustomerDashboardDataVariables

const useGetCustomerDashboardData = (props: GetCustomerDashboardDataProps): GetCustomerDashboardQuery => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetCustomerDashboardData, GetCustomerDashboardDataVariables>(
        queries.GetCustomerDashboardData,
        { variables: props, fetchPolicy: 'network-only' }
    )

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des données du dashboard', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const dashboardData = responseData && responseData.dashboardProject ? responseData.dashboardProject : null

        return {
            dashboardData,
            dashboardDataLoading: false,
        }
    } else {
        return {
            dashboardData: null,
            dashboardDataLoading: true,
        }
    }
}

export default useGetCustomerDashboardData
