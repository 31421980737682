import React from 'react'
import useGetPlaceDocuments from '../../../../../helpers/data/getPlace'
import { Document_User } from '../../../../../helpers/data/models/Document'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import DocumentsList from '../../../../documentsList/DocumentsList'
import LoadingDisplay from '../../../../LoadingDisplay'

const PlaceDocuments = () => {
    const { place } = usePlaceLoadedExisting()

    const placeDocumentsState = useGetPlaceDocuments(place.id)

    if (placeDocumentsState.placeLoading) {
        return <LoadingDisplay />
    } else if (!placeDocumentsState.place) {
        return (
            <ErrorDisplay
                message="Erreur lors de la récupération des documents d'un local"
                debug={placeDocumentsState}
            />
        )
    } else return <PlaceDocumentsReady documents={placeDocumentsState.place.documents} />
}

type PlaceDocumentsReadyProps = {
    documents: Document_User[]
}

const PlaceDocumentsReady = ({ documents }: PlaceDocumentsReadyProps) => {
    return <DocumentsList documents={documents} mode="confirmed" showLink={false} />
}

export default PlaceDocuments
