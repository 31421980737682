import { createContext, ReactElement, useContext } from 'react'
import queries from '../../graphql/queries'
import { GetPlace, GetPlaceVariables, GetPlace_place } from '../../graphql/queries/typings/GetPlace'
import { useErrorService } from '../helpers/errors/ErrorService'
import useQueryWrapper from './useQueryWrapper'

type PlaceContext = {
    place: GetPlace_place | null
    placeLoading: boolean
}

const PlaceContext = createContext<PlaceContext>({
    place: null,
    placeLoading: true,
})

type ProvidePlaceProps = {
    children: ReactElement
    placeId: string
}

export function ProvidePlace({ children, placeId }: ProvidePlaceProps) {
    const placeData = useProvidePlace(placeId)

    return <PlaceContext.Provider value={placeData}>{children}</PlaceContext.Provider>
}

export const usePlace = () => {
    return useContext(PlaceContext)
}

export const usePlaceLoadedExisting = () => {
    const context = useContext(PlaceContext)

    if (context.placeLoading) {
        console.error('Current place is loading !')
        throw new Error('Current place is loading')
    }

    if (!context.place) {
        console.error(`Place is loading !`)
        throw new Error('Place is loading !')
    }

    return {
        place: context.place,
        placeLoading: context.placeLoading,
    }
}

const useProvidePlace = (placeId: string) => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetPlace, GetPlaceVariables>(queries.GetPlace, {
        variables: { placeID: placeId },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération du local', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const place = responseData && responseData.place ? responseData.place : null

        return {
            place,
            placeLoading: false,
        }
    } else {
        return {
            place: null,
            placeLoading: true,
        }
    }
}

export default usePlace
