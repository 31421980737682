import { AppBar, Icon, Toolbar, Tooltip } from '@material-ui/core'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import useQueryWrapper from '../../../../app/hooks/useQueryWrapper'
import useSettings from '../../../../app/hooks/useSettings'
import queries from '../../../../graphql/queries'
import { GetApiInfos } from '../../../../graphql/queries/typings/GetApiInfos'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    footer: {
        minHeight: 'var(--topbar-height)',
        '@media (max-width: 499px)': {
            display: 'table',
            width: '100%',
            minHeight: 'auto',
            padding: '1rem 0',
            '& .container': {
                flexDirection: 'column !important',
                '& a': {
                    margin: '0 0 16px !important',
                },
            },
        },
    },
    appbar: {
        zIndex: 96,
    },
}))

const Footer = () => {
    const classes = useStyles()
    const theme = useTheme()
    const { settings } = useSettings()

    const footerTheme = settings.themes[settings.footer.theme] || theme

    const queryResult = useQueryWrapper<GetApiInfos>(queries.GetApiInfos)

    const apiTooltip = useMemo(() => {
        if (!queryResult.data) return null

        const { coreVersion, date, environment, version } = queryResult.data.getApiInfos
        const labels = [
            `CoreVersion: ${coreVersion}`,
            `Date: ${date}`,
            `Environment: ${environment}`,
            `Version: ${version}`,
        ]

        return (
            <Tooltip
                title={labels.map((label) => (
                    <div>{label}</div>
                ))}>
                <Icon fontSize="small" style={{ marginLeft: 10, position: 'relative', top: 5 }}>
                    help_outline
                </Icon>
            </Tooltip>
        )
    }, [queryResult])

    return (
        <ThemeProvider theme={footerTheme}>
            <AppBar color="primary" position="static" className={classes.appbar}>
                <Toolbar className={clsx('flex items-center', classes.footer)}>
                    <div className="flex items-center container w-full">
                        <p className="m-0">
                            <a href="https://www.australexpertdassure.com/">
                                Portail fourni par Austral Expert d'Assuré
                            </a>
                            {apiTooltip}
                        </p>
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}

export default Footer
