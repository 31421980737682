import queries from '../../../graphql/queries'
import {
    GetEnterprise_Documents,
    GetEnterprise_DocumentsVariables,
    GetEnterprise_Documents_enterprise
} from '../../../graphql/queries/typings/GetEnterprise_Documents'
import {
    GetEnterprise_Elements,
    GetEnterprise_ElementsVariables,
    GetEnterprise_Elements_enterprise
} from '../../../graphql/queries/typings/GetEnterprise_Elements'
import {
    GetEnterprise_Full,
    GetEnterprise_FullVariables,
    GetEnterprise_Full_enterprise
} from '../../../graphql/queries/typings/GetEnterprise_Full'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type EnterpriseData = {
    enterprise: GetEnterprise_Full_enterprise | null
    enterpriseLoading: boolean
}

const useGetEnterprise = (enterpriseId: string): EnterpriseData => {
    const { errorAlert } = useErrorService()

    const enterpriseQueryResult = useQueryWrapper<GetEnterprise_Full, GetEnterprise_FullVariables>(
        queries.GetEnterprise_Full,
        {
            variables: { enterpriseId },
        }
    )

    if (enterpriseQueryResult.error) {
        errorAlert("Erreur lors de la récupération de l'entreprise", enterpriseQueryResult)
    }

    const queryLoading = enterpriseQueryResult.loading
    const responseData = enterpriseQueryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

type EnterpriseDocumentsData = {
    enterprise: GetEnterprise_Documents_enterprise | null
    enterpriseLoading: boolean
}

export const useGetEnterpriseDocuments = (enterpriseId: string): EnterpriseDocumentsData => {
    const { errorAlert } = useErrorService()

    const enterpriseQueryResult = useQueryWrapper<GetEnterprise_Documents, GetEnterprise_DocumentsVariables>(
        queries.GetEnterprise_Documents,
        {
            variables: { enterpriseId },
        }
    )

    if (enterpriseQueryResult.error) {
        errorAlert("Erreur lors de la récupération des documents de l'entreprise", enterpriseQueryResult)
    }

    const queryLoading = enterpriseQueryResult.loading
    const responseData = enterpriseQueryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

type EnterpriseElementsData = {
    enterprise: GetEnterprise_Elements_enterprise | null
    enterpriseLoading: boolean
}

export const useGetEnterpriseElements = (enterpriseId: string): EnterpriseElementsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetEnterprise_Elements, GetEnterprise_ElementsVariables>(
        queries.GetEnterprise_Elements,
        {
            variables: { enterpriseId },
            fetchPolicy: 'network-only',
        }
    )

    if (queryResult.error) {
        errorAlert("Erreur lors de la récupération des éléments de l'entreprise", queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

export default useGetEnterprise
