import queries from '../../../graphql/queries'
import { GetVehicles_Minimal, GetVehicles_MinimalVariables } from '../../../graphql/queries/typings/GetVehicles_Minimal'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Vehicle_Minimal } from './models/fleet/Vehicle'

type VehiclesData = {
    vehicles: Vehicle_Minimal[] | null
    vehiclesLoading: boolean
}

type GetVehiclesProps = {
    customerId: string
    enterpriseId?: string
}

const useGetVehicles = ({ customerId, enterpriseId }: GetVehiclesProps): VehiclesData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetVehicles_Minimal, GetVehicles_MinimalVariables>(
        queries.GetVehicles_Minimal,
        {
            variables: {
                customerId,
                enterpriseId,
            },
        }
    )

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const vehicles = responseData && responseData.vehicles ? responseData.vehicles : null

        return {
            vehicles,
            vehiclesLoading: false,
        }
    } else {
        return {
            vehicles: null,
            vehiclesLoading: true,
        }
    }
}

export default useGetVehicles
