import { createContext, ReactElement, useContext } from 'react'
import useGetSites from '../helpers/data/getSites'
import { Site_Minimal } from '../helpers/data/models/Site'
import { useAuthLoaded } from './useAuth'

type SitesContext = {
    sites: Site_Minimal[] | null
    sitesLoading: boolean
}

const SitesContext = createContext<SitesContext>({
    sites: null,
    sitesLoading: true,
})

type ProvideSitesProps = {
    children: ReactElement
    customerId: string
}

export function ProvideSites({ children, customerId }: ProvideSitesProps) {
    const sitesData = useProvideSites(customerId)

    return <SitesContext.Provider value={sitesData}>{children}</SitesContext.Provider>
}

export const useSites = () => {
    return useContext(SitesContext)
}

export const useSitesLoadedExisting = () => {
    const context = useContext(SitesContext)
    if (context.sitesLoading) {
        console.error('Erreur : les sites sont toujours en cours de chargement !')
        throw new Error('Erreur : les sites sont toujours en cours de chargement !')
    }
    if (!context.sites) {
        console.error(`Erreur lors de la récupération des sites !`)
        throw new Error('Erreur lors de la récupération des sites !')
    }

    return {
        sites: context.sites,
        sitesLoading: context.sitesLoading,
    }
}

const useProvideSites = (customerId: string) => {
    const { sites, sitesLoading } = useGetSites(customerId)

    return {
        sites,
        sitesLoading,
    }
}

export default useSites
