import { OperationVariables } from '@apollo/client/core/types'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'
import { client } from '../../../graphql/client'

// This helper is used to check and manage auth errors
export default function runQuery<TData = any, TVariables = OperationVariables>(options: QueryOptions<TVariables>) {
    const query = client.query<TData, TVariables>(options)

    return query
}
