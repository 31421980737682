import ErrorService from './ErrorService'

type ErrorDisplayProps = {
    message: string
    debug?: any
}

const ErrorDisplay = ({ message, debug }: ErrorDisplayProps) => {
    ErrorService.logError(message, debug)

    return <div style={{ color: 'red' }} className="p-6">{message}</div>
}

export default ErrorDisplay
