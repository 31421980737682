import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useState } from 'react'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { UserGeneric_user } from '../../../../graphql/queries/typings/UserGeneric'
import { isStringInputValid } from '../../../../utilsTs'
import { Enterprise_Minimal } from '../../../helpers/data/models/Enterprise'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import UserHelper from '../../../helpers/UserHelper'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useCreateUser, useUpdateUser } from '../../../hooks/useMutations'
import CustomButton from '../../CustomButton'
import { Dialog } from '../../Dialog'

type UserDialogProps = {
    user?: UserForDialog
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

type Data = {
    firstname: string | null
    lastname: string | null
    email: string | null
    phone: string | null
    linkedEnterprisesIds: string[]
}

const emptyData: Data = {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    linkedEnterprisesIds: ['all'],
}

type UserForDialog = Pick<UserGeneric_user, 'id' | 'firstname' | 'lastname' | 'email' | 'phone'> & {
    restrictedToEnterprises: Pick<EnterpriseGeneric_enterprise, 'id' | 'title'>[]
}

const UserDialog = ({ user, onDone, open, closeDialog }: UserDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()

    const [data, setData] = useState<Data>(
        user
            ? {
                  firstname: user.firstname,
                  lastname: user.lastname,
                  email: user.email,
                  phone: user.phone || null,
                  linkedEnterprisesIds:
                      user.restrictedToEnterprises.length > 0
                          ? UserHelper.getCustomerRestrictedEnterprisesIds(user, customer)
                          : ['all'],
              }
            : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createUserMutation = useCreateUser()
    const updateUserMutation = useUpdateUser()

    const getEnterprisesLabel = (id: string) => {
        if (id === 'all') return 'Toutes les entreprises'

        const enterprise = customer.enterprises.find((enterprise) => enterprise.id === id)

        if (!enterprise) throw new Error(`Entreprise avec id ${id} absente`)

        return enterprise.title
    }

    const handleChange = (value: string, prop: 'firstname' | 'lastname' | 'email' | 'phone') => {
        setData((oldData) => {
            return { ...oldData, [prop]: value }
        })
    }

    const handleEnterpriseChange = (value: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                linkedEnterprisesIds: [value],
            }
        })
    }

    // const handleEnterprisesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setData((oldData) => {
    //         return {
    //             ...oldData,
    //             linkedEnterprisesIds: event.target.value as string[],
    //         }
    //     })
    // }

    const handleSubmit = async () => {
        if (data.firstname === null) return
        if (data.lastname === null) return
        if (data.email === null) return

        setLoading(true)

        try {
            const linkedEnterprisesIds = data.linkedEnterprisesIds.filter((id) => id !== 'all')

            if (user) {
                //Update

                const response = await updateUserMutation.run({
                    id: user.id,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    phone: data.phone,
                    enterpriseIds: linkedEnterprisesIds,
                })

                if (response.user) {
                    onDone()
                } else {
                    errorAlert("Une erreur est survenue pendant la mise à jour de l'utilisateur", response)
                }
            } else {
                //Create
                const response = await createUserMutation.run({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    phone: data.phone,
                    customerId: customer.id,
                    enterpriseIds: linkedEnterprisesIds,
                })

                if (response.user) {
                    onDone()
                } else {
                    errorAlert("Une erreur est survenue pendant la création de l'utilisateur", response)
                }

                setData(emptyData)
            }

            closeDialog()
        } catch (error) {
            errorAlert("Erreur pendant la création de l'utilisateur", error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid =
        isStringInputValid(data.firstname) && isStringInputValid(data.lastname) && isStringInputValid(data.email)

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un utilisateur</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstname"
                    label="Prénom *"
                    type="text"
                    fullWidth
                    value={data.firstname || ''}
                    onChange={(event) => handleChange(event.target.value, 'firstname')}
                />
                <TextField
                    margin="dense"
                    id="lastname"
                    label="Nom de famille *"
                    type="text"
                    fullWidth
                    value={data.lastname || ''}
                    onChange={(event) => handleChange(event.target.value, 'lastname')}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Adresse mail *"
                    type="email"
                    fullWidth
                    value={data.email || ''}
                    onChange={(event) => handleChange(event.target.value, 'email')}
                    disabled={!!user}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="Téléphone"
                    type="text"
                    fullWidth
                    value={data.phone || ''}
                    onChange={(event) => handleChange(event.target.value, 'phone')}
                />
                <TextField
                    margin="dense"
                    name="linkedEnterprises"
                    label="Restrictions d'accès"
                    fullWidth
                    select
                    onChange={(event) => handleEnterpriseChange(event.target.value)}
                    value={data.linkedEnterprisesIds[0]}>
                    <MenuItem value="all">
                        <b>Toutes les entreprises</b>
                    </MenuItem>
                    {customer.enterprises.map((enterprise: Enterprise_Minimal) => (
                        <MenuItem key={enterprise.id} value={enterprise.id}>
                            {enterprise.title}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <CustomButton color="secondary" onClick={closeDialog}>
                    Annuler
                </CustomButton>
                <CustomButton
                    loading={loading}
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default UserDialog
