import queries from '../../../graphql/queries'
import { GetPlace_Documents, GetPlace_DocumentsVariables } from '../../../graphql/queries/typings/GetPlace_Documents'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Place_Documents } from './models/Place'

type PlaceDocumentsData = {
    place: Place_Documents | null
    placeLoading: boolean
}

const useGetPlaceDocuments = (placeId: string): PlaceDocumentsData => {
    const { errorAlert } = useErrorService()
    
    const queryResult = useQueryWrapper<GetPlace_Documents, GetPlace_DocumentsVariables>(queries.GetPlace_Documents, {
        variables: { placeId },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des documents du local', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const place = responseData && responseData.local ? responseData.local : null

        return {
            place,
            placeLoading: false,
        }
    } else {
        return {
            place: null,
            placeLoading: true,
        }
    }
}

export default useGetPlaceDocuments
