import queries from '../../../graphql/queries'
import {
    GetContract_Disasters,
    GetContract_DisastersVariables,
    GetContract_Disasters_contract,
} from '../../../graphql/queries/typings/GetContract_Disasters'
import {
    GetContract_Documents,
    GetContract_DocumentsVariables,
} from '../../../graphql/queries/typings/GetContract_Documents'
import {
    GetContract_Full,
    GetContract_FullVariables,
    GetContract_Full_contract,
} from '../../../graphql/queries/typings/GetContract_Full'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Contract_Documents } from './models/contract/Contract'

type ContractState = {
    contractLoading: boolean
    contract: GetContract_Full_contract | null
}

const useGetContract = (contractId: string): ContractState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetContract_Full, GetContract_FullVariables>(queries.GetContract_Full, {
        variables: { contractId },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération du contrat', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const contract = responseData && responseData.contract ? responseData.contract : null

        return {
            contract,
            contractLoading: false,
        }
    } else {
        return {
            contract: null,
            contractLoading: true,
        }
    }
}

type ContractDisastersState = {
    contractLoading: boolean
    contract: GetContract_Disasters_contract | null
}

export const useGetContractDisasters = (contractId: string): ContractDisastersState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetContract_Disasters, GetContract_DisastersVariables>(
        queries.GetContract_Disasters,
        {
            variables: { contractId },
        }
    )

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des contrats', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const contract = responseData && responseData.contract ? responseData.contract : null

        return {
            contract,
            contractLoading: false,
        }
    } else {
        return {
            contract: null,
            contractLoading: true,
        }
    }
}

type ContractDocumentsState = {
    contractLoading: boolean
    contract: Contract_Documents | null
}

export const useGetContractDocuments = (contractId: string): ContractDocumentsState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetContract_Documents, GetContract_DocumentsVariables>(
        queries.GetContract_Documents,
        {
            variables: { contractId },
        }
    )

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des documents du contrat', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const contract = responseData && responseData.contract ? responseData.contract : null

        return {
            contract,
            contractLoading: false,
        }
    } else {
        return {
            contract: null,
            contractLoading: true,
        }
    }
}

export default useGetContract
