import { createContext, ReactElement, useContext } from 'react'
import queries from '../../graphql/queries'
import { GetContracts_contracts } from '../../graphql/queries/typings/GetContracts'
import {
    GetEnterpriseContracts,
    GetEnterpriseContractsVariables,
    GetEnterpriseContracts_contracts,
} from '../../graphql/queries/typings/GetEnterpriseContracts'
import useGetContracts from '../helpers/data/getContracts'
import useQueryWrapper from './useQueryWrapper'

type ContractsData = {
    contracts: GetContracts_contracts[] | null
    contractsLoading: boolean
}

type ContractsContext = {
    contracts: GetContracts_contracts[] | null
    contractsLoading: boolean
}

const ContractsContext = createContext<ContractsContext>({
    contracts: null,
    contractsLoading: true,
})

type ProvideContractsProps = {
    children: ReactElement
    customerId: string
}

export function ProvideContracts({ children, customerId }: ProvideContractsProps) {
    const sitesData = useProvideContracts(customerId)

    return <ContractsContext.Provider value={sitesData}>{children}</ContractsContext.Provider>
}

export const useContracts = () => {
    return useContext(ContractsContext)
}

export const useContractsLoadedExisting = () => {
    const context = useContext(ContractsContext)
    if (context.contractsLoading) {
        console.error('Erreur : les contrats sont toujours en cours de chargement !')
        throw new Error('Erreur : les contrats sont toujours en cours de chargement !')
    }
    if (!context.contracts) {
        console.error(`Erreur lors de la récupération des contrats !`)
        throw new Error('Erreur lors de la récupération des contrats !')
    }

    return {
        contracts: context.contracts,
        contractsLoading: context.contractsLoading,
    }
}

// If no enterpriseId provided, we get all the customer's contracts
const useProvideContracts = (customerId: string): ContractsData => {
    const { contracts, contractsLoading } = useGetContracts({ customerId })

    return {
        contracts,
        contractsLoading,
    }
}

type UseDisasterContractsProps = {
    customerId: string
    enterpriseId: string
}

type EnterpriseContractsData = {
    contracts: GetEnterpriseContracts_contracts[] | null
    contractsLoading: boolean
}

export const useEnterpriseContracts = ({
    customerId,
    enterpriseId,
}: UseDisasterContractsProps): EnterpriseContractsData => {
    const queryResult = useQueryWrapper<GetEnterpriseContracts, GetEnterpriseContractsVariables>(
        queries.GetEnterpriseContracts,
        {
            variables: {
                customerId,
                enterpriseId,
            },
        }
    )

    if (!queryResult.loading) {
        const contracts = queryResult.data && queryResult.data.contracts ? queryResult.data.contracts : null

        return {
            contracts,
            contractsLoading: false,
        }
    } else {
        return {
            contracts: null,
            contractsLoading: true,
        }
    }
}

export default useContracts
