import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import TextField from '@material-ui/core/TextField/TextField'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'

export type DMA_Element = {
    label: string
    id: string
}

type DocumentManagerAssociationElementTextFieldProps = {
    data: DMA_Data
    setData: DMA_SetData
    elements: DMA_Element[]
    elementLabel: string
}

const DocumentManagerAssociationElementTextField = ({
    data,
    setData,
    elements,
    elementLabel,
}: DocumentManagerAssociationElementTextFieldProps) => {
    const handleChange = (value: string) => {
        setData({ docElementType: data.docElementType, docElementId: value })
    }

    return (
        <TextField
            margin="dense"
            id="type"
            label={elements.length > 0 ? `${elementLabel} à rattacher` : `Aucun ${elementLabel} à rattacher`}
            type="text"
            fullWidth
            value={data.docElementId || ''}
            onChange={(event) => handleChange(event.target.value)}
            disabled={elements.length === 0}
            select>
            {elements.map((element) => {
                return (
                    <MenuItem value={element.id} key={element.id}>
                        {element.label}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

export default DocumentManagerAssociationElementTextField
