import queries from '../../../graphql/queries'
import {
    GetContracts,
    GetContractsVariables,
    GetContracts_contracts,
} from '../../../graphql/queries/typings/GetContracts'
import { DateRange } from '../../components/DateCategoryInput'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import DateHelper from '../DateHelper'
import { useErrorService } from '../errors/ErrorService'

type ContractsState = {
    contractsLoading: boolean
    contracts: GetContracts_contracts[] | null
}

type GetContractsProps = {
    customerId: string
    dateRange?: DateRange
}

const useGetContracts = ({ customerId, dateRange }: GetContractsProps): ContractsState => {
    const { errorAlert } = useErrorService()

    const { fromDateToServer } = DateHelper()

    const queryResult = useQueryWrapper<GetContracts, GetContractsVariables>(queries.GetContracts, {
        variables: {
            customerId,
            startAtAfter: dateRange && dateRange.startDate ? fromDateToServer(dateRange.startDate) : undefined,
            startAtBefore: dateRange && dateRange.endDate ? fromDateToServer(dateRange.endDate) : undefined,
        },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des contrats', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const contracts = responseData && responseData.contracts ? responseData.contracts : null

        return {
            contracts,
            contractsLoading: false,
        }
    } else {
        return {
            contracts: null,
            contractsLoading: true,
        }
    }
}

export default useGetContracts
