import queries from '../../../graphql/queries'
import { GetFleet_Documents, GetFleet_DocumentsVariables } from '../../../graphql/queries/typings/GetFleet_Documents'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Fleet_Documents } from './models/fleet/Fleet'

type FleetData = {
    fleet: Fleet_Documents | null
    fleetLoading: boolean
}

type GetFleetDocumentsProps = {
    id: string
}

const useGetFleetDocuments = ({ id }: GetFleetDocumentsProps): FleetData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetFleet_Documents, GetFleet_DocumentsVariables>(queries.GetFleet_Documents, {
        variables: {
            id,
        },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const fleet = responseData && responseData.fleet ? responseData.fleet : null

        return {
            fleet,
            fleetLoading: false,
        }
    } else {
        return {
            fleet: null,
            fleetLoading: true,
        }
    }
}

export default useGetFleetDocuments
