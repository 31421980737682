import queries from '../../../graphql/queries'
import {
    GetCustomer_Users,
    GetCustomer_UsersVariables,
    GetCustomer_Users_project,
} from '../../../graphql/queries/typings/GetCustomer_Users'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type CustomerState = {
    customerLoading: boolean
    customer: GetCustomer_Users_project | null
}

const useGetCustomer = (customerID: string): CustomerState => {
    const { errorAlert } = useErrorService()

    const customerQueryResult = useQueryWrapper<GetCustomer_Users, GetCustomer_UsersVariables>(
        queries.GetCustomer_Users,
        {
            variables: { id: customerID },
        }
    )

    if (customerQueryResult.error) {
        errorAlert('Erreur lors de la récupération du client', customerQueryResult)
    }

    const queryLoading = customerQueryResult.loading
    const responseData = customerQueryResult.data

    if (!queryLoading) {
        const customer = responseData && responseData.project ? responseData.project : null

        return {
            customer,
            customerLoading: false,
        }
    } else {
        return {
            customer: null,
            customerLoading: true,
        }
    }
}

export default useGetCustomer
