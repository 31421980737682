import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { EnumUser } from '../../../graphql/typings/global_types'
import RoutesHelper from '../../helpers/RoutesHelper'
import { useAuthUser } from '../../hooks/useAuth'
import { ProvideCommonData } from '../../hooks/useCommonData'
import { ProvideCustomers } from '../../hooks/useCustomers'
import AEACustomerZone from './customer/AEACustomerZone'
import AEARootZone from './root/AEARootZone'

const AEAZone = () => {
    const auth = useAuthUser()

    if (auth.user.userType === EnumUser.admin) {
        return <AEAZoneReady />
    } else {
        return <Redirect to={RoutesHelper.views.Customer.Home.to()} />
    }
}

const AEAZoneReady = () => {
    return (
        <ProvideCommonData>
            <ProvideCustomers>
                <Switch>
                    <Route path={RoutesHelper.aeaZones.rootZone} component={AEARootZone} />
                    <Route path={RoutesHelper.aeaZones.customerZone} component={AEACustomerZone} />
                    <Route component={AEAZoneRedirect} />
                </Switch>
            </ProvideCustomers>
        </ProvideCommonData>
    )
}

const AEAZoneRedirect = () => {
    return <Redirect to={RoutesHelper.views.AEA.Home} />
}

export default AEAZone
