import { ReactElement } from 'react'
import { useAuth } from '../hooks/useAuth'
import LoadingDisplay from './LoadingDisplay'

type AuthGuardProps = {
    authenticated: ReactElement
    notAuthenticated: ReactElement
}

const AuthGuard = ({ authenticated, notAuthenticated }: AuthGuardProps) => {
    const auth = useAuth()

    if (auth.loading) return <LoadingDisplay />
    else if (auth.user) return authenticated
    else return notAuthenticated
}

export default AuthGuard
