import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import React, { Fragment, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EnumUser } from '../../../../graphql/typings/global_types'
import { Vehicle_Minimal } from '../../../helpers/data/models/fleet/Vehicle'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import IconHelper from '../../../helpers/IconHelper'
import VehicleHelper from '../../../helpers/VehicleHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteVehicle } from '../../../hooks/useMutations'
import { useNavContext } from '../../../hooks/useNavContext'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import Panel from '../../Panel'
import VehicleDialog from '../enterprises/enterprise/vehicles/VehicleDialog'

type VehiclesListProps = {
    vehicles: Vehicle_Minimal[]
}

type FormattedData = {
    title: string
    registration: string
    description: string
    enterprise: string
    baseVehicle: Vehicle_Minimal
}

type DialogState =
    | {
          open: true
          vehicle: Vehicle_Minimal
      }
    | { open: false }

const VehiclesList = ({ vehicles }: VehiclesListProps) => {
    const history = useHistory()
    const { getVehicleRoute } = useNavContext()
    const { customer } = useCustomerLoadedExisting()
    const enterprises = customer.enterprises
    const { openSnack } = useSnackBar()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const { errorAlert } = useErrorService()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const deleteVehicleMutation = useDeleteVehicle()

    const columns = [
        {
            name: 'title',
            label: 'Désignation',
        },
        {
            name: 'registration',
            label: 'Immatriculation',
        },
        {
            name: 'description',
            label: 'Description',
        },
        {
            name: 'enterprise',
            label: 'Entreprise propriétaire',
        },
    ]

    const formattedData: FormattedData[] = useMemo(() => {
        return vehicles.map((vehicle) => {
            const enterprise = enterprises.find((enterprise) => enterprise.id === vehicle.enterprise.id)

            return {
                title: vehicle.title,
                registration: vehicle.registration || '-',
                description: vehicle.description || '-',
                enterprise: enterprise ? enterprise.title : '-',
                baseVehicle: vehicle,
            }
        })
    }, [vehicles])

    const onRowClick = (formattedData: FormattedData) => {
        history.push(getVehicleRoute(formattedData.baseVehicle.id))
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un véhicule`,
    }

    const onCreated = (vehicle: Vehicle_Minimal) => {
        openSnack({
            type: 'success',
            message: `Véhicule créé avec succès !`,
        })
    }

    const onEdited = (vehicle: Vehicle_Minimal) => {
        openSnack({
            message: 'Véhicule modifié avec succès !',
            type: 'success',
        })
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const vehicle = vehicles[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le véhicule">
                    <IconButton onClick={(event) => onEditClick(event, vehicle)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le véhicule">
                    <IconButton onClick={(event) => onDeleteClick(event, vehicle)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, vehicle: Vehicle_Minimal) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, vehicle })
    }

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, vehicle: Vehicle_Minimal) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, vehicle })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const vehicle = openDeleteDialog.vehicle

            const response = await deleteVehicleMutation.run({
                enterpriseId: vehicle.enterprise.id,
                customerId: customer.id,
                id: vehicle.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du véhicule.'
            } else {
                openSnack({
                    message: 'Véhicule supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<FormattedData>
                    title={'Véhicules'}
                    icon={IconHelper.elementIcons.vehicleIcon}
                    data={formattedData}
                    columns={columns}
                    showSearch={true}
                    rowClick={onRowClick}
                    addFeature={addFeatureData}
                    actionsColumn={actionsColumn}
                />
            </Panel>
            <VehicleDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <VehicleDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    vehicle={openEditDialog.vehicle}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un véhicule"
                    text={`Voulez-vous vraiment supprimer le véhicule "${VehicleHelper.getLabel(
                        openDeleteDialog.vehicle
                    )}" ?`}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default VehiclesList
