import queries from '../../graphql/queries'
import { GetDocuments_User, GetDocuments_UserVariables } from '../../graphql/queries/typings/GetDocuments_User'
import { Document_User } from '../helpers/data/models/Document'
import { useErrorService } from '../helpers/errors/ErrorService'
import useQueryWrapper from './useQueryWrapper'

type DocumentsData = {
    documents: Document_User[] | null
    documentsLoading: boolean
}

const useDocuments = (customerId: string): DocumentsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetDocuments_User, GetDocuments_UserVariables>(queries.GetDocuments_User, {
        variables: { customerId },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des documents', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const documents = responseData && responseData.documents ? responseData.documents : null

        return {
            documents,
            documentsLoading: false,
        }
    } else {
        return {
            documents: null,
            documentsLoading: true,
        }
    }
}

export default useDocuments
