import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useState } from 'react'
import { CustomerGeneric_customer } from '../../../../../graphql/queries/typings/CustomerGeneric'
import { isStringInputValid } from '../../../../../utilsTs'
import CustomButton from '../../../../components/CustomButton'
import { Dialog } from '../../../../components/Dialog'
import { UserAEA_Minimal } from '../../../../helpers/data/models/User'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import UserHelper from '../../../../helpers/UserHelper'
import { useCommonDataLoadedExisting } from '../../../../hooks/useCommonData'
import { useCreateCustomer, useUpdateCustomer } from '../../../../hooks/useMutations'

type AddCustomerDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
    customer?: CustomerForDialog
}

type CustomerForDialog = Pick<CustomerGeneric_customer, 'id' | 'title'> & {
    supervisor: UserAEA_Minimal
}

type Data = {
    title: string
    supervisor: UserAEA_Minimal | null
}

const emptyData = {
    title: '',
    supervisor: null,
}

const CustomerDialog = ({ onDone, open, closeDialog, customer }: AddCustomerDialogProps) => {
    const { admins } = useCommonDataLoadedExisting()
    const { errorAlert } = useErrorService()

    const [data, setData] = useState<Data>(
        customer ? { title: customer.title, supervisor: customer.supervisor } : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createCustomerMutation = useCreateCustomer()
    const updateCustomerMutation = useUpdateCustomer()

    const handleClose = () => {
        closeDialog()
    }

    const handleTitleChange = (value: string) => {
        setData({
            ...data,
            title: value,
        })
    }

    const handleSupervisorChange = (supervisorId: string) => {
        const newSupervisor = admins.find((admin) => admin.id === supervisorId)

        if (!newSupervisor) {
            errorAlert(`Aucun admin trouvé avec cet identifiant ${supervisorId}`, admins)
            return
        }

        setData((oldData) => {
            return {
                ...oldData,
                supervisor: newSupervisor,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return
        if (!data.supervisor) return

        setLoading(true)

        if (customer) {
            // Updating
            const response = await updateCustomerMutation.run({
                customerId: customer.id,
                title: data.title,
                supervisor: data.supervisor,
            })

            if (response.customer) {
                onDone()
            } else {
                errorAlert('Une erreur est survenue pendant la mise à jour du client', response)
            }
        } else {
            // Creating
            const response = await createCustomerMutation.run({ title: data.title, supervisor: data.supervisor })

            if (response.customer) {
                onDone()
            } else {
                errorAlert('Une erreur est survenue pendant la création du client', response)
            }
        }

        setLoading(false)
        closeDialog()
    }

    const isFormValid = isStringInputValid(data.title) && data.supervisor !== null

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un client</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Saisie des informations principales du nouveau client. Les autres informations pourront être
                    ajoutées et modifiées par la suite
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom du client *"
                    type="text"
                    fullWidth
                    value={data.title}
                    onChange={(event) => handleTitleChange(event.target.value)}
                />
                <TextField
                    margin="dense"
                    id="supervisor"
                    label="Administrateur du client *"
                    type="text"
                    fullWidth
                    value={data.supervisor ? data.supervisor.id : ''}
                    onChange={(event) => handleSupervisorChange(event.target.value)}
                    select>
                    {admins.map((admin) => {
                        return (
                            <MenuItem value={admin.id} key={admin.id}>
                                {UserHelper.getFullName(admin.firstname, admin.lastname)}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default CustomerDialog
