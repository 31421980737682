import React from 'react'
import Place, { PlaceTabName } from '../../../components/customer/places/place/Place'
import LoadingDisplay from '../../../components/LoadingDisplay'
import RoutesHelper, { useRouteInput } from '../../../helpers/RoutesHelper'
import usePlace, { ProvidePlace } from '../../../hooks/usePlace'

const CustomerPlace = () => {
    const { placeId, tab } = useRouteInput(RoutesHelper.views.Customer.places.Place).params

    return (
        <ProvidePlace placeId={placeId}>
            <CustomerPlaceLoading tab={tab} />
        </ProvidePlace>
    )
}

const CustomerPlaceLoading = (props: CustomerPlaceReadyProps) => {
    const { placeLoading } = usePlace()

    if (placeLoading) return <LoadingDisplay />
    else return <CustomerPlaceReady {...props} />
}

type CustomerPlaceReadyProps = {
    tab?: PlaceTabName
}

const CustomerPlaceReady = (props: CustomerPlaceReadyProps) => {
    return <Place {...props} />
}

export default CustomerPlace
