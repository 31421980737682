import queries from '../../../graphql/queries'
import { GetSites_Minimal, GetSites_MinimalVariables } from '../../../graphql/queries/typings/GetSites_Minimal'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Site_Minimal } from './models/Site'

type SitesState = {
    sitesLoading: boolean
    sites: Site_Minimal[] | null
}

const useGetSites = (customerId: string): SitesState => {
    const { errorAlert } = useErrorService()
    
    const queryResult = useQueryWrapper<GetSites_Minimal, GetSites_MinimalVariables>(queries.GetSites_Minimal, {
        variables: { customerId },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des sites', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const sites = responseData && responseData.sites ? responseData.sites : null
        
        return {
            sites,
            sitesLoading: false,
        }
    } else {
        return {
            sites: null,
            sitesLoading: true,
        }
    }
}

export default useGetSites
