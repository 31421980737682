import React from 'react'
import { Disaster_Documents } from '../../../../../../graphql/fragments/Disaster/typings/Disaster_Documents'
import { useGetDisasterDocuments } from '../../../../../helpers/data/getDisaster'
import { Document_User } from '../../../../../helpers/data/models/Document'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useDisasterLoadedExisting } from '../../../../../hooks/useDisaster'
import DocumentsList from '../../../../documentsList/DocumentsList'
import LoadingDisplay from '../../../../LoadingDisplay'

const DisasterDocuments = () => {
    const { disaster } = useDisasterLoadedExisting()

    const disasterDocumentsState = useGetDisasterDocuments(disaster.id)

    if (disasterDocumentsState.disasterLoading) {
        return <LoadingDisplay />
    } else if (!disasterDocumentsState.disaster) {
        return (
            <ErrorDisplay
                message="Erreur lors de la récupération des documents d'un sinistre"
                debug={disasterDocumentsState}
            />
        )
    } else return <DisasterDocumentsReady disaster={disasterDocumentsState.disaster} />
}

type DisasterDocumentsReadyProps = {
    disaster: Disaster_Documents
}

const DisasterDocumentsReady = ({ disaster }: DisasterDocumentsReadyProps) => {
    const documents: Document_User[] = disaster.documents

    return <DocumentsList documents={documents} mode="confirmed" showLink={false} />
}

export default DisasterDocuments
