import React, { Fragment } from 'react'
import Breadcrumb from '../../../../matx/components/Breadcrumb'
import useGetFleets from '../../../helpers/data/getFleets'
import useGetVehicles from '../../../helpers/data/getVehicles'
import { Fleet_Minimal } from '../../../helpers/data/models/fleet/Fleet'
import { Vehicle_Minimal } from '../../../helpers/data/models/fleet/Vehicle'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import IconHelper from '../../../helpers/IconHelper'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import useNavContext from '../../../hooks/useNavContext'
import LoadingDisplay from '../../LoadingDisplay'
import Spacer from '../../Spacer'
import FleetsList from '../fleets/FleetsList'
import VehiclesList from './VehiclesList'

const Vehicles = () => {
    const { customer } = useCustomerLoadedExisting()
    const { vehicles, vehiclesLoading } = useGetVehicles({ customerId: customer.id })
    const { fleets, fleetsLoading } = useGetFleets({ customerId: customer.id })
    const { getCustomerRoute, getVehiclesRoute } = useNavContext()

    const fullSegments = [
        {
            name: 'Véhicules',
            icon: IconHelper.elementIcons.vehicleIcon,
            path: getVehiclesRoute().pathname,
        },
    ]

    const customerRoute = getCustomerRoute()

    const content =
        vehiclesLoading || fleetsLoading ? (
            <LoadingDisplay />
        ) : !vehicles ? (
            <ErrorDisplay message="Une erreur est survenue lors de la récupération des véhicules" debug={customer} />
        ) : !fleets ? (
            <ErrorDisplay message="Une erreur est survenue lors de la récupération des flottes" debug={customer} />
        ) : (
            <VehiclesReady vehicles={vehicles} fleets={fleets} />
        )

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb homePath={customerRoute.pathname} routeSegments={fullSegments} />
            </div>
            {content}
        </div>
    )
}

type VehiclesReadyProps = {
    vehicles: Vehicle_Minimal[]
    fleets: Fleet_Minimal[]
}

const VehiclesReady = ({ vehicles, fleets }: VehiclesReadyProps) => {
    return (
        <Fragment>
            <VehiclesList vehicles={vehicles} />
            <Spacer />
            <FleetsList fleets={fleets} />
        </Fragment>
    )
}

export default Vehicles
