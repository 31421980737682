import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Fragment, ReactNode, useMemo, useState } from 'react'
import { PreventionCategory_Minimal } from '../../../../../../graphql/fragments/Prevention/PreventionCategory/typings/PreventionCategory_Minimal'
import {
    DocumentInput,
    EnumDocumentCategory,
    EnumDocumentParent,
    EnumDocumentStatus,
} from '../../../../../../graphql/typings/global_types'
import { Place_Minimal } from '../../../../../helpers/data/models/Place'
import { Prevention_Minimal } from '../../../../../helpers/data/models/prevention/Prevention'
import DateHelper from '../../../../../helpers/DateHelper'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useCommonDataLoadedExisting } from '../../../../../hooks/useCommonData'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import FileDialogHelper, { FileHelper, FileType } from '../../../../../hooks/useFileDialog'
import { useCreateDocuments, useCreatePrevention, useUpdatePrevention } from '../../../../../hooks/useMutations'
import { UploadManagerToolkit, useUploadManyFiles } from '../../../../../hooks/useUploadManager'
import CustomButton from '../../../../CustomButton'
import { Dialog } from '../../../../Dialog'
import DocumentUpload from '../../../../documentsList/DocumentForms/DocumentUpload'

type PreventionDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (prevention: Prevention_Minimal) => void
    prevention?: Prevention_Minimal
    preventionCategory?: PreventionCategory_Minimal
    place: Place_Minimal
}

type PreventionData = {
    preventionCategoryId: string | null
    description: string | null
    startAt: Date | null
    endAt: Date | null
    hasFileUploaded: boolean
}

const ContractDialog = ({
    onDone,
    open,
    closeDialog,
    prevention,
    preventionCategory,
    place,
}: PreventionDialogProps) => {
    const { fromDateToServer } = DateHelper()
    const { customer } = useCustomerLoadedExisting()
    const { preventionCategories } = useCommonDataLoadedExisting()
    const auth = useAuthUser()
    const emptyPrevention: PreventionData = {
        preventionCategoryId: preventionCategory ? preventionCategory.id : null,
        description: null,
        startAt: null,
        endAt: null,
        hasFileUploaded: false,
    }

    const [data, setData] = useState<PreventionData>(
        prevention
            ? {
                  preventionCategoryId: prevention.preventionCategory.id,
                  description: prevention.description,
                  startAt: new Date(prevention.startAt),
                  endAt: prevention.endAt ? new Date(prevention.endAt) : null,
                  hasFileUploaded: prevention.documents.length > 0 ? true : false,
              }
            : emptyPrevention
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createPreventionMutation = useCreatePrevention()
    const updatePreventionMutation = useUpdatePrevention()

    const { errorAlert } = useErrorService()

    /*****************
     * FILE MANAGEMENT
     ****************/

    const { uploadDocumentFile, uploadingState } = useUploadManyFiles()
    const createDocumentsMutation = useCreateDocuments()
    const { filesPickerNode, openFilesPicker, filesHelpers } = FileDialogHelper.useManyFilesDialog(FileType.ALL)
    const { getUploadedFileDataAsString } = UploadManagerToolkit()

    const deleteFile = (fileHelper: FileHelper) => {
        fileHelper.deleteFile()
    }

    const handleClose = () => {
        filesHelpers.forEach((fileHelper) => fileHelper.deleteFile())
        setLoading(false)
        closeDialog()
    }

    const handleStringChange = (value: string, prop: 'preventionCategoryId' | 'description') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleDateChange = (date: MaterialUiPickersDate, prop: 'startAt' | 'endAt') => {
        if (!date) return

        setData((oldData) => {
            return {
                ...oldData,
                [prop]: date,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.preventionCategoryId) return
        if (!data.startAt) return
        if (!data.endAt) return

        setLoading(true)

        try {
            if (prevention) {
                //Update
                const response = await updatePreventionMutation.run({
                    id: prevention.id,
                    placeId: place.id,
                    preventionCategoryId: data.preventionCategoryId,
                    description: data.description,
                    startAt: fromDateToServer(data.startAt),
                    endAt: fromDateToServer(data.endAt),
                })

                if (response.prevention) {
                    onDone(response.prevention)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour de la prévention'
                }
            } else {
                const response = await createPreventionMutation.run({
                    customerId: customer.id,
                    placeId: place.id,
                    preventionCategoryId: data.preventionCategoryId,
                    description: data.description,
                    startAt: fromDateToServer(data.startAt),
                    endAt: fromDateToServer(data.endAt),
                })

                if (response.prevention === null) {
                    throw 'Une erreur est survenue pendant la création de la prévention'
                }

                const newPrevention = response.prevention

                const documentsInput: DocumentInput[] = []

                const promises = filesHelpers.map((fileHelper) =>
                    uploadDocumentFile(fileHelper.file).then((uploadedFileData) => {
                        const fileDataAsString = getUploadedFileDataAsString(uploadedFileData)

                        documentsInput.push({
                            title: fileHelper.title,
                            // By default, we link the doc to the project
                            parentType: EnumDocumentParent.Prevention,
                            parentId: newPrevention.id,
                            askedById: auth.user.id,
                            status: EnumDocumentStatus.confirmed,
                            statusComment: "Ajouté pendant la création d'une prévention",
                            // By default, we set the category to "miscellaneous"
                            category: EnumDocumentCategory.miscellaneous,
                            fileDescriptor: fileDataAsString,
                        })
                    })
                )

                await Promise.all(promises)

                // We create the documents
                await createDocumentsMutation.run({
                    customerId: customer.id,
                    documentsInput,
                })

                if (response.prevention) {
                    onDone(response.prevention)
                } else {
                    throw 'Une erreur est survenue pendant la création de la prévention'
                }

                setData(emptyPrevention)
            }

            handleClose()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = data.preventionCategoryId !== null && data.startAt !== null && data.endAt !== null

    const documentUploadElements: ReactNode[] = useMemo(() => {
        if (prevention) return []

        const nodes = filesHelpers.map((fileHelper, index) => {
            const uploadingStatus = uploadingState[index]

            return (
                <DocumentUpload
                    key={fileHelper.id}
                    loading={loading}
                    uploadPercent={uploadingStatus ? uploadingStatus.uploadPercent : 0}
                    localFile={fileHelper.file}
                    openFilePicker={openFilesPicker}
                    hasAlreadyFileUploaded={false}
                    deleteFile={fileHelper.deleteFile}
                    editable={true}
                    title={fileHelper.title}
                    editFileName={fileHelper.editFileTitle}
                />
            )
        })

        nodes.push(
            <DocumentUpload
                loading={loading}
                uploadPercent={0}
                localFile={null}
                openFilePicker={openFilesPicker}
                hasAlreadyFileUploaded={false}
                deleteFile={() => {}}
            />
        )

        return nodes
    }, [prevention, filesHelpers, uploadingState, loading, openFilesPicker])

    return (
        <Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{prevention ? 'Modification' : 'Création'} d'une période de prévention</DialogTitle>
                <DialogContent>
                    <div className="mb-2">{documentUploadElements}</div>
                    <TextField
                        margin="dense"
                        id="preventionCategoryId"
                        label="Type de prévention *"
                        type="text"
                        fullWidth
                        value={data.preventionCategoryId || ''}
                        onChange={(event) => handleStringChange(event.target.value, 'preventionCategoryId')}
                        select>
                        {preventionCategories.map((category) => {
                            return (
                                <MenuItem value={category.id} key={category.id}>
                                    {category.title}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                    <TextField
                        margin="dense"
                        id="description"
                        label="Commentaire"
                        type="text"
                        fullWidth
                        value={data.description || ''}
                        onChange={(event) => handleStringChange(event.target.value, 'description')}
                    />
                    <DatePicker
                        margin="dense"
                        id="startAt"
                        label="Date de début *"
                        value={data.startAt}
                        onChange={(date) => handleDateChange(date, 'startAt')}
                        fullWidth
                        format="dd/MM/yyyy"
                        autoOk={true}
                    />
                    <DatePicker
                        margin="dense"
                        id="endAt"
                        label="Date d'expiration *"
                        value={data.endAt}
                        onChange={(date) => handleDateChange(date, 'endAt')}
                        fullWidth
                        format="dd/MM/yyyy"
                        className="mb-5"
                        autoOk={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <CustomButton
                        variant="outlined"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!isFormValid}
                        loading={loading}>
                        Valider
                    </CustomButton>
                </DialogActions>
            </Dialog>
            {filesPickerNode}
        </Fragment>
    )
}

export default ContractDialog
