import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Disaster_Minimal } from '../../../../../helpers/data/models/disaster/Disaster'
import DateHelper from '../../../../../helpers/DateHelper'
import DisasterHelper from '../../../../../helpers/DisasterHelper'
import IconHelper from '../../../../../helpers/IconHelper'
import { useDisastersLoadedExisting } from '../../../../../hooks/useDisasters'
import useNavContext from '../../../../../hooks/useNavContext'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import DataTable from '../../../../DataTable'

type FormattedPlaceDisasters = {
    date: string
    label: string
    category: string
    status: string
    baseDisaster: Disaster_Minimal
}

const PlaceDisasters = () => {
    const { place } = usePlaceLoadedExisting()
    const { disasters } = useDisastersLoadedExisting()
    const { fromServertoString } = DateHelper()
    const history = useHistory()
    const { getDisasterRoute } = useNavContext()

    const placeDisasters = disasters.filter((disaster) => {
        return DisasterHelper.getDisasterElements(disaster).places.find(
            (disasterPlace) => disasterPlace.id === place.id
        )
    })

    const formattedPlaceDisasters: FormattedPlaceDisasters[] = placeDisasters.map((disaster) => {
        return {
            date: fromServertoString(disaster.date),
            label: disaster.title,
            category: disaster.disasterCategory.title,
            status: DisasterHelper.getDisasterStatusLabel(disaster.status),
            baseDisaster: disaster,
        }
    })

    const onDisasterClick = ({ baseDisaster }: FormattedPlaceDisasters) => {
        history.push(getDisasterRoute(baseDisaster.id))
    }

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'date',
                label: 'Date',
            },
            {
                name: 'label',
                label: 'Libellé',
            },
            {
                name: 'category',
                label: 'Catégorie',
            },
            {
                name: 'status',
                label: 'Statut',
            },
        ],
        [placeDisasters]
    )

    return (
        <Fragment>
            <DataTable<FormattedPlaceDisasters>
                title={'Sinistres sur ce local'}
                icon={IconHelper.elementIcons.disasterIcon}
                data={formattedPlaceDisasters}
                columns={columns}
                showSearch={true}
                rowClick={onDisasterClick}
            />
        </Fragment>
    )
}

export default PlaceDisasters
