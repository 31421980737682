import React from 'react'
import queries from '../../../../../graphql/queries'
import { GetVehicle_Full, GetVehicle_FullVariables } from '../../../../../graphql/queries/typings/GetVehicle_Full'
import { EnumUser } from '../../../../../graphql/typings/global_types'
import { Vehicle_Full } from '../../../../helpers/data/models/fleet/Vehicle'
import ErrorDisplay from '../../../../helpers/errors/ErrorDisplay'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper, { useRouteInput } from '../../../../helpers/RoutesHelper'
import VehicleHelper from '../../../../helpers/VehicleHelper'
import { useAuthUser } from '../../../../hooks/useAuth'
import useNavContext from '../../../../hooks/useNavContext'
import useQueryWrapper from '../../../../hooks/useQueryWrapper'
import CustomTabs, { CustomTab } from '../../../CustomTabs'
import LoadingDisplay from '../../../LoadingDisplay'
import VehicleContracts from './contracts/VehicleContracts'
import VehicleDocuments from './VehicleDocuments'
import VehicleGeneral from './VehicleGeneral'

export type VehicleTabName = 'general' | 'contracts' | 'documents'

const Vehicle = () => {
    const auth = useAuthUser()

    const vehicleRoute =
        auth.user.userType === EnumUser.admin
            ? RoutesHelper.views.AEA.customer.vehicles.Vehicle
            : RoutesHelper.views.Customer.vehicles.Vehicle

    // Deserves to update the enterpriseId according to URL params
    const { vehicleId, tab } = useRouteInput(vehicleRoute).params

    const queryResult = useQueryWrapper<GetVehicle_Full, GetVehicle_FullVariables>(queries.GetVehicle_Full, {
        variables: {
            id: vehicleId,
        },
    })

    return queryResult.loading ? (
        <LoadingDisplay />
    ) : !queryResult.data ? (
        <ErrorDisplay message="Erreur lors de la récupération du véhicule" debug={queryResult} />
    ) : (
        <VehicleReady vehicle={queryResult.data.vehicle} tab={tab} />
    )
}

type VehicleReadyProps = {
    vehicle: Vehicle_Full
    tab?: VehicleTabName
}

const VehicleReady = ({ vehicle, tab }: VehicleReadyProps) => {
    const { getVehiclesRoute, getVehicleRoute } = useNavContext()

    const breadcrumbSegments = [
        {
            name: 'Véhicules',
            path: getVehiclesRoute().pathname,
        },
        {
            name: VehicleHelper.getLabel(vehicle),
            icon: IconHelper.elementIcons.vehicleIcon,
            path: getVehicleRoute(vehicle.id).pathname,
        },
    ]

    const tabs: CustomTab<VehicleTabName>[] = [
        {
            id: 'general',
            label: 'Général',
            children: <VehicleGeneral vehicle={vehicle} />,
            to: getVehicleRoute(vehicle.id),
        },
        {
            id: 'contracts',
            label: 'Contrats',
            children: <VehicleContracts vehicleID={vehicle.id} />,
            to: getVehicleRoute(vehicle.id, 'contracts'),
        },
        {
            id: 'documents',
            label: 'Documents',
            children: <VehicleDocuments vehicle={vehicle} />,
            to: getVehicleRoute(vehicle.id, 'documents'),
        },
    ]

    return (
        <CustomTabs<Vehicle_Full, VehicleTabName>
            breadcrumbSegments={breadcrumbSegments}
            activeTab={tab || 'general'}
            tabs={tabs}
        />
    )
}

export default Vehicle
