import { Button, DialogActions, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { EnumDocumentCategory, EnumUser } from '../../../../../../graphql/typings/global_types'
import DocumentHelper from '../../../../../helpers/DocumentHelper'
import UserHelper from '../../../../../helpers/UserHelper'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import useSnackBar from '../../../../../hooks/useSnackBar'
import MultiSelectCheckbox from '../../../../customInputs/MultiSelectCheckbox'
import Spacer from '../../../../Spacer'
import { DocumentAskToCustomerData } from './DocumentAskToCustomer'

type DocumentAskToCustomerInformationProps = {
    data: DocumentAskToCustomerData
    setData: Dispatch<SetStateAction<DocumentAskToCustomerData>>
    closeDialog: () => void
    canGoPrevious: boolean
    goPrevious: () => void
    goNext: () => void
}

const DocumentAskToCustomerInformation = ({
    data,
    setData,
    closeDialog,
    canGoPrevious,
    goPrevious,
    goNext,
}: DocumentAskToCustomerInformationProps) => {
    const { openSnack } = useSnackBar()
    const { customer } = useCustomerLoadedExisting()

    const users = useMemo(() => {
        return customer.users.filter((user) => user.userType === EnumUser.user)
    }, [customer.users])

    const handleTitleChange = (title: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                title,
            }
        })
    }

    const handleCommentChange = (statusComment: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                statusComment,
            }
        })
    }

    const handleCategoryChange = (value: string | null) => {
        const valueAsCategory = value ? (value as EnumDocumentCategory) : null

        setData((oldData) => {
            return {
                ...oldData,
                category: valueAsCategory,
            }
        })
    }

    const handleUsersChange = (values: string[]) => {
        setData((oldData) => {
            return {
                ...oldData,
                askedTo: values,
            }
        })
    }

    const getUserLabel = useCallback(
        (userID: string) => {
            const user = users.find((user) => user.id === userID)

            return user ? UserHelper.getFullName(user.firstname, user.lastname) : 'Inconnu'
        },
        [users]
    )

    const setNext = () => {
        if (!data.category) {
            openSnack({
                type: 'error',
                message: `Vous devez définir une catégorie avant de passer à la suite.`,
            })
            return
        }

        goNext()
    }

    // Because the array is frozen in strict mode, you'll need to copy the array before sorting it:
    const categoriesAsList = useMemo(() => DocumentHelper.getSortedDocumentsCategories(), [])

    return (
        <>
            <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Titre"
                type="text"
                fullWidth
                value={data.title || ''}
                onChange={(event) => handleTitleChange(event.target.value)}
            />
            <Autocomplete
                options={categoriesAsList}
                getOptionLabel={(docCategory) => {
                    return DocumentHelper.getDocumentCategoryLabel(docCategory as EnumDocumentCategory)
                }}
                fullWidth
                onChange={(event, newValue) => handleCategoryChange(newValue ? newValue : null)}
                value={data.category || ''}
                renderInput={(params) => (
                    <TextField {...params} label="Type de document(s) *" margin="dense" type="text" />
                )}
            />
            <TextField
                margin="dense"
                id="statusComment"
                label="Commentaire (facultatif)"
                type="text"
                fullWidth
                value={data.statusComment || ''}
                onChange={(event) => handleCommentChange(event.target.value)}
            />
            <MultiSelectCheckbox
                selectTitle={users.length === 0 ? 'Aucun utilisateur disponible' : 'Demander à'}
                name="askedto"
                value={data.askedTo}
                onChange={(selectedValues) => handleUsersChange(selectedValues)}
                getLabel={getUserLabel}
                fullWidth
                disabled={users.length === 0}
                availableIds={users.map((user) => user.id)}
            />
            <Spacer />
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <Spacer size={1} />
                {canGoPrevious && (
                    <>
                        <Button color="primary" variant="outlined" onClick={goPrevious}>
                            Précédent
                        </Button>
                        <Spacer size={1} />
                    </>
                )}
                <Button color="primary" variant="contained" onClick={setNext}>
                    Suivant
                </Button>
            </DialogActions>
        </>
    )
}

export default DocumentAskToCustomerInformation
