import React from 'react'
import { Breadcrumb } from '../../../../matx'
import IconHelper from '../../../helpers/IconHelper'
import useNavContext from '../../../hooks/useNavContext'
import Panel from '../../Panel'
import UsersList from './UsersList'

const Users = () => {
    const { getCustomerRoute } = useNavContext()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb
                    routeSegments={[{ name: 'Utilisateurs', icon: IconHelper.elementIcons.usersIcon }]}
                    homePath={getCustomerRoute().pathname}
                />
            </div>
            <Panel>
                <UsersList />
            </Panel>
        </div>
    )
}

export default Users
