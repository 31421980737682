import { Avatar, Icon } from '@material-ui/core'
import TouchRipple from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import RoutesHelper from '../../../app/helpers/RoutesHelper'
import { Navigation } from '../../../app/hooks/useNavigation'
import { useCustomer } from '../../../app/hooks/useCustomer'
import useSettings from '../../../app/hooks/useSettings'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    navItem: {
        transition: 'all 150ms ease-in',
        '&:hover': {
            backgroundColor: palette.action.hover,
        },
    },
    navItemActive: {
        backgroundColor: palette.action.selected,
    },
    compactNavItem: {
        overflow: 'hidden',
        justifyContent: 'center !important',
        '& $itemText': {
            display: 'none',
        },
        '& $itemIcon': {
            display: 'none',
        },
    },
    itemIcon: {},
    itemText: {
        fontSize: '0.875rem',
        paddingLeft: '0.8rem',
    },
    label: {
        color: palette.text.secondary,
    },
    bulletIcon: {
        background: palette.text.secondary,
    },
}))

type MatxVerticalNavProps = {
    items: Navigation[]
}

const MatxVerticalNav = ({ items }: MatxVerticalNavProps) => {
    const { settings, updateSettings } = useSettings()
    const { layout1Settings } = settings
    const { mode } = layout1Settings.leftSidebar
    const classes = useStyles()
    const theme = useTheme()

    const isUpLG = useMediaQuery(theme.breakpoints.up('lg'))

    const updateSidebarMode = (sidebarSettings: any) => {
        let activeLayoutSettingsName = settings.activeLayout + 'Settings'
        let activeLayoutSettings = settings[activeLayoutSettingsName as 'layout1Settings']

        updateSettings({
            ...settings,
            [activeLayoutSettingsName]: {
                ...activeLayoutSettings,
                leftSidebar: {
                    ...activeLayoutSettings.leftSidebar,
                    ...sidebarSettings,
                },
            },
        })
    }

    const closeSideBarOnClick = () => {
        if (!isUpLG) updateSidebarMode({ mode: 'close' })
    }

    const renderLevels = (data: Navigation[]) => {
        return data.map((item: Navigation, index: number) => {
            // if (item.type === 'label')
            //     return (
            //         <p
            //             key={index}
            //             className={clsx({
            //                 'px-4 mb-2 mt-6 uppercase text-12 sidenavHoverShow': true,
            //                 [classes.label]: true,
            //                 hidden: mode === 'compact',
            //             })}>
            //             {item.label}
            //         </p>
            //     )
            // if (item.children) {
            //     return (
            //         <MatxVerticalNavExpansionPanel mode={mode} item={item} key={index}>
            //             {renderLevels(item.children)}
            //         </MatxVerticalNavExpansionPanel>
            //     )
            // } else
            // if (item.type === 'extLink') {
            //     return (
            //         <a
            //             key={index}
            //             href={item.path}
            //             className={clsx({
            //                 'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden': true,
            //                 [classes.navItem]: true,
            //                 [classes.compactNavItem]: mode === 'compact',
            //             })}
            //             rel="noopener noreferrer"
            //             target="_blank">
            //             <TouchRipple key={item.name} name="child" className="w-full">
            //                 {(() => {
            //                     if (item.icon) {
            //                         return <Icon className="text-18 align-middle px-4">{item.icon}</Icon>
            //                     } else {
            //                         return <span className="item-icon icon-text">{item.iconText}</span>
            //                     }
            //                 })()}
            //                 <span className={clsx('align-middle sidenavHoverShow', classes.itemText)}>{item.name}</span>
            //                 <div className="mx-auto"></div>
            //                 {item.badge && (
            //                     <div className={`rounded bg-${item.badge.color} px-1 py-1px`}>{item.badge.value}</div>
            //                 )}
            //             </TouchRipple>
            //         </a>
            //     )
            // } else {
            if (item.type === 'link') {
                return (
                    <NavLink
                        key={index}
                        to={item.path}
                        exact={item.exactMatch}
                        activeClassName={classes.navItemActive}
                        className={clsx({
                            'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden':
                                true,
                            [classes.navItem]: true,
                            [classes.compactNavItem]: mode === 'compact',
                        })}
                        onClick={closeSideBarOnClick}>
                        <TouchRipple key={item.name} name="child" className="w-full">
                            <Icon className="text-18 align-middle w-36 px-4">{item.icon}</Icon>

                            <span className={clsx('align-middle text-left sidenavHoverShow', classes.itemText)}>
                                {item.name}
                            </span>
                            <div className="mx-auto"></div>
                            {/* {item.badge && (
                            <div
                                className={clsx(
                                    `rounded bg-${item.badge.color} px-1 py-1px`,
                                    'sidenavHoverShow',
                                    classes.itemIcon
                                )}>
                                {item.badge.value}
                            </div>
                        )} */}
                        </TouchRipple>
                    </NavLink>
                )
            } else if (item.type === 'label') {
                return (
                    <p
                        key={index}
                        className={clsx({
                            'px-4 mb-2 mt-6 uppercase text-12 sidenavHoverShow': true,
                            [classes.label]: true,
                            hidden: mode === 'compact',
                        })}>
                        {item.label}
                    </p>
                )
            } else {
                return <Fragment></Fragment>
            }
        })
    }

    return (
        <div className="navigation">
            <p
                className={clsx({
                    'px-4 mb-2 mt-6 uppercase text-12 sidenavHoverShow': true,
                    [classes.label]: true,
                    hidden: mode === 'compact',
                })}>
                Menu
            </p>
            {renderLevels(items)}
        </div>
    )
}

export const CustomerBrand = () => {
    const { customer, customerLoading } = useCustomer()
    const { settings } = useSettings()
    const { mode } = settings.layout1Settings.leftSidebar
    const classes = useStyles()

    if (customerLoading || !customer) return null

    return (
        <>
            {/* <p
                className={clsx({
                    'px-4 mb-2 mt-6 uppercase text-12 sidenavHoverShow': true,
                    [classes.label]: true,
                    hidden: mode === 'compact',
                })}>
                Client
            </p>
            <Link
                to={RoutesHelper.views.AEA.customer.Home.to({ customerId: customer.id }).pathname}
                className={clsx({
                    'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden': true,
                    [classes.navItem]: true,
                    [classes.compactNavItem]: mode === 'compact',
                })}>
                <TouchRipple name="child" className="w-full">
                    <Icon className="text-18 align-middle w-36 px-4">store</Icon>
                    <span className={clsx('align-middle text-left sidenavHoverShow', classes.itemText)}>
                        {customer.title}
                    </span>
                    <div className="mx-auto"></div>
                </TouchRipple>
            </Link> */}
        </>
    )
}

export default React.memo(MatxVerticalNav)
