import { createContext, ReactElement, useContext } from 'react'
import { GetCustomer_Users_project } from '../../graphql/queries/typings/GetCustomer_Users'
import useGetCustomer from '../helpers/data/getCustomer'

type CustomerContext = {
    customer: GetCustomer_Users_project | null
    customerLoading: boolean
}

const CustomerContext = createContext<CustomerContext>({
    customer: null,
    customerLoading: true,
})

type ProvideCustomerProps = {
    customerId: string
    children: ReactElement
}

export function ProvideCustomer({ children, customerId }: ProvideCustomerProps) {
    const customerData = useProvideCustomer(customerId)

    return <CustomerContext.Provider value={customerData}>{children}</CustomerContext.Provider>
}

export const useCustomer = () => {
    return useContext(CustomerContext)
}

export const useCustomerLoaded = () => {
    const context = useContext(CustomerContext)
    if (context.customerLoading) {
        console.error('Le client est toujours en train de charger !', context)
        throw new Error('Le client est toujours en train de charger !')
    }

    return context
}

export const useCustomerLoadedExisting = () => {
    const context = useContext(CustomerContext)
    if (context.customerLoading) {
        console.error('Le client est toujours en train de charger !', context)
        throw new Error('Le client est toujours en train de charger !')
    }
    if (!context.customer) {
        console.error("Le client n'a pas été trouvé")
        throw new Error("Le client n'a pas été trouvé")
    }

    return {
        customer: context.customer,
        customerLoading: context.customerLoading,
    }
}

const useProvideCustomer = (customerId: string) => {
    const { customer, customerLoading } = useGetCustomer(customerId)

    return { customer, customerLoading }
}

export default useCustomer
