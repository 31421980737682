import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Fragment, useMemo } from 'react'
import { EnumDocumentStatus } from '../../../../graphql/typings/global_types'
import useGetFleetDocuments from '../../../helpers/data/getFleet'
import useGetGoodDocuments from '../../../helpers/data/getGood'
import useGetVehicleDocuments from '../../../helpers/data/getVehicle'
import { Document_User } from '../../../helpers/data/models/Document'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import CustomButton from '../../CustomButton'
import { Dialog } from '../../Dialog'
import LoadingDisplay from '../../LoadingDisplay'
import DocumentsModalList from './DocumentsModalList'

type DialogProps = {
    open: boolean
    closeDialog: () => void
    elementType: 'vehicle' | 'fleet' | 'good' | 'prevention'
    elementId: string
}

const DocumentModal = ({ elementType, ...props }: DialogProps) => {
    const children =
        elementType === 'vehicle' ? (
            <DocumentsModalVehicle {...props} />
        ) : elementType === 'fleet' ? (
            <DocumentsModalFleet {...props} />
        ) : elementType === 'good' ? (
            <DocumentsModalGood {...props} />
        ) : elementType === 'prevention' ? (
            <DocumentsModalPrevention {...props} />
        ) : (
            <ErrorDisplay message="Type inconnu" debug={{ elementType, ...props }} />
        )

    return (
        <Fragment>
            <Dialog open={props.open} onClose={props.closeDialog}>
                {/* <DialogTitle>Liste des documents</DialogTitle> */}
                {children}
            </Dialog>
        </Fragment>
    )
}

type DocumentModalLoadingProps = Omit<DialogProps, 'elementType'>

const DocumentsModalVehicle = ({ elementId, ...props }: DocumentModalLoadingProps) => {
    const { vehicle, vehicleLoading } = useGetVehicleDocuments({ id: elementId })

    if (vehicleLoading) return <LoadingDisplay />
    else if (!vehicle) {
        return (
            <ErrorDisplay
                message="Une erreur est survenue pendant la récupération des documents du véhicule"
                debug={{ vehicle, vehicleLoading }}
            />
        )
    } else return <DocumentsModalReady documents={vehicle.documents} {...props} />
}

const DocumentsModalFleet = ({ elementId, ...props }: DocumentModalLoadingProps) => {
    const { fleet, fleetLoading } = useGetFleetDocuments({ id: elementId })

    if (fleetLoading) return <LoadingDisplay />
    else if (!fleet) {
        return (
            <ErrorDisplay
                message="Une erreur est survenue pendant la récupération des documents de la flotte"
                debug={{ fleet, fleetLoading }}
            />
        )
    } else return <DocumentsModalReady documents={fleet.documents} {...props} />
}

const DocumentsModalGood = ({ elementId, ...props }: DocumentModalLoadingProps) => {
    const { good, goodLoading } = useGetGoodDocuments({ id: elementId })

    if (goodLoading) return <LoadingDisplay />
    else if (!good) {
        return (
            <ErrorDisplay
                message="Une erreur est survenue pendant la récupération des documents du bien"
                debug={{ good, goodLoading }}
            />
        )
    } else return <DocumentsModalReady documents={good.documents} {...props} />
}

const DocumentsModalPrevention = ({ elementId, ...props }: DocumentModalLoadingProps) => {
    const { customer } = useCustomerLoadedExisting()

    const prevention = useMemo(() => {
        return customer.preventions.find((prev) => prev.id === elementId)
    }, [customer.preventions, elementId])

    if (!prevention) {
        return (
            <ErrorDisplay
                message="Une erreur est survenue pendant la récupération des documents de la prévention"
                debug={{ elementId, customer }}
            />
        )
    }

    return <DocumentsModalReady documents={prevention.documents} {...props} />
}

type DialogReadyProps = {
    open: boolean
    closeDialog: () => void
    documents: Document_User[]
}

const DocumentsModalReady = ({ open, closeDialog, documents }: DialogReadyProps) => {
    const confirmedDocuments = useMemo(() => {
        return documents.filter((doc) => doc.status === EnumDocumentStatus.confirmed)
    }, [documents])

    const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        closeDialog()
    }

    return (
        <Fragment>
            <DialogContent className="mb-5" style={{ padding: 0 }}>
                {/* <DocumentsList documents={documents} mode="confirmed" showLink={false} /> */}
                <DocumentsModalList documents={confirmedDocuments} />
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" onClick={(event) => handleClose(event)} color="primary">
                    Fermer
                </CustomButton>
            </DialogActions>
        </Fragment>
    )
}

export default DocumentModal
