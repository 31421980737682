import { Route, Switch } from 'react-router-dom'
import { MatxLayout } from '../../../../matx'
import LoadingDisplay from '../../../components/LoadingDisplay'
import NotFound from '../../../components/NotFound'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import RoutesHelper, { useRouteInput } from '../../../helpers/RoutesHelper'
import useCommonData from '../../../hooks/useCommonData'
import useContracts, { ProvideContracts } from '../../../hooks/useContracts'
import useCustomer, { ProvideCustomer } from '../../../hooks/useCustomer'
import useCustomers from '../../../hooks/useCustomers'
import { ProvideDisasters, useDisasters } from '../../../hooks/useDisasters'
import { NavType } from '../../../hooks/useNavigation'
import useSites, { ProvideSites } from '../../../hooks/useSites'
import AEACustomerAdminContract from './adminContract/AEACustomerAdminContract'
import AEACustomerHome from './AEACustomerHome'
import AEACustomerContract from './contracts/AEACustomerContract'
import AEACustomerContracts from './contracts/AEACustomerContracts'
import AEACustomerDisaster from './disasters/AEACustomerDisaster'
import AEACustomerDisasters from './disasters/AEACustomerDisasters'
import AEACustomerDocument from './documents/AEACustomerDocuments'
import AEACustomerEnterprise from './enterprises/AEACustomerEnterprise'
import AEACustomerEnterprises from './enterprises/AEACustomerEnterprises'
import AEACustomerOrgChart from './orgChart/AEACustomerOrgChart'
import AEACustomerPlace from './places/AEACustomerPlace'
import AEACustomerPlaces from './places/AEACustomerPlaces'
import AEACustomerPrevention from './preventions/AEACustomerPrevention'
import AEACustomerPreventions from './preventions/AEACustomerPreventions'
import AEACustomerRoadmap from './roadmap/AEACustomerRoadmap'
import AEACustomerSites from './sites/AEACustomerSites'
import AEACustomerUsers from './users/AEACustomerUsers'
import AEACustomerVehicle from './vehicles/AEACustomerVehicle'
import AEACustomerVehicles from './vehicles/AEACustomerVehicles'

const AEACustomerZone = () => {
    // Deserves to update the customerId according to URL params
    const { customerId } = useRouteInput(RoutesHelper.views.AEA.customer.Home).params

    return (
        <ProvideCustomer customerId={customerId}>
            <ProvideSites customerId={customerId}>
                <ProvideDisasters customerId={customerId}>
                    <ProvideContracts customerId={customerId}>
                        <AEACustomerZoneCustomerLoader />
                    </ProvideContracts>
                </ProvideDisasters>
            </ProvideSites>
        </ProvideCustomer>
    )
}

const AEACustomerZoneCustomerLoader = () => {
    const { customers, customersLoading } = useCustomers()
    const { customer, customerLoading } = useCustomer()
    const { disastersLoading } = useDisasters()
    const { commonDataLoading } = useCommonData()
    const { sites, sitesLoading } = useSites()
    const { contractsLoading } = useContracts()

    const children =
        customerLoading ||
        disastersLoading ||
        customersLoading ||
        commonDataLoading ||
        sitesLoading ||
        contractsLoading ? (
            <LoadingDisplay />
        ) : !customer ? (
            <ErrorDisplay
                message={'Undefined customer after loading'}
                debug={{
                    customer,
                    customerLoading,
                    commonDataLoading,
                    customers,
                    customersLoading,
                    sitesLoading,
                    sites,
                    contractsLoading,
                }}
            />
        ) : (
            <AEACustomerZoneReady />
        )

    return (
        <MatxLayout zone={NavType.aeaCustomer} customers={customers || []} customer={customer}>
            {children}
        </MatxLayout>
    )
}

const AEACustomerZoneReady = () => {
    return (
        <Switch>
            <Route exact path={RoutesHelper.views.AEA.customer.Home.path} component={AEACustomerHome} />
            <Route
                exact
                path={RoutesHelper.views.AEA.customer.AdminContract.Home.path}
                component={AEACustomerAdminContract}
            />
            <Route
                exact
                path={RoutesHelper.views.AEA.customer.enterprises.Home.path}
                component={AEACustomerEnterprises}
            />

            <Route exact path={RoutesHelper.views.AEA.customer.orgChart.Home.path} component={AEACustomerOrgChart} />
            <Route exact path={RoutesHelper.views.AEA.customer.contracts.Home.path} component={AEACustomerContracts} />
            <Route
                exact
                path={RoutesHelper.views.AEA.customer.contracts.Contract.path}
                component={AEACustomerContract}
            />
            <Route exact path={RoutesHelper.views.AEA.customer.vehicles.Home.path} component={AEACustomerVehicles} />
            <Route exact path={RoutesHelper.views.AEA.customer.vehicles.Vehicle.path} component={AEACustomerVehicle} />
            <Route exact path={RoutesHelper.views.AEA.customer.places.Home.path} component={AEACustomerPlaces} />
            <Route exact path={RoutesHelper.views.AEA.customer.places.Place.path} component={AEACustomerPlace} />
            <Route exact path={RoutesHelper.views.AEA.customer.sites.Home.path} component={AEACustomerSites} />
            <Route exact path={RoutesHelper.views.AEA.customer.disasters.Home.path} component={AEACustomerDisasters} />
            <Route
                exact
                path={RoutesHelper.views.AEA.customer.disasters.Disaster.path}
                component={AEACustomerDisaster}
            />
            <Route exact path={RoutesHelper.views.AEA.customer.Documents.path} component={AEACustomerDocument} />
            <Route
                exact
                path={RoutesHelper.views.AEA.customer.preventions.Home.path}
                component={AEACustomerPreventions}
            />
            <Route
                exact
                path={RoutesHelper.views.AEA.customer.preventions.Prevention.path}
                component={AEACustomerPrevention}
            />
            <Route exact path={RoutesHelper.views.AEA.customer.Roadmap.path} component={AEACustomerRoadmap} />
            <Route exact path={RoutesHelper.views.AEA.customer.Users.path} component={AEACustomerUsers} />
            <Route
                path={RoutesHelper.views.AEA.customer.enterprises.Enterprise.path}
                component={AEACustomerEnterprise}
            />
            <Route component={NotFound} />
        </Switch>
    )
}

export default AEACustomerZone
