import { Button, CircularProgress } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useHistory } from 'react-router-dom'
import { EnumUser } from '../../graphql/typings/global_types'
import RoutesHelper from '../helpers/RoutesHelper'
import { useAuthUnlogged } from '../hooks/useAuth'
import ExternalLinkLayout from './external/ExternalLinkLayout'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const Login = () => {
    const auth = useAuthUnlogged()
    const history = useHistory()
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [remember, setRemember] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const setValueFunction = event.target.name === 'email' ? setEmail : setPassword

        setValueFunction(event.target.value)
    }

    const handleCheckboxChange = (checkboxValue: boolean) => {
        setRemember(checkboxValue)
    }

    const handleFormSubmit = async () => {
        setLoading(true)
        try {
            const user = await login()
            setLoading(false)

            if (user.userType === EnumUser.admin) history.push(RoutesHelper.views.AEA.Home.to())
            else history.push(RoutesHelper.views.Customer.Home.to())
        } catch (e) {
            console.log(e)
            setMessage(e.message)
            setLoading(false)
        }
    }

    const login = async () => {
        setLoading(true)
        const user = await auth.login(email, password)
        setLoading(false)

        if (!user) {
            throw new Error("Erreur d'authentification. Recommencez")
        }

        return user
    }

    return (
        <ExternalLinkLayout title="Connexion">
            <ValidatorForm onSubmit={handleFormSubmit}>
                <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="small"
                    label="Email"
                    onChange={handleInputChange}
                    type="email"
                    name="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['Ce champ est requis', "L'email est invalide"]}
                />
                <TextValidator
                    className="mb-6 w-full"
                    label="Password"
                    variant="outlined"
                    size="small"
                    onChange={handleInputChange}
                    name="password"
                    type="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['Ce champ est requis']}
                />
                {/* <FormControlLabel
                                    className="mb-3 min-w-288"
                                    name="remember"
                                    onChange={(event, checked) => {
                                        handleCheckboxChange(checked)
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            onChange={({ target: { checked } }) => {
                                                handleCheckboxChange(checked)
                                            }}
                                            checked={remember}
                                        />
                                    }
                                    label="Rester connecté"
                                /> */}

                {message && <p className="text-error">{message}</p>}

                <div className="flex flex-wrap items-center mb-4">
                    <div className="relative">
                        <Button variant="contained" color="primary" disabled={loading} type="submit">
                            Connexion
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>
                <Button className="text-primary" onClick={() => history.push(RoutesHelper.views.ForgotPassword.to())}>
                    Mot de passe oublié ?
                </Button>
            </ValidatorForm>
        </ExternalLinkLayout>
    )
}

export default Login
