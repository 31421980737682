import { Button } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { PreventionCategory_Minimal } from '../../../../../graphql/fragments/Prevention/PreventionCategory/typings/PreventionCategory_Minimal'
import {
    GetCustomer_Users_project_places,
    GetCustomer_Users_project_preventions,
} from '../../../../../graphql/queries/typings/GetCustomer_Users'
import { EnumUser } from '../../../../../graphql/typings/global_types'
import Breadcrumb from '../../../../../matx/components/Breadcrumb'
import DateHelper from '../../../../helpers/DateHelper'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper, { useRouteInput } from '../../../../helpers/RoutesHelper'
import { useAuthUser } from '../../../../hooks/useAuth'
import { useCommonDataLoadedExisting } from '../../../../hooks/useCommonData'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import useNavContext from '../../../../hooks/useNavContext'
import { useSnackBar } from '../../../../hooks/useSnackBar'
import DataTable from '../../../DataTable'
import DocumentsModal from '../../../documentsList/DocumentModal/DocumentsModal'
import ErrorView from '../../../ErrorView'
import Panel from '../../../Panel'
import Spacer from '../../../Spacer'
import PreventionDialog from '../../places/place/preventions/PreventionDialog'
import PreventionActionMenu from './PreventionActionMenu'

export type PreventionForPrevention = GetCustomer_Users_project_preventions
export type PlaceForPrevention = GetCustomer_Users_project_places

const Prevention = () => {
    const auth = useAuthUser()

    const preventionRoute =
        auth.user.userType === EnumUser.admin
            ? RoutesHelper.views.AEA.customer.preventions.Prevention
            : RoutesHelper.views.Customer.preventions.Prevention

    const { placeId, categoryId } = useRouteInput(preventionRoute).params
    const { customer } = useCustomerLoadedExisting()
    const commonData = useCommonDataLoadedExisting()

    const preventions: PreventionForPrevention[] = useMemo(() => {
        return customer.preventions.filter((prev) => {
            return prev.place.id === placeId && prev.preventionCategory.id === categoryId
        })
    }, [customer.preventions, placeId, categoryId])

    const category = useMemo(() => {
        return commonData.preventionCategories.find((prevCat) => prevCat.id === categoryId)
    }, [commonData.preventionCategories, categoryId])

    const place = useMemo(() => {
        return customer.places.find((place) => place.id === placeId)
    }, [commonData.preventionCategories, categoryId])

    return category && place ? (
        <PreventionReady preventions={preventions} preventionCategory={category} place={place} />
    ) : (
        <div className="m-sm-30">
            <ErrorView
                message="Une erreur est survenue pendant la récupération des préventions"
                debug={{
                    customer,
                    placeId,
                    categoryId,
                }}
            />
        </div>
    )
}

type PreventionReadyProps = {
    preventions: PreventionForPrevention[]
    preventionCategory: PreventionCategory_Minimal
    place: PlaceForPrevention
}

const PreventionReady = ({ preventions, preventionCategory, place }: PreventionReadyProps) => {
    const { getCustomerRoute, getPlaceRoute, getPreventionRoute } = useNavContext()

    const fullSegments = [
        {
            name: place.title,
            icon: IconHelper.elementIcons.placeIcon,
            path: getPlaceRoute(place.id, 'prevention').pathname,
        },
        {
            name: preventionCategory.title + ' (' + place.title + ') ',
            icon: IconHelper.elementIcons.preventionIcon,
            path: getPreventionRoute(place.id, preventionCategory.id).pathname,
        },
    ]

    const customerRoute = getCustomerRoute()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb routeSegments={fullSegments} homePath={customerRoute.pathname} />
            </div>
            <PreventionPeriods preventions={preventions} preventionCategory={preventionCategory} place={place} />
        </div>
    )
}

type PreventionPeriodsProps = {
    preventions: PreventionForPrevention[]
    preventionCategory: PreventionCategory_Minimal
    place: PlaceForPrevention
}

type FormattedPreventions = {
    period: string
    comment: string
    prevention: PreventionForPrevention
}

type DialogState =
    | {
          open: true
          prevention: PreventionForPrevention
      }
    | { open: false }

const PreventionPeriods = ({ preventions, preventionCategory, place }: PreventionPeriodsProps) => {
    const { fromServertoString } = DateHelper()
    const { getPlaceRoute } = useNavContext()
    const { openSnack } = useSnackBar()

    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [openDocsDialog, setOpenDocsDialog] = useState<DialogState>({ open: false })

    const formattedPreventions: FormattedPreventions[] = useMemo(() => {
        return preventions
            .sort((prevA, prevB) => {
                return new Date(prevB.endAt).getTime() - new Date(prevA.endAt).getTime()
            })
            .map((prev, index) => {
                return {
                    period: fromServertoString(prev.startAt) + ' - ' + fromServertoString(prev.endAt),
                    comment: prev.description || '-',
                    prevention: prev,
                }
            })
    }, [preventions])

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'period',
                label: 'Période',
            },
            {
                name: 'comment',
                label: 'Commentaire',
            },
        ],
        [formattedPreventions]
    )

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        const formattedPrev = formattedPreventions[dataIndex]

        return <PreventionActionMenu prevention={formattedPrev.prevention} place={place} />
    }

    const placeRoute = useMemo(() => {
        return getPlaceRoute(place.id, 'prevention')
    }, [place.id])

    /************************
     * PREV PERIOD CREATION
     ************************/

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Nouvelle période de prévention`,
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: 'Prévention créée avec succès !',
        })
    }

    /***************************
     * END PREV PERIOD CREATION
     ***************************/

    return (
        <>
            <Button
                component={Link}
                to={placeRoute.pathname}
                size="small"
                variant="outlined"
                startIcon={<ChevronLeftIcon />}>
                Revenir au local
            </Button>
            <Spacer size={2} />
            <Panel>
                <DataTable<FormattedPreventions>
                    title={`Historique de cette prévention`}
                    icon={IconHelper.elementIcons.preventionIcon}
                    data={formattedPreventions}
                    columns={columns}
                    showSearch={true}
                    actionsColumn={actionsColumn}
                    addFeature={addFeatureData}
                    options={{
                        filter: true,
                        filterType: 'multiselect',
                    }}
                />
            </Panel>
            <PreventionDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                place={place}
                preventionCategory={preventionCategory}
            />
            {openDocsDialog.open && (
                <DocumentsModal
                    open={openDocsDialog.open}
                    closeDialog={() => setOpenDocsDialog({ open: false })}
                    elementType={'prevention'}
                    elementId={openDocsDialog.prevention.id}
                />
            )}
        </>
    )
}

export default Prevention
