import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    ContractGeneric_contract,
    ContractGeneric_contract_guarantees,
    ContractGeneric_contract_guarantees_guaranteeCategory,
} from '../../../../graphql/queries/typings/ContractGeneric'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { InsuranceGeneric_insurance } from '../../../../graphql/queries/typings/InsuranceGeneric'
import { GuaranteeTargetInput } from '../../../../graphql/typings/global_types'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import IconHelper from '../../../helpers/IconHelper'
import { useUnlinkFromGuarantee } from '../../../hooks/useMutations'
import useNavContext from '../../../hooks/useNavContext'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import LoadingDisplay from '../../LoadingDisplay'
import ObjectContractDialog from './ObjectContractDialog'

type ContractForObject = Pick<ContractGeneric_contract, 'id' | 'title' | 'reference'> & {
    insurance: Pick<InsuranceGeneric_insurance, 'id' | 'title'>
    enterprise: Pick<EnterpriseGeneric_enterprise, 'id' | 'title'>
}

export type ObjectGuarantees = Pick<ContractGeneric_contract_guarantees, 'id'> & {
    guaranteeCategory: Pick<ContractGeneric_contract_guarantees_guaranteeCategory, 'id' | 'title'>
    contract: Pick<ContractGeneric_contract, 'id'>
}

type FormattedContract = {
    id: string
    title: string
    reference: string
    insurance: string
    enterprise: string
    guarantees: string
}

export type ContractWrapper = {
    contract: ContractForObject
    guarantees: ObjectGuarantees[]
}

type ObjectContractsProps = {
    title: string
    onSuccessText: string
    confirmText: string
    contractsWrappers: ContractWrapper[]
    guaranteeTarget: GuaranteeTargetInput
}

type DialogData = {
    open: boolean
    contractID?: string
}

const ObjectContracts = ({
    title,
    onSuccessText,
    confirmText,
    contractsWrappers,
    guaranteeTarget,
}: ObjectContractsProps) => {
    const { openSnack } = useSnackBar()
    const history = useHistory()
    const { getContractRoute } = useNavContext()
    const { errorAlert } = useErrorService()

    const doUnlinkGuarantees = useUnlinkFromGuarantee()

    const [openDialog, setOpenDialog] = useState<DialogData>({ open: false })
    const [unlinkDialogOpen, setUnlinkDialogOpen] = useState<string | null>(null)
    const [unlinkLoading, setUnlinkLoading] = useState<string | null>(null)

    const formattedContracts: FormattedContract[] = useMemo(() => {
        return contractsWrappers.map((wrapper) => {
            const contract = wrapper.contract

            return {
                id: contract.id,
                title: contract.title,
                reference: contract.reference || '-',
                insurance: contract.insurance.title,
                enterprise: contract.enterprise.title,
                guarantees: wrapper.guarantees.map((guarantee) => guarantee.guaranteeCategory.title).join(', '),
            }
        })
    }, [contractsWrappers])

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'title',
                label: 'Libellé',
            },
            {
                name: 'reference',
                label: 'Référence',
            },
            {
                name: 'insurance',
                label: 'Assurance',
            },
            {
                name: 'enterprise',
                label: 'Enterprise',
            },
            {
                name: 'guarantees',
                label: 'Garanties',
            },
        ],
        [formattedContracts]
    )

    const onContractClick = (contractData: FormattedContract) => {
        history.push(getContractRoute(contractData.id))
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenDialog({ open: true })
        },
        tooltip: `Ajouter aux garanties d'un contrat`,
    }

    const onClose = () => {
        setOpenDialog({ open: false })
    }

    const onDone = () => {
        openSnack({
            message: onSuccessText,
            type: 'success',
        })
        setOpenDialog({ open: false })
    }

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, contract: FormattedContract) => {
        event.stopPropagation()
        setOpenDialog({ open: true, contractID: contract.id })
    }

    const onUnlinkClick = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        contract: FormattedContract
    ) => {
        event.stopPropagation()
        setUnlinkDialogOpen(contract.id)
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        const contract = formattedContracts[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le contrat">
                    <IconButton onClick={(event) => onEditClick(event, contract)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                {unlinkLoading === contract.id ? (
                    <LoadingDisplay size={2} />
                ) : (
                    <Tooltip title="Supprimer le contrat">
                        <IconButton onClick={(event) => onUnlinkClick(event, contract)}>
                            <Icon>link_off</Icon>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        )
    }

    const objectGuarantees: ObjectGuarantees[] = useMemo(() => {
        if (!openDialog.contractID) return []

        const wrapper = contractsWrappers.find((wrapper) => wrapper.contract.id === openDialog.contractID)

        if (!wrapper) {
            errorAlert('Impossible de trouver le contrat ciblé.', openDialog.contractID)
            return []
        }

        return wrapper.guarantees
    }, [contractsWrappers, openDialog])

    const onUnlinkConfirmed = async () => {
        const contractID = unlinkDialogOpen

        if (contractID === null) return

        const wrapper = contractsWrappers.find((wrapper) => wrapper.contract.id === contractID)

        if (!wrapper) {
            errorAlert('Impossible de trouver le contrat ciblé.', openDialog.contractID)
            return
        }

        setUnlinkLoading(contractID)

        const contractGuarantees = wrapper.guarantees
            .filter((guarantee) => guarantee.contract.id === contractID)
            .map((guarantee) => guarantee.id)

        try {
            await doUnlinkGuarantees.run({
                contractID: contractID,
                guaranteeIds: contractGuarantees,
                guaranteeTarget,
            })
        } catch (error) {
            errorAlert(error)
        } finally {
            setUnlinkLoading(null)
        }

        setUnlinkDialogOpen(null)
    }

    return (
        <>
            <DataTable<FormattedContract>
                title={title}
                icon={IconHelper.elementIcons.contractIcon}
                data={formattedContracts}
                columns={columns}
                showSearch={true}
                rowClick={onContractClick}
                addFeature={addFeatureData}
                actionsColumn={actionsColumn}
            />
            <ObjectContractDialog
                open={openDialog.open}
                contractID={openDialog.contractID}
                closeDialog={onClose}
                guaranteeTarget={guaranteeTarget}
                objectGuarantees={objectGuarantees}
                onDone={onDone}
            />
            <ConfirmDialog
                open={unlinkDialogOpen !== null}
                title="Retirer les garanties"
                text={confirmText}
                onConfirmed={onUnlinkConfirmed}
                onCancelled={() => setUnlinkDialogOpen(null)}
                loading={unlinkLoading !== null}
            />
        </>
    )
}

export default ObjectContracts
