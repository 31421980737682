import { useMemo } from 'react'
import useGetGoods from '../../../../../../helpers/data/getGoods'
import ErrorDisplay from '../../../../../../helpers/errors/ErrorDisplay'
import { useCustomerLoadedExisting } from '../../../../../../hooks/useCustomer'
import LoadingDisplay from '../../../../../LoadingDisplay'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'
import DocumentManagerAssociationElementTextField from './DocumentManagerAssociationElementTextFields'

type DMA_GoodsProps = DMAButtonsProps & {
    data: DMA_Data
    setData: DMA_SetData
}

const DMA_Goods = ({ data, setData, ...buttonsProps }: DMA_GoodsProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { goods, goodsLoading } = useGetGoods({ customerId: customer.id })

    const content = useMemo(() => {
        if (goodsLoading) {
            return <LoadingDisplay size={1} />
        } else if (!goods) {
            return <ErrorDisplay message="Erreur lors du chargement des biens" debug={goods} />
        } else {
            const elements = goods.map((good) => {
                return { label: good.title, id: good.id }
            })

            return (
                <DocumentManagerAssociationElementTextField
                    data={data}
                    setData={setData}
                    elementLabel={'Bien'}
                    elements={elements}
                />
            )
        }
    }, [goods, goodsLoading, data, setData])

    return (
        <>
            {content}
            <DMAButtons {...buttonsProps} />
        </>
    )
}

export default DMA_Goods
