import { gql } from '@apollo/client/core'
import Document_User from '../fragments/Document/Document_User'
import RoadmapItem_Minimal from '../fragments/RoadmapItem/RoadmapItem_Minimal'

export default gql`
    ${Document_User}
    ${RoadmapItem_Minimal}
    query GetCustomer_Users($id: ID!) {
        project(id: $id) {
            id
            title
            enterprises {
                id
                title
                address
                notificationUsers {
                    id
                }
                agreements {
                    id
                    title
                    startAt
                    endAt
                    description
                    version
                    amount
                    category
                    urlToFile
                }
            }
            supervisor {
                id
                firstname
                lastname
                email
                userType
                phone
                avatar: urlToUserAvatar
            }
            places: locals {
                id
                title
                address
                wallsOwner
                enterprise {
                    id
                    address
                    title
                }
                site {
                    id
                    title
                }
            }
            preventions {
                id
                description
                documents {
                    ...Document_User
                }
                startAt
                endAt
                preventionCategory {
                    id
                    description
                    title
                }
                place: local {
                    id
                    title
                    address
                    wallsOwner
                    site {
                        id
                        title
                    }
                }
            }
            roadmapItems {
                ...RoadmapItem_Minimal
            }
            users {
                id
                firstname
                lastname
                email
                userType
                phone
                restrictedToEnterprises {
                    id
                    title
                }
            }
        }
    }
`
