import queries from '../../../graphql/queries'
import {
    GetDisasters_Minimal,
    GetDisasters_MinimalVariables,
    GetDisasters_Minimal_disasters
} from '../../../graphql/queries/typings/GetDisasters_Minimal'
import { EnumDisasterThrough } from '../../../graphql/typings/global_types'
import useQueryWrapper from '../../hooks/useQueryWrapper'

type DisastersState = {
    disastersLoading: boolean
    disasters: GetDisasters_Minimal_disasters[] | null
}

type GetDisasterProps = {
    customerId?: string
    enterpriseId?: string
    // categories?: EnumGuaranteeCategory[]
}

const useGetDisasters = ({ customerId, enterpriseId }: GetDisasterProps): DisastersState => {
    const variables: GetDisasters_MinimalVariables = enterpriseId
        ? {
              customerId,
              enterpriseId,
              through: EnumDisasterThrough.through_damage,
          }
        : {
              customerId,
              through: EnumDisasterThrough.through_contract,
          }

    const queryResult = useQueryWrapper<GetDisasters_Minimal, GetDisasters_MinimalVariables>(
        queries.GetDisasters_Minimal,
        { variables }
    )

    if (queryResult.error) {
        throw 'Erreur lors de la récupération des sinistres'
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const disasters = responseData && responseData.disasters ? responseData.disasters : null

        return {
            disasters,
            disastersLoading: false,
        }
    } else {
        return {
            disasters: null,
            disastersLoading: true,
        }
    }
}

export default useGetDisasters
