import queries from '../../../graphql/queries'
import {
    GetCustomers_Minimal,
    GetCustomers_Minimal_customers
} from '../../../graphql/queries/typings/GetCustomers_Minimal'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type CustomersData = {
    customers: GetCustomers_Minimal_customers[] | null
    customersLoading: boolean
}

const useGetCustomers = (): CustomersData => {
    const { errorAlert } = useErrorService()

    const customersQueryResult = useQueryWrapper<GetCustomers_Minimal>(queries.GetCustomers_Minimal)

    if (customersQueryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', customersQueryResult)
    }

    const queryLoading = customersQueryResult.loading
    const responseData = customersQueryResult.data

    if (!queryLoading) {
        const customers = responseData && responseData.customers ? responseData.customers : null

        return {
            customers,
            customersLoading: false,
        }
    } else {
        return {
            customers: null,
            customersLoading: true,
        }
    }
}

export default useGetCustomers
