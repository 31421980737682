import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GetCustomer_Users_project_enterprises } from '../../../../graphql/queries/typings/GetCustomer_Users'
import { EnumUser } from '../../../../graphql/typings/global_types'
import { Enterprise_Minimal, Enterprise_Users } from '../../../helpers/data/models/Enterprise'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import IconHelper from '../../../helpers/IconHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteEnterprise } from '../../../hooks/useMutations'
import useNavContext from '../../../hooks/useNavContext'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import EnterpriseDialog from './enterprise/general/EnterpriseDialog'

type DialogState =
    | {
          open: true
          enterprise: GetCustomer_Users_project_enterprises
      }
    | { open: false }

const EnterprisesList = () => {
    const { customer } = useCustomerLoadedExisting()
    const enterprises = customer.enterprises
    const history = useHistory()
    const { openSnack } = useSnackBar()
    const { getEnterpriseRoute } = useNavContext()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const deleteEnterpriseMutation = useDeleteEnterprise()
    const { errorAlert } = useErrorService()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const onEnterpriseClick = (enterprise: Enterprise_Minimal) => {
        history.push(getEnterpriseRoute(enterprise.id))
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
    ]

    const onEnterpriseCreated = () => {
        openSnack({
            type: 'success',
            message: `Entreprise ajoutée avec succès !`,
        })
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'une entreprise`,
    }

    const onEdited = () => {
        openSnack({
            message: 'Entreprise modifiée avec succès !',
            type: 'success',
        })
    }

    const onEditClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        enterprise: GetCustomer_Users_project_enterprises
    ) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, enterprise })
    }

    const onDeleteClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        enterprise: GetCustomer_Users_project_enterprises
    ) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, enterprise })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteEnterpriseMutation.run({
                customerId: customer.id,
                id: openDeleteDialog.enterprise.id,
            })

            if (response.errors.length > 0) {
                throw "Une erreur est survenue pendant la suppression de l'entreprise."
            } else {
                openSnack({
                    message: 'Entreprise supprimée avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const enterprise = enterprises[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier l'entreprise">
                    <IconButton onClick={(event) => onEditClick(event, enterprise)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer l'enterprise">
                    <IconButton onClick={(event) => onDeleteClick(event, enterprise)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <>
            <DataTable
                title={'Entreprises'}
                icon={IconHelper.elementIcons.enterpriseIcon}
                data={enterprises}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                rowClick={onEnterpriseClick}
                actionsColumn={actionsColumn}
            />
            <EnterpriseDialog
                onDone={onEnterpriseCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
            />
            {openEditDialog.open && (
                <EnterpriseDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    enterprise={openEditDialog.enterprise}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'une entreprise"
                    text={`Voulez-vous vraiment supprimer l'entreprise "${openDeleteDialog.enterprise.title}" ? Tous les éléments créés dans l'entreprise seront supprimés.`}
                    loading={deleteLoading}
                />
            )}
        </>
    )
}

export default EnterprisesList
