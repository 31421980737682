import { useMutation } from '@apollo/client/react/hooks/useMutation'
import mutations from '../../graphql/mutations'
import { CreateAgreement, CreateAgreementVariables } from '../../graphql/mutations/typings/CreateAgreement'
import { CreateBroker, CreateBrokerVariables } from '../../graphql/mutations/typings/CreateBroker'
import { CreateContract, CreateContractVariables } from '../../graphql/mutations/typings/CreateContract'
import {
    CreateContractGuarantee,
    CreateContractGuaranteeVariables,
} from '../../graphql/mutations/typings/CreateContractGuarantee'
import { CreateCustomer, CreateCustomerVariables } from '../../graphql/mutations/typings/CreateCustomer'
import { CreateDisaster, CreateDisasterVariables } from '../../graphql/mutations/typings/CreateDisaster'
import {
    CreateDisasterCategory,
    CreateDisasterCategoryVariables,
} from '../../graphql/mutations/typings/CreateDisasterCategory'
import {
    CreateDisasterDamage,
    CreateDisasterDamageVariables,
} from '../../graphql/mutations/typings/CreateDisasterDamage'
import { CreateDisasterEvent, CreateDisasterEventVariables } from '../../graphql/mutations/typings/CreateDisasterEvent'
import {
    CreateDisasterPayment,
    CreateDisasterPaymentVariables,
} from '../../graphql/mutations/typings/CreateDisasterPayment'
import { CreateDocument, CreateDocumentVariables } from '../../graphql/mutations/typings/CreateDocument'
import { CreateDocuments, CreateDocumentsVariables } from '../../graphql/mutations/typings/CreateDocuments'
import { CreateEnterprise, CreateEnterpriseVariables } from '../../graphql/mutations/typings/CreateEnterprise'
import { CreateFleet, CreateFleetVariables } from '../../graphql/mutations/typings/CreateFleet'
import { CreateGood, CreateGoodVariables } from '../../graphql/mutations/typings/CreateGood'
import {
    CreateGuaranteeCategory,
    CreateGuaranteeCategoryVariables,
} from '../../graphql/mutations/typings/CreateGuaranteeCategory'
import { CreateInsurance, CreateInsuranceVariables } from '../../graphql/mutations/typings/CreateInsurance'
import { CreateInsurer, CreateInsurerVariables } from '../../graphql/mutations/typings/CreateInsurer'
import { CreatePlace, CreatePlaceVariables } from '../../graphql/mutations/typings/CreatePlace'
import { CreatePrevention, CreatePreventionVariables } from '../../graphql/mutations/typings/CreatePrevention'
import {
    CreatePreventionCategory,
    CreatePreventionCategoryVariables,
} from '../../graphql/mutations/typings/CreatePreventionCategory'
import { CreateRoadmapItem, CreateRoadmapItemVariables } from '../../graphql/mutations/typings/CreateRoadmapItem'
import { CreateSite, CreateSiteVariables } from '../../graphql/mutations/typings/CreateSite'
import { CreateUser, CreateUserVariables } from '../../graphql/mutations/typings/CreateUser'
import { CreateVehicle, CreateVehicleVariables } from '../../graphql/mutations/typings/CreateVehicle'
import { DeclareDisaster, DeclareDisasterVariables } from '../../graphql/mutations/typings/DeclareDisaster'
import { DeleteAgreement, DeleteAgreementVariables } from '../../graphql/mutations/typings/DeleteAgreement'
import { DeleteBroker, DeleteBrokerVariables } from '../../graphql/mutations/typings/DeleteBroker'
import { DeleteContract, DeleteContractVariables } from '../../graphql/mutations/typings/DeleteContract'
import {
    DeleteContractGuarantee,
    DeleteContractGuaranteeVariables,
} from '../../graphql/mutations/typings/DeleteContractGuarantee'
import { DeleteCustomer, DeleteCustomerVariables } from '../../graphql/mutations/typings/DeleteCustomer'
import { DeleteDisaster, DeleteDisasterVariables } from '../../graphql/mutations/typings/DeleteDisaster'
import {
    DeleteDisasterCategory,
    DeleteDisasterCategoryVariables,
} from '../../graphql/mutations/typings/DeleteDisasterCategory'
import {
    DeleteDisasterDamage,
    DeleteDisasterDamageVariables,
} from '../../graphql/mutations/typings/DeleteDisasterDamage'
import { DeleteDisasterEvent, DeleteDisasterEventVariables } from '../../graphql/mutations/typings/DeleteDisasterEvent'
import {
    DeleteDisasterPayment,
    DeleteDisasterPaymentVariables,
} from '../../graphql/mutations/typings/DeleteDisasterPayment'
import { DeleteDocument, DeleteDocumentVariables } from '../../graphql/mutations/typings/DeleteDocument'
import { DeleteEnterprise, DeleteEnterpriseVariables } from '../../graphql/mutations/typings/DeleteEnterprise'
import { DeleteFleet, DeleteFleetVariables } from '../../graphql/mutations/typings/DeleteFleet'
import { DeleteGood, DeleteGoodVariables } from '../../graphql/mutations/typings/DeleteGood'
import {
    DeleteGuaranteeCategory,
    DeleteGuaranteeCategoryVariables,
} from '../../graphql/mutations/typings/DeleteGuaranteeCategory'
import { DeleteInsurance, DeleteInsuranceVariables } from '../../graphql/mutations/typings/DeleteInsurance'
import { DeleteInsurer, DeleteInsurerVariables } from '../../graphql/mutations/typings/DeleteInsurer'
import { DeletePlace, DeletePlaceVariables } from '../../graphql/mutations/typings/DeletePlace'
import { DeletePrevention, DeletePreventionVariables } from '../../graphql/mutations/typings/DeletePrevention'
import {
    DeletePreventionCategory,
    DeletePreventionCategoryVariables,
} from '../../graphql/mutations/typings/DeletePreventionCategory'
import { DeleteRoadmapItem, DeleteRoadmapItemVariables } from '../../graphql/mutations/typings/DeleteRoadmapItem'
import { DeleteSite, DeleteSiteVariables } from '../../graphql/mutations/typings/DeleteSite'
import { DeleteUser, DeleteUserVariables } from '../../graphql/mutations/typings/DeleteUser'
import { DeleteVehicle, DeleteVehicleVariables } from '../../graphql/mutations/typings/DeleteVehicle'
import { DuplicateContract, DuplicateContractVariables } from '../../graphql/mutations/typings/DuplicateContract'
import { ForgotPassword, ForgotPasswordVariables } from '../../graphql/mutations/typings/ForgotPassword'
import {
    LinkGuaranteeTargetToGuarantees,
    LinkGuaranteeTargetToGuaranteesVariables,
} from '../../graphql/mutations/typings/LinkGuaranteeTargetToGuarantees'
import {
    UnlinkGuaranteeTargetFromGuarantees,
    UnlinkGuaranteeTargetFromGuaranteesVariables,
} from '../../graphql/mutations/typings/UnlinkGuaranteeTargetFromGuarantees'
import { UpdateAgreement, UpdateAgreementVariables } from '../../graphql/mutations/typings/UpdateAgreement'
import { UpdateBroker, UpdateBrokerVariables } from '../../graphql/mutations/typings/UpdateBroker'
import { UpdateContract, UpdateContractVariables } from '../../graphql/mutations/typings/UpdateContract'
import {
    UpdateContractGuarantee,
    UpdateContractGuaranteeVariables,
} from '../../graphql/mutations/typings/UpdateContractGuarantee'
import { UpdateCustomer, UpdateCustomerVariables } from '../../graphql/mutations/typings/UpdateCustomer'
import { UpdateDisaster, UpdateDisasterVariables } from '../../graphql/mutations/typings/UpdateDisaster'
import {
    UpdateDisasterCategory,
    UpdateDisasterCategoryVariables,
} from '../../graphql/mutations/typings/UpdateDisasterCategory'
import {
    UpdateDisasterDamage,
    UpdateDisasterDamageVariables,
} from '../../graphql/mutations/typings/UpdateDisasterDamage'
import { UpdateDisasterEvent, UpdateDisasterEventVariables } from '../../graphql/mutations/typings/UpdateDisasterEvent'
import {
    UpdateDisasterPayment,
    UpdateDisasterPaymentVariables,
} from '../../graphql/mutations/typings/UpdateDisasterPayment'
import { UpdateDocument, UpdateDocumentVariables } from '../../graphql/mutations/typings/UpdateDocument'
import { UpdateEnterprise, UpdateEnterpriseVariables } from '../../graphql/mutations/typings/UpdateEnterprise'
import {
    UpdateEnterpriseChildren,
    UpdateEnterpriseChildrenVariables,
} from '../../graphql/mutations/typings/UpdateEnterpriseChildren'
import {
    UpdateEnterpriseParents,
    UpdateEnterpriseParentsVariables,
} from '../../graphql/mutations/typings/UpdateEnterpriseParents'
import { UpdateFleet, UpdateFleetVariables } from '../../graphql/mutations/typings/UpdateFleet'
import { UpdateGood, UpdateGoodVariables } from '../../graphql/mutations/typings/UpdateGood'
import {
    UpdateGuaranteeCategory,
    UpdateGuaranteeCategoryVariables,
} from '../../graphql/mutations/typings/UpdateGuaranteeCategory'
import { UpdateInsurance, UpdateInsuranceVariables } from '../../graphql/mutations/typings/UpdateInsurance'
import { UpdateInsurer, UpdateInsurerVariables } from '../../graphql/mutations/typings/UpdateInsurer'
import { UpdatePlace, UpdatePlaceVariables } from '../../graphql/mutations/typings/UpdatePlace'
import { UpdatePrevention, UpdatePreventionVariables } from '../../graphql/mutations/typings/UpdatePrevention'
import {
    UpdatePreventionCategory,
    UpdatePreventionCategoryVariables,
} from '../../graphql/mutations/typings/UpdatePreventionCategory'
import { UpdateRoadmapItem, UpdateRoadmapItemVariables } from '../../graphql/mutations/typings/UpdateRoadmapItem'
import { UpdateSite, UpdateSiteVariables } from '../../graphql/mutations/typings/UpdateSite'
import { UpdateUser, UpdateUserVariables } from '../../graphql/mutations/typings/UpdateUser'
import { UpdateUserPassword, UpdateUserPasswordVariables } from '../../graphql/mutations/typings/UpdateUserPassword'
import { UpdateVehicle, UpdateVehicleVariables } from '../../graphql/mutations/typings/UpdateVehicle'
import queries from '../../graphql/queries'
import { GetContract_DisastersVariables } from '../../graphql/queries/typings/GetContract_Disasters'
import { GetContract_DocumentsVariables } from '../../graphql/queries/typings/GetContract_Documents'
import { GetContract_FullVariables } from '../../graphql/queries/typings/GetContract_Full'
import { GetCustomer_UsersVariables } from '../../graphql/queries/typings/GetCustomer_Users'
import { GetDisasters_MinimalVariables } from '../../graphql/queries/typings/GetDisasters_Minimal'
import { GetDisaster_DocumentsVariables } from '../../graphql/queries/typings/GetDisaster_Documents'
import { GetDisaster_FullVariables } from '../../graphql/queries/typings/GetDisaster_Full'
import { GetDocuments_UserVariables } from '../../graphql/queries/typings/GetDocuments_User'
import { GetEnterprise_DocumentsVariables } from '../../graphql/queries/typings/GetEnterprise_Documents'
import { GetEnterprise_FullVariables } from '../../graphql/queries/typings/GetEnterprise_Full'
import { GetFleets_MinimalVariables } from '../../graphql/queries/typings/GetFleets_Minimal'
import { GetFleet_DocumentsVariables } from '../../graphql/queries/typings/GetFleet_Documents'
import { GetGoods_MinimalVariables } from '../../graphql/queries/typings/GetGoods_Minimal'
import { GetGood_DocumentsVariables } from '../../graphql/queries/typings/GetGood_Documents'
import { GetPlace_DocumentsVariables } from '../../graphql/queries/typings/GetPlace_Documents'
import { GetPreventions_PlaceMinimalVariables } from '../../graphql/queries/typings/GetPreventions_PlaceMinimal'
import { GetSites_MinimalVariables } from '../../graphql/queries/typings/GetSites_Minimal'
import { GetVehicles_MinimalVariables } from '../../graphql/queries/typings/GetVehicles_Minimal'
import { GetVehicle_DocumentsVariables } from '../../graphql/queries/typings/GetVehicle_Documents'
import {
    DamageTargetInput,
    EnumDamageTarget,
    EnumDisasterThrough,
    EnumDocumentParent,
    EnumGuaranteeTarget,
    GuaranteeTargetInput,
} from '../../graphql/typings/global_types'
import { UserAEA_Minimal } from '../helpers/data/models/User'
import { createQueryToRefetch, QueryToRefetch, runMutation } from '../helpers/data/runMutation'
import { useCustomerLoadedExisting } from './useCustomer'

/*******************
 *
 * CUSTOMERS
 *
 ******************/

type CreateCustomerProps = {
    title: string
    supervisor: UserAEA_Minimal
}

export const useCreateCustomer = () => {
    const [doCreateCustomer] = useMutation<CreateCustomer, CreateCustomerVariables>(mutations.CreateCustomer)

    const run = ({ title, supervisor }: CreateCustomerProps) => {
        return runMutation(
            doCreateCustomer,
            {
                title,
                supervisorId: supervisor.id,
            },
            'createProject',
            {
                queriesToRefetch: [createQueryToRefetch(queries.GetCustomers_Minimal, {})],
            }
        )
    }

    return {
        run,
    }
}

type UpdateCustomerProps = {
    customerId: string
    title: string
    supervisor: UserAEA_Minimal
}

export const useUpdateCustomer = () => {
    const [doUpdateCustomer] = useMutation<UpdateCustomer, UpdateCustomerVariables>(mutations.UpdateCustomer)

    const run = ({ customerId, title, supervisor }: UpdateCustomerProps) => {
        return runMutation(
            doUpdateCustomer,
            {
                id: customerId,
                title,
                supervisorId: supervisor.id,
            },
            'updateProject'
        )
    }

    return {
        run,
    }
}

export const useDeleteCustomer = () => {
    const [doDeleteCustomer] = useMutation<DeleteCustomer, DeleteCustomerVariables>(mutations.DeleteCustomer)

    const run = (variables: DeleteCustomerVariables) => {
        return runMutation(doDeleteCustomer, variables, 'deleteProject', {
            queriesToRefetch: [createQueryToRefetch(queries.GetCustomers_Minimal, {})],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * ENTERPRISES
 *
 ******************/

export const useCreateEnterprise = () => {
    const { customer } = useCustomerLoadedExisting()
    const [doCreateEnterprise] = useMutation<CreateEnterprise, CreateEnterpriseVariables>(mutations.CreateEnterprise)

    const run = (variables: CreateEnterpriseVariables) => {
        return runMutation(doCreateEnterprise, variables, 'createEnterprise', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, { id: customer.id }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateEnterprise = () => {
    const [doUpdateEnterprise] = useMutation<UpdateEnterprise, UpdateEnterpriseVariables>(mutations.UpdateEnterprise)

    const run = (variables: UpdateEnterpriseVariables) => {
        return runMutation(doUpdateEnterprise, variables, 'updateEnterprise')
    }

    return {
        run,
    }
}

type DeleteEnterpriseProps = DeleteEnterpriseVariables & {
    customerId: string
}

export const useDeleteEnterprise = () => {
    const [doDeleteEnterprise] = useMutation<DeleteEnterprise, DeleteEnterpriseVariables>(mutations.DeleteEnterprise)

    const run = ({ customerId, ...variables }: DeleteEnterpriseProps) => {
        return runMutation(doDeleteEnterprise, variables, 'deleteEnterprise', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, { id: customerId }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateEnterpriseParents = () => {
    const [doUpdateEnterpriseParents] = useMutation<UpdateEnterpriseParents, UpdateEnterpriseParentsVariables>(
        mutations.UpdateEnterpriseParents
    )

    const run = (variables: UpdateEnterpriseParentsVariables) => {
        return runMutation(doUpdateEnterpriseParents, variables, 'updateEnterpriseParents')
    }

    return {
        run,
    }
}

export const useUpdateEnterpriseChildren = () => {
    const [doUpdateEnterpriseChildren] = useMutation<UpdateEnterpriseChildren, UpdateEnterpriseChildrenVariables>(
        mutations.UpdateEnterpriseChildren
    )

    const run = (variables: UpdateEnterpriseChildrenVariables) => {
        return runMutation(doUpdateEnterpriseChildren, variables, 'updateEnterpriseChildren')
    }

    return {
        run,
    }
}

/*******************
 *
 * DISASTERS
 *
 ******************/

type CreateDisasterProps = CreateDisasterVariables & {
    enterpriseId: string
}

export const useCreateDisaster = () => {
    const { customer } = useCustomerLoadedExisting()
    const [doCreateDisaster] = useMutation<CreateDisaster, CreateDisasterVariables>(mutations.CreateDisaster)

    const run = ({ enterpriseId, ...variables }: CreateDisasterProps) => {
        const refetchGlobal = createQueryToRefetch<GetDisasters_MinimalVariables>(queries.GetDisasters_Minimal, {
            customerId: customer.id,
            through: EnumDisasterThrough.through_contract,
        })

        const refetchEnterprise = createQueryToRefetch<GetDisasters_MinimalVariables>(queries.GetDisasters_Minimal, {
            customerId: customer.id,
            enterpriseId,
            through: EnumDisasterThrough.through_damage,
        })

        const refetchContract = createQueryToRefetch<GetContract_DisastersVariables>(queries.GetContract_Disasters, {
            contractId: variables.contractId,
        })

        const queriesToRefetch = [refetchGlobal, refetchEnterprise, refetchContract]

        return runMutation(doCreateDisaster, variables, 'createDisaster', {
            queriesToRefetch,
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'disasterIndicators' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

export const useUpdateDisaster = () => {
    const [doUpdateDisaster] = useMutation<UpdateDisaster, UpdateDisasterVariables>(mutations.UpdateDisaster)

    const run = (variables: UpdateDisasterVariables) => {
        return runMutation(doUpdateDisaster, variables, 'updateDisaster')
    }

    return {
        run,
    }
}

type DeleteDisasterProps = DeleteDisasterVariables & {
    enterpriseId: string
    contractId: string
}

export const useDeleteDisaster = () => {
    const { customer } = useCustomerLoadedExisting()
    const [doDeleteDisaster] = useMutation<DeleteDisaster, DeleteDisasterVariables>(mutations.DeleteDisaster)

    const run = ({ enterpriseId, contractId, ...variables }: DeleteDisasterProps) => {
        const refetchGlobal = createQueryToRefetch<GetDisasters_MinimalVariables>(queries.GetDisasters_Minimal, {
            customerId: customer.id,
            through: EnumDisasterThrough.through_contract,
        })

        const refetchEnterprise = createQueryToRefetch<GetDisasters_MinimalVariables>(queries.GetDisasters_Minimal, {
            customerId: customer.id,
            enterpriseId,
            through: EnumDisasterThrough.through_damage,
        })

        const refetchContract = createQueryToRefetch<GetContract_DisastersVariables>(queries.GetContract_Disasters, {
            contractId,
        })

        return runMutation(doDeleteDisaster, variables, 'deleteDisaster', {
            queriesToRefetch: [refetchGlobal, refetchEnterprise, refetchContract],
        })
    }

    return {
        run,
    }
}

/************************
 *
 * DISASTERS PRE-DECLARE
 *
 ***********************/

// Pre-declaration (light declaration) of disasters
export const useDeclareDisaster = () => {
    const [doDeclareDisaster] = useMutation<DeclareDisaster, DeclareDisasterVariables>(mutations.DeclareDisaster)

    const run = (variables: DeclareDisasterVariables) => {
        return runMutation(doDeclareDisaster, variables, 'declareDisaster')
    }

    return {
        run,
    }
}

/************************
 *
 * DISASTERS DAMAGE
 *
 ***********************/

const getDisasterDamageRefetchQueries = (
    customerId: string,
    disasterId: string,
    damageTargets: DamageTargetInput[]
): QueryToRefetch[] => {
    const queriesToRefetch: QueryToRefetch[] = [
        createQueryToRefetch<GetDisaster_FullVariables>(queries.GetDisaster_Full, {
            disasterId: disasterId,
        }),
    ]

    const createEnterpriseQuery = (enterpriseId: string) => {
        return createQueryToRefetch<GetDisasters_MinimalVariables>(queries.GetDisasters_Minimal, {
            customerId,
            enterpriseId,
            through: EnumDisasterThrough.through_damage,
        })
    }

    // const createFleetQuery = (id: string) => {

    // }

    // const createGoodQuery = (id: string) => {

    // }

    const createPlaceQuery = (placeId: string) => {
        return createQueryToRefetch<GetDisasters_MinimalVariables>(queries.GetDisasters_Minimal, {
            customerId,
        })
    }

    // const createSiteQuery = () => {

    // }

    // const createVehicleQuery = (id: string) => {

    // }

    damageTargets.forEach((damageTarget) => {
        const type = damageTarget.targetType
        const id = damageTarget.targetId

        const queryToRefetch: QueryToRefetch | null =
            type === EnumDamageTarget.Enterprise
                ? createEnterpriseQuery(id)
                : type === EnumDamageTarget.Fleet
                ? null
                : type === EnumDamageTarget.Good
                ? null
                : type === EnumDamageTarget.Local
                ? createPlaceQuery(id)
                : type === EnumDamageTarget.Site
                ? null
                : type === EnumDamageTarget.Vehicle
                ? null
                : null

        if (!queryToRefetch) return

        queriesToRefetch.push(queryToRefetch)
    })

    return queriesToRefetch
}

type CreateDisasterDamageProps = CreateDisasterDamageVariables & {
    customerId: string
}

export const useCreateDisasterDamage = () => {
    const [doCreateDisasterDamage] = useMutation<CreateDisasterDamage, CreateDisasterDamageVariables>(
        mutations.CreateDisasterDamage
    )

    const run = ({ customerId, ...variables }: CreateDisasterDamageProps) => {
        const queriesToRefetch = getDisasterDamageRefetchQueries(
            customerId,
            variables.disasterId,
            variables.damageTargets
        )

        return runMutation(doCreateDisasterDamage, variables, 'createDamage', {
            queriesToRefetch,
        })
    }

    return {
        run,
    }
}

type UpdateDisasterDamageProps = UpdateDisasterDamageVariables & {
    customerId: string
}

export const useUpdateDisasterDamage = () => {
    const [doUpdateDisasterDamage] = useMutation<UpdateDisasterDamage, UpdateDisasterDamageVariables>(
        mutations.UpdateDisasterDamage
    )

    const run = ({ customerId, ...variables }: UpdateDisasterDamageProps) => {
        const queriesToRefetch = getDisasterDamageRefetchQueries(
            customerId,
            variables.disasterId,
            variables.damageTargets
        )

        return runMutation(doUpdateDisasterDamage, variables, 'updateDamage', {
            queriesToRefetch,
        })
    }

    return {
        run,
    }
}

type DeleteDisasterDamageProps = DeleteDisasterDamageVariables & {
    disasterId: string
}

export const useDeleteDisasterDamage = () => {
    const [doDeleteDisasterDamage] = useMutation<DeleteDisasterDamage, DeleteDisasterDamageVariables>(
        mutations.DeleteDisasterDamage
    )

    const run = ({ disasterId, ...variables }: DeleteDisasterDamageProps) => {
        return runMutation(doDeleteDisasterDamage, variables, 'deleteDamage', {
            queriesToRefetch: [
                createQueryToRefetch<GetDisaster_FullVariables>(queries.GetDisaster_Full, {
                    disasterId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/************************
 *
 * DISASTERS PAYMENT
 *
 ***********************/

export const useCreateDisasterPayment = () => {
    const [doCreateDisasterPayment] = useMutation<CreateDisasterPayment, CreateDisasterPaymentVariables>(
        mutations.CreateDisasterPayment
    )

    const run = (variables: CreateDisasterPaymentVariables) => {
        return runMutation(doCreateDisasterPayment, variables, 'createDisasterPayment', {
            queriesToRefetch: [
                createQueryToRefetch<GetDisaster_FullVariables>(queries.GetDisaster_Full, {
                    disasterId: variables.disasterId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateDisasterPayment = () => {
    const [doUpdateDisasterPayment] = useMutation<UpdateDisasterPayment, UpdateDisasterPaymentVariables>(
        mutations.UpdateDisasterPayment
    )

    const run = (variables: UpdateDisasterPaymentVariables) => {
        return runMutation(doUpdateDisasterPayment, variables, 'updateDisasterPayment')
    }

    return {
        run,
    }
}

type DeleteDisasterPaymentProps = DeleteDisasterPaymentVariables & {
    disasterId: string
}

export const useDeleteDisasterPayment = () => {
    const [doDeleteDisasterPayment] = useMutation<DeleteDisasterPayment, DeleteDisasterPaymentVariables>(
        mutations.DeleteDisasterPayment
    )

    const run = ({ disasterId, ...variables }: DeleteDisasterPaymentProps) => {
        return runMutation(doDeleteDisasterPayment, variables, 'deleteDisasterPayment', {
            queriesToRefetch: [
                createQueryToRefetch<GetDisaster_FullVariables>(queries.GetDisaster_Full, {
                    disasterId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * DISASTERS EVENTS
 *
 ******************/

export const useCreateDisasterEvent = () => {
    const [doCreateDisasterEvent] = useMutation<CreateDisasterEvent, CreateDisasterEventVariables>(
        mutations.CreateDisasterEvent
    )

    const run = (variables: CreateDisasterEventVariables) => {
        return runMutation(doCreateDisasterEvent, variables, 'createEvent', {
            queriesToRefetch: [createQueryToRefetch(queries.GetDisasterEvents_Minimal, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdateDisasterEvent = () => {
    const [doUpdateDisasterEvent] = useMutation<UpdateDisasterEvent, UpdateDisasterEventVariables>(
        mutations.UpdateDisasterEvent
    )

    const run = (variables: UpdateDisasterEventVariables) => {
        return runMutation(doUpdateDisasterEvent, variables, 'updateEvent')
    }

    return {
        run,
    }
}

export const useDeleteDisasterEvent = () => {
    const [doDeleteDisasterEvent] = useMutation<DeleteDisasterEvent, DeleteDisasterEventVariables>(
        mutations.DeleteDisasterEvent
    )

    const run = (variables: DeleteDisasterEventVariables) => {
        return runMutation(doDeleteDisasterEvent, variables, 'deleteEvent', {
            queriesToRefetch: [createQueryToRefetch(queries.GetDisasterEvents_Minimal, {})],
        })
    }

    return {
        run,
    }
}

/************************
 *
 * DISASTER CATEGORIES
 *
 ***********************/

export const useCreateDisasterCategory = () => {
    const [doCreateDisasterCategory] = useMutation<CreateDisasterCategory, CreateDisasterCategoryVariables>(
        mutations.CreateDisasterCategory
    )

    const run = (variables: CreateDisasterCategoryVariables) => {
        return runMutation(doCreateDisasterCategory, variables, 'createDisasterCategory', {
            queriesToRefetch: [createQueryToRefetch(queries.GetDisasterCategories, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdateDisasterCategory = () => {
    const [doUpdateDisasterCategory] = useMutation<UpdateDisasterCategory, UpdateDisasterCategoryVariables>(
        mutations.UpdateDisasterCategory
    )

    const run = (variables: UpdateDisasterCategoryVariables) => {
        return runMutation(doUpdateDisasterCategory, variables, 'updateDisasterCategory')
    }

    return {
        run,
    }
}

export const useDeleteDisasterCategory = () => {
    const [doDeleteDisasterCategory] = useMutation<DeleteDisasterCategory, DeleteDisasterCategoryVariables>(
        mutations.DeleteDisasterCategory
    )

    const run = (variables: DeleteDisasterCategoryVariables) => {
        return runMutation(doDeleteDisasterCategory, variables, 'deleteDisasterCategory', {
            queriesToRefetch: [createQueryToRefetch(queries.GetDisasterCategories, {})],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * CONTRACTS
 *
 ******************/

type CreateContractProps = CreateContractVariables & {
    customerId: string
}

export const useCreateContract = () => {
    const [doCreateContract] = useMutation<CreateContract, CreateContractVariables>(mutations.CreateContract)

    const run = ({ customerId, ...variables }: CreateContractProps) => {
        return runMutation(doCreateContract, variables, 'createContract', {
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

export const useUpdateContract = () => {
    const [doUpdateContract] = useMutation<UpdateContract, UpdateContractVariables>(mutations.UpdateContract)

    const run = (variables: UpdateContractVariables) => {
        return runMutation(doUpdateContract, variables, 'updateContract', {
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

type DeleteContractProps = DeleteContractVariables & {
    customerId: string
    enterpriseId: string
}

export const useDeleteContract = () => {
    const [doDeleteContract] = useMutation<DeleteContract, DeleteContractVariables>(mutations.DeleteContract)

    const run = ({ customerId, enterpriseId, ...variables }: DeleteContractProps) => {
        return runMutation(doDeleteContract, variables, 'deleteContract', {
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

type DuplicateContractProps = DuplicateContractVariables & {
    customerId: string
    enterpriseId: string
}

export const useDuplicateContract = () => {
    const [doDuplicateContract] = useMutation<DuplicateContract, DuplicateContractVariables>(
        mutations.DuplicateContract
    )

    const run = ({ customerId, enterpriseId, ...variables }: DuplicateContractProps) => {
        return runMutation(doDuplicateContract, variables, 'duplicateContract', {
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * CONTRACT GUARANTEES
 *
 ******************/

export const useCreateContractGuarantee = () => {
    const [doCreateContractGuarantee] = useMutation<CreateContractGuarantee, CreateContractGuaranteeVariables>(
        mutations.CreateContractGuarantee
    )

    const run = (variables: CreateContractGuaranteeVariables) => {
        return runMutation(doCreateContractGuarantee, variables, 'createGuarantee', {
            queriesToRefetch: [
                createQueryToRefetch<GetContract_FullVariables>(queries.GetContract_Full, {
                    contractId: variables.contractId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateContractGuarantee = () => {
    const [doUpdateContractGuarantee] = useMutation<UpdateContractGuarantee, UpdateContractGuaranteeVariables>(
        mutations.UpdateContractGuarantee
    )

    const run = (variables: UpdateContractGuaranteeVariables) => {
        return runMutation(doUpdateContractGuarantee, variables, 'updateGuarantee')
    }

    return {
        run,
    }
}

type DeleteContractGuaranteeProps = DeleteContractGuaranteeVariables & {
    contractId: string
}

export const useDeleteContractGuarantee = () => {
    const [doDeleteContractGuarantee] = useMutation<DeleteContractGuarantee, DeleteContractGuaranteeVariables>(
        mutations.DeleteContractGuarantee
    )

    const run = ({ contractId, ...variables }: DeleteContractGuaranteeProps) => {
        return runMutation(doDeleteContractGuarantee, variables, 'deleteGuarantee', {
            queriesToRefetch: [
                createQueryToRefetch<GetContract_FullVariables>(queries.GetContract_Full, {
                    contractId: contractId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

const getGuaranteeLinkCacheEvict = (guaranteeTarget: GuaranteeTargetInput): string => {
    const cacheEvict =
        guaranteeTarget.targetType === EnumGuaranteeTarget.Fleet
            ? 'Fleet'
            : guaranteeTarget.targetType === EnumGuaranteeTarget.Good
            ? 'Good'
            : guaranteeTarget.targetType === EnumGuaranteeTarget.Local
            ? 'Local'
            : guaranteeTarget.targetType === EnumGuaranteeTarget.Site
            ? 'Site'
            : guaranteeTarget.targetType === EnumGuaranteeTarget.Vehicle
            ? 'Vehicle'
            : guaranteeTarget.targetType === EnumGuaranteeTarget.Enterprise
            ? 'Enterprise'
            : 'Unknown'

    return `${cacheEvict}:${guaranteeTarget.targetId}`
}

type UseLinkToGuaranteeVariables = LinkGuaranteeTargetToGuaranteesVariables & {
    contractID: string
}

export const useLinkToGuarantee = () => {
    const [doLinkGuarantees] = useMutation<LinkGuaranteeTargetToGuarantees, LinkGuaranteeTargetToGuaranteesVariables>(
        mutations.LinkGuaranteeTargetToGuarantees
    )

    const run = ({ contractID, ...variables }: UseLinkToGuaranteeVariables) => {
        const cacheEvict = getGuaranteeLinkCacheEvict(variables.guaranteeTarget)

        return runMutation(doLinkGuarantees, variables, 'linkGuaranteeTargetToGuarantees', {
            update: (cache, { data }) => {
                cache.evict({ id: cacheEvict })
                cache.evict({ id: `Contract:${contractID}` })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

type UseUnlinkFromGuaranteeVariables = UnlinkGuaranteeTargetFromGuaranteesVariables & {
    contractID: string
}

export const useUnlinkFromGuarantee = () => {
    const [doUnlinkGuarantees] = useMutation<
        UnlinkGuaranteeTargetFromGuarantees,
        UnlinkGuaranteeTargetFromGuaranteesVariables
    >(mutations.UnlinkGuaranteeTargetFromGuarantees)

    const run = ({ contractID, ...variables }: UseUnlinkFromGuaranteeVariables) => {
        const cacheEvict = getGuaranteeLinkCacheEvict(variables.guaranteeTarget)

        return runMutation(doUnlinkGuarantees, variables, 'unlinkGuaranteeTargetFromGuarantees', {
            update: (cache, { data }) => {
                cache.evict({ id: cacheEvict })
                cache.evict({ id: `Contract:${contractID}` })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

/************************
 *
 * GUARANTEE CATEGORIES
 *
 ***********************/

export const useCreateGuaranteeCategory = () => {
    const [doCreateGuaranteeCategory] = useMutation<CreateGuaranteeCategory, CreateGuaranteeCategoryVariables>(
        mutations.CreateGuaranteeCategory
    )

    const run = (variables: CreateGuaranteeCategoryVariables) => {
        return runMutation(doCreateGuaranteeCategory, variables, 'createGuaranteeCategory', {
            queriesToRefetch: [createQueryToRefetch(queries.GetGuaranteeCategories, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdateGuaranteeCategory = () => {
    const [doUpdateGuaranteeCategory] = useMutation<UpdateGuaranteeCategory, UpdateGuaranteeCategoryVariables>(
        mutations.UpdateGuaranteeCategory
    )

    const run = (variables: UpdateGuaranteeCategoryVariables) => {
        return runMutation(doUpdateGuaranteeCategory, variables, 'updateGuaranteeCategory')
    }

    return {
        run,
    }
}

export const useDeleteGuaranteeCategory = () => {
    const [doDeletePreventionCategory] = useMutation<DeleteGuaranteeCategory, DeleteGuaranteeCategoryVariables>(
        mutations.DeleteGuaranteeCategory
    )

    const run = (variables: DeleteGuaranteeCategoryVariables) => {
        return runMutation(doDeletePreventionCategory, variables, 'deleteGuaranteeCategory', {
            queriesToRefetch: [createQueryToRefetch(queries.GetGuaranteeCategories, {})],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * SITES
 *
 ******************/

export const useCreateSite = () => {
    const [doCreateSite] = useMutation<CreateSite, CreateSiteVariables>(mutations.CreateSite)

    const run = (variables: CreateSiteVariables) => {
        return runMutation(doCreateSite, variables, 'createSite', {
            queriesToRefetch: [
                createQueryToRefetch<GetSites_MinimalVariables>(queries.GetSites_Minimal, {
                    customerId: variables.customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateSite = () => {
    const [doUpdateSite] = useMutation<UpdateSite, UpdateSiteVariables>(mutations.UpdateSite)

    const run = (variables: UpdateSiteVariables) => {
        return runMutation(doUpdateSite, variables, 'updateSite')
    }

    return {
        run,
    }
}

type DeleteSiteProps = DeleteSiteVariables & {
    customerId: string
}

export const useDeleteSite = () => {
    const [doDeleteSite] = useMutation<DeleteSite, DeleteSiteVariables>(mutations.DeleteSite)

    const run = ({ customerId, ...variables }: DeleteSiteProps) => {
        return runMutation(doDeleteSite, variables, 'deleteSite', {
            queriesToRefetch: [
                createQueryToRefetch<GetSites_MinimalVariables>(queries.GetSites_Minimal, {
                    customerId: customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * PLACES
 *
 ******************/

type CreatePlaceProps = CreatePlaceVariables & {
    customerId: string
}

export const useCreatePlace = () => {
    const [doCreatePlace] = useMutation<CreatePlace, CreatePlaceVariables>(mutations.CreatePlace)

    const run = ({ customerId, ...variables }: CreatePlaceProps) => {
        return runMutation(doCreatePlace, variables, 'createLocal', {
            queriesToRefetch: [
                // We refetch the locals from the customer
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
                // We refetch the locals from the enterprise
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId: variables.enterpriseId,
                }),
                //TODO Refetch locals from disaster, contracts ... ?
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdatePlace = () => {
    const [doUpdatePlace] = useMutation<UpdatePlace, UpdatePlaceVariables>(mutations.UpdatePlace)

    const run = (variables: UpdatePlaceVariables) => {
        return runMutation(doUpdatePlace, variables, 'updateLocal')
    }

    return {
        run,
    }
}

type DeletePlaceProps = DeletePlaceVariables & {
    customerId: string
    enterpriseId: string
}

export const useDeletePlace = () => {
    const [doDeletePlace] = useMutation<DeletePlace, DeletePlaceVariables>(mutations.DeletePlace)

    const run = ({ customerId, enterpriseId, ...variables }: DeletePlaceProps) => {
        return runMutation(doDeletePlace, variables, 'deleteLocal', {
            queriesToRefetch: [
                // We refetch the locals from the customer
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
                // We refetch the locals from the enterprise
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId: enterpriseId,
                }),
                //TODO Refetch locals from disaster, contracts ... ?
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * USERS
 *
 ******************/

type CreateUserProps = Omit<CreateUserVariables, 'customerIds'> & {
    customerId: string
}

export const useCreateUser = () => {
    const [doCreateUser] = useMutation<CreateUser, CreateUserVariables>(mutations.CreateUser)

    const run = ({ customerId, ...variables }: CreateUserProps) => {
        const formattedVariables: CreateUserVariables = { ...variables, customerIds: [customerId] }
        return runMutation(doCreateUser, formattedVariables, 'createUser', {
            queriesToRefetch: [
                // We refetch the locals from the customer
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateUser = () => {
    const [doUpdateUser] = useMutation<UpdateUser, UpdateUserVariables>(mutations.UpdateUser)

    const run = (variables: UpdateUserVariables) => {
        return runMutation(doUpdateUser, variables, 'updateUser')
    }

    return {
        run,
    }
}

type DeleteUserProps = DeleteUserVariables & {
    customerId: string
}

export const useDeleteUser = () => {
    const [doDeleteUser] = useMutation<DeleteUser, DeleteUserVariables>(mutations.DeleteUser)

    const run = ({ customerId, ...variables }: DeleteUserProps) => {
        return runMutation(doDeleteUser, variables, 'deleteUser', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateUserPassword = () => {
    const [doUpdateUserPassword] = useMutation<UpdateUserPassword, UpdateUserPasswordVariables>(
        mutations.UpdateUserPassword
    )

    const run = (variables: UpdateUserPasswordVariables) => {
        return runMutation(doUpdateUserPassword, variables, 'updateUserPasswordByUserToken')
    }

    return {
        run,
    }
}

export const useForgotPassword = () => {
    const [doForgotPassword] = useMutation<ForgotPassword, ForgotPasswordVariables>(mutations.ForgotPassword)

    const run = (variables: ForgotPasswordVariables) => {
        return runMutation(doForgotPassword, variables, 'forgottenPassword')
    }

    return {
        run,
    }
}

/*******************
 *
 * ROADMAP
 *
 ******************/

export const useCreateRoadmapItem = () => {
    const [doCreateRoadmapItem] = useMutation<CreateRoadmapItem, CreateRoadmapItemVariables>(
        mutations.CreateRoadmapItem
    )

    const run = (variables: CreateRoadmapItemVariables) => {
        return runMutation(doCreateRoadmapItem, variables, 'createRoadmapItem', {
            queriesToRefetch: [
                // We refetch the locals from the customer
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: variables.customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateRoadmapItem = () => {
    const [doUpdateRoadmapItem] = useMutation<UpdateRoadmapItem, UpdateRoadmapItemVariables>(
        mutations.UpdateRoadmapItem
    )

    const run = (variables: UpdateRoadmapItemVariables) => {
        return runMutation(doUpdateRoadmapItem, variables, 'updateRoadmapItem')
    }

    return {
        run,
    }
}

type DeleteRoadmapItemProps = DeleteRoadmapItemVariables & {
    customerId: string
}

export const useDeleteRoadmapItem = () => {
    const [doDeleteRoadmapItem] = useMutation<DeleteRoadmapItem, DeleteRoadmapItemVariables>(
        mutations.DeleteRoadmapItem
    )

    const run = ({ customerId, ...variables }: DeleteRoadmapItemProps) => {
        return runMutation(doDeleteRoadmapItem, variables, 'deleteRoadmapItem', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * DOCUMENTS
 *
 ******************/

const getQueryToRefetch = (
    customerId: string,
    parentType: EnumDocumentParent,
    parentId: string
): QueryToRefetch | null => {
    let query = null

    switch (parentType) {
        case EnumDocumentParent.Contract:
            return createQueryToRefetch<GetContract_DocumentsVariables>(queries.GetContract_Documents, {
                contractId: parentId,
            })
        case EnumDocumentParent.Disaster:
            return createQueryToRefetch<GetDisaster_DocumentsVariables>(queries.GetDisaster_Documents, {
                disasterId: parentId,
            })
        case EnumDocumentParent.Enterprise:
            return createQueryToRefetch<GetEnterprise_DocumentsVariables>(queries.GetEnterprise_Documents, {
                enterpriseId: parentId,
            })
        case EnumDocumentParent.Fleet:
            return createQueryToRefetch<GetFleet_DocumentsVariables>(queries.GetFleet_Documents, {
                id: parentId,
            })
        case EnumDocumentParent.Good:
            return createQueryToRefetch<GetGood_DocumentsVariables>(queries.GetGood_Documents, {
                id: parentId,
            })
        case EnumDocumentParent.Local:
            return createQueryToRefetch<GetPlace_DocumentsVariables>(queries.GetPlace_Documents, {
                placeId: parentId,
            })
        case EnumDocumentParent.Prevention:
            return createQueryToRefetch<GetPreventions_PlaceMinimalVariables>(queries.GetPreventions_PlaceMinimal, {
                id: customerId,
            })
        case EnumDocumentParent.Site:
            //TODO
            return null
        case EnumDocumentParent.Vehicle:
            return createQueryToRefetch<GetVehicle_DocumentsVariables>(queries.GetVehicle_Documents, {
                id: parentId,
            })
        case EnumDocumentParent.Project:
        default:
            // We do nothing, global documents list is refreshed by default
            return null
    }
}

type CreateDocumentProps = CreateDocumentVariables & {
    customerId: string
}

export const useCreateDocument = () => {
    const [doCreateDocument] = useMutation<CreateDocument, CreateDocumentVariables>(mutations.CreateDocument)

    const run = ({ customerId, ...variables }: CreateDocumentProps) => {
        const queriesToRefetch: QueryToRefetch[] = [
            createQueryToRefetch<GetDocuments_UserVariables>(queries.GetDocuments_User, {
                customerId,
            }),
        ]

        const targetQueryToRefetch = getQueryToRefetch(customerId, variables.parentType, variables.parentId)
        if (targetQueryToRefetch) queriesToRefetch.push(targetQueryToRefetch)

        return runMutation(doCreateDocument, variables, 'createDocument', {
            queriesToRefetch,
        })
    }

    return {
        run,
    }
}

type CreateDocumentsProps = CreateDocumentsVariables & {
    customerId: string
}

export const useCreateDocuments = () => {
    const [doCreateDocuments] = useMutation<CreateDocuments, CreateDocumentsVariables>(mutations.CreateDocuments)

    const run = ({ customerId, ...variables }: CreateDocumentsProps) => {
        const queriesToRefetch: QueryToRefetch[] = [
            createQueryToRefetch<GetDocuments_UserVariables>(queries.GetDocuments_User, {
                customerId,
            }),
        ]
        //TODO When we will have multiple parentTypes, improve this
        const docInput = variables.documentsInput[0]
        const targetQueryToRefetch = getQueryToRefetch(customerId, docInput.parentType, docInput.parentId)
        if (targetQueryToRefetch) queriesToRefetch.push(targetQueryToRefetch)

        return runMutation(doCreateDocuments, variables, 'createDocuments', {
            queriesToRefetch,
        })
    }

    return {
        run,
    }
}

export const useUpdateDocument = () => {
    const [doUpdateDocument] = useMutation<UpdateDocument, UpdateDocumentVariables>(mutations.UpdateDocument)

    const run = (variables: UpdateDocumentVariables) => {
        return runMutation(doUpdateDocument, variables, 'updateDocument')
    }

    return {
        run,
    }
}

type DeleteDocumentProps = DeleteDocumentVariables & {
    customerId: string
    parentType: EnumDocumentParent
    parentId: string
}

export const useDeleteDocument = () => {
    const [doDeleteDocument] = useMutation<DeleteDocument, DeleteDocumentVariables>(mutations.DeleteDocument)

    const run = ({ customerId, parentType, parentId, ...variables }: DeleteDocumentProps) => {
        const queriesToRefetch: QueryToRefetch[] = [
            createQueryToRefetch<GetDocuments_UserVariables>(queries.GetDocuments_User, {
                customerId,
            }),
        ]

        const targetQueryToRefetch = getQueryToRefetch(customerId, parentType, parentId)
        if (targetQueryToRefetch) queriesToRefetch.push(targetQueryToRefetch)

        return runMutation(doDeleteDocument, variables, 'deleteDocument', {
            queriesToRefetch,
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * AGREEMENTS
 *
 ******************/

type CreateAgreementProps = CreateAgreementVariables & {
    customerId: string
}

export const useCreateAgreement = () => {
    const [doCreateAgreement] = useMutation<CreateAgreement, CreateAgreementVariables>(mutations.CreateAgreement)

    const run = ({ customerId, ...variables }: CreateAgreementProps) => {
        return runMutation(doCreateAgreement, variables, 'createAgreement', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateAgreement = () => {
    const [doUpdateAgreement] = useMutation<UpdateAgreement, UpdateAgreementVariables>(mutations.UpdateAgreement)

    const run = (variables: UpdateAgreementVariables) => {
        return runMutation(doUpdateAgreement, variables, 'updateAgreement')
    }

    return {
        run,
    }
}

type DeleteAgreementProps = DeleteAgreementVariables & {
    customerId: string
}

export const useDeleteAgreement = () => {
    const [doDeleteAgreement] = useMutation<DeleteAgreement, DeleteAgreementVariables>(mutations.DeleteAgreement)

    const run = ({ customerId, ...variables }: DeleteAgreementProps) => {
        return runMutation(doDeleteAgreement, variables, 'deleteAgreement', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
                    id: customerId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * AGREEMENTS
 *
 ******************/

type CreateFleetProps = CreateFleetVariables & {
    customerId: string
}

export const useCreateFleet = () => {
    const [doCreateFleet] = useMutation<CreateFleet, CreateFleetVariables>(mutations.CreateFleet)

    const run = ({ customerId, ...variables }: CreateFleetProps) => {
        return runMutation(doCreateFleet, variables, 'createFleet', {
            queriesToRefetch: [
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId: variables.enterpriseId,
                }),
                createQueryToRefetch<GetFleets_MinimalVariables>(queries.GetFleets_Minimal, {
                    customerId,
                    enterpriseId: variables.enterpriseId,
                }),
            ],
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'fleets' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

export const useUpdateFleet = () => {
    const [doUpdateFleet] = useMutation<UpdateFleet, UpdateFleetVariables>(mutations.UpdateFleet)

    const run = (variables: UpdateFleetVariables) => {
        return runMutation(doUpdateFleet, variables, 'updateFleet')
    }

    return {
        run,
    }
}

type DeleteFleetProps = DeleteFleetVariables & {
    enterpriseId: string
    customerId: string
}

export const useDeleteFleet = () => {
    const [doDeleteFleet] = useMutation<DeleteFleet, DeleteFleetVariables>(mutations.DeleteFleet)

    const run = ({ enterpriseId, customerId, ...variables }: DeleteFleetProps) => {
        return runMutation(doDeleteFleet, variables, 'deleteFleet', {
            queriesToRefetch: [
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId,
                }),
                createQueryToRefetch<GetFleets_MinimalVariables>(queries.GetFleets_Minimal, {
                    customerId,
                    enterpriseId,
                }),
            ],
            update: (cache, { data }) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'fleets' })
                cache.gc()
            },
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * VEHICLES
 *
 ******************/

type UseCreateVehicleVariablesProps = CreateVehicleVariables & {
    customerId: string
}

export const useCreateVehicle = () => {
    const [doCreateVehicle] = useMutation<CreateVehicle, CreateVehicleVariables>(mutations.CreateVehicle)

    const run = ({ customerId, ...variables }: UseCreateVehicleVariablesProps) => {
        return runMutation(doCreateVehicle, variables, 'createVehicle', {
            queriesToRefetch: [
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId: variables.enterpriseId,
                }),
                createQueryToRefetch<GetVehicles_MinimalVariables>(queries.GetVehicles_Minimal, {
                    customerId,
                }),
                createQueryToRefetch<GetVehicles_MinimalVariables>(queries.GetVehicles_Minimal, {
                    customerId,
                    enterpriseId: variables.enterpriseId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateVehicle = () => {
    const [doUpdateVehicle] = useMutation<UpdateVehicle, UpdateVehicleVariables>(mutations.UpdateVehicle)

    const run = (variables: UpdateVehicleVariables) => {
        return runMutation(doUpdateVehicle, variables, 'updateVehicle', {
            queriesToRefetch: [
                // Because update also updates fleets, we need to update all the enterprises
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId: variables.enterpriseId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

type DeleteVehicleProps = DeleteVehicleVariables & {
    enterpriseId: string
    customerId: string
}

export const useDeleteVehicle = () => {
    const [doDeleteVehicle] = useMutation<DeleteVehicle, DeleteVehicleVariables>(mutations.DeleteVehicle)

    const run = ({ enterpriseId, customerId, ...variables }: DeleteVehicleProps) => {
        return runMutation(doDeleteVehicle, variables, 'deleteVehicle', {
            queriesToRefetch: [
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId,
                }),
                createQueryToRefetch<GetVehicles_MinimalVariables>(queries.GetVehicles_Minimal, {
                    customerId,
                }),
                createQueryToRefetch<GetVehicles_MinimalVariables>(queries.GetVehicles_Minimal, {
                    customerId,
                    enterpriseId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * GOODS
 *
 ******************/
type CreateGoodProps = CreateGoodVariables & {
    parentEnterpriseId: string
    customerId: string
}

export const useCreateGood = () => {
    const [doCreateGood] = useMutation<CreateGood, CreateGoodVariables>(mutations.CreateGood)

    const run = ({ parentEnterpriseId, customerId, ...variables }: CreateGoodProps) => {
        return runMutation(doCreateGood, variables, 'createGood', {
            queriesToRefetch: [
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId: parentEnterpriseId,
                }),
                createQueryToRefetch<GetGoods_MinimalVariables>(queries.GetGoods_Minimal, {
                    customerId,
                    enterpriseId: parentEnterpriseId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdateGood = () => {
    const [doUpdateGood] = useMutation<UpdateGood, UpdateGoodVariables>(mutations.UpdateGood)

    const run = (variables: UpdateGoodVariables) => {
        return runMutation(doUpdateGood, variables, 'updateGood')
    }

    return {
        run,
    }
}

type DeleteGoodProps = DeleteGoodVariables & {
    enterpriseId: string
    customerId: string
}

export const useDeleteGood = () => {
    const [doDeleteGood] = useMutation<DeleteGood, DeleteGoodVariables>(mutations.DeleteGood)

    const run = ({ enterpriseId, customerId, ...variables }: DeleteGoodProps) => {
        return runMutation(doDeleteGood, variables, 'deleteGood', {
            queriesToRefetch: [
                createQueryToRefetch<GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
                    enterpriseId,
                }),
                createQueryToRefetch<GetGoods_MinimalVariables>(queries.GetGoods_Minimal, {
                    customerId,
                    enterpriseId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * PREVENTIONS
 *
 ******************/

type CreatePreventionProps = CreatePreventionVariables & {
    customerId: string
}

export const useCreatePrevention = () => {
    const [doCreatePrevention] = useMutation<CreatePrevention, CreatePreventionVariables>(mutations.CreatePrevention)

    const run = ({ customerId, ...variables }: CreatePreventionProps) => {
        return runMutation(doCreatePrevention, variables, 'createPrevention', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, { id: customerId }),
                createQueryToRefetch<GetPlace_DocumentsVariables>(queries.GetPlace_Documents, {
                    placeId: variables.placeId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

export const useUpdatePrevention = () => {
    const [doUpdatePrevention] = useMutation<UpdatePrevention, UpdatePreventionVariables>(mutations.UpdatePrevention)

    const run = (variables: UpdatePreventionVariables) => {
        return runMutation(doUpdatePrevention, variables, 'updatePrevention')
    }

    return {
        run,
    }
}

type DeletePreventionProps = DeletePreventionVariables & {
    customerId: string
    placeId: string
}

export const useDeletePrevention = () => {
    const [doDeletePrevention] = useMutation<DeletePrevention, DeletePreventionVariables>(mutations.DeletePrevention)

    const run = ({ customerId, placeId, ...variables }: DeletePreventionProps) => {
        return runMutation(doDeletePrevention, variables, 'deletePrevention', {
            queriesToRefetch: [
                createQueryToRefetch<GetCustomer_UsersVariables>(queries.GetCustomer_Users, { id: customerId }),
                createQueryToRefetch<GetPlace_DocumentsVariables>(queries.GetPlace_Documents, {
                    placeId: placeId,
                }),
            ],
        })
    }

    return {
        run,
    }
}

/************************
 *
 * PREVENTIONS CATEGORIES
 *
 ***********************/

export const useCreatePreventionCategory = () => {
    const [doCreatePreventionCategory] = useMutation<CreatePreventionCategory, CreatePreventionCategoryVariables>(
        mutations.CreatePreventionCategory
    )

    const run = (variables: CreatePreventionCategoryVariables) => {
        return runMutation(doCreatePreventionCategory, variables, 'createPreventionCategory', {
            queriesToRefetch: [createQueryToRefetch(queries.GetPreventionCategories_Minimal, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdatePreventionCategory = () => {
    const [doUpdatePreventionCategory] = useMutation<UpdatePreventionCategory, UpdatePreventionCategoryVariables>(
        mutations.UpdatePreventionCategory
    )

    const run = (variables: UpdatePreventionCategoryVariables) => {
        return runMutation(doUpdatePreventionCategory, variables, 'updatePreventionCategory')
    }

    return {
        run,
    }
}

export const useDeletePreventionCategory = () => {
    const [doDeletePreventionCategory] = useMutation<DeletePreventionCategory, DeletePreventionCategoryVariables>(
        mutations.DeletePreventionCategory
    )

    const run = (variables: DeletePreventionCategoryVariables) => {
        return runMutation(doDeletePreventionCategory, variables, 'deletePreventionCategory', {
            queriesToRefetch: [createQueryToRefetch(queries.GetPreventionCategories_Minimal, {})],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * INSURANCES
 *
 ******************/

export const useCreateInsurance = () => {
    const [doCreateInsurance] = useMutation<CreateInsurance, CreateInsuranceVariables>(mutations.CreateInsurance)

    const run = (variables: CreateInsuranceVariables) => {
        return runMutation(doCreateInsurance, variables, 'createInsurance', {
            queriesToRefetch: [createQueryToRefetch(queries.GetInsurances_Minimal, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdateInsurance = () => {
    const [doUpdateInsurance] = useMutation<UpdateInsurance, UpdateInsuranceVariables>(mutations.UpdateInsurance)

    const run = (variables: UpdateInsuranceVariables) => {
        return runMutation(doUpdateInsurance, variables, 'updateInsurance')
    }

    return {
        run,
    }
}

export const useDeleteInsurance = () => {
    const [doDeleteInsurance] = useMutation<DeleteInsurance, DeleteInsuranceVariables>(mutations.DeleteInsurance)

    const run = (variables: DeleteInsuranceVariables) => {
        return runMutation(doDeleteInsurance, variables, 'deleteInsurance', {
            queriesToRefetch: [createQueryToRefetch(queries.GetInsurances_Minimal, {})],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * INSURERS
 *
 ******************/

export const useCreateInsurer = () => {
    const [doCreateInsurer] = useMutation<CreateInsurer, CreateInsurerVariables>(mutations.CreateInsurer)

    const run = (variables: CreateInsurerVariables) => {
        return runMutation(doCreateInsurer, variables, 'createInsurer', {
            queriesToRefetch: [createQueryToRefetch(queries.GetInsurers_Minimal, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdateInsurer = () => {
    const [doUpdateInsurer] = useMutation<UpdateInsurer, UpdateInsurerVariables>(mutations.UpdateInsurer)

    const run = (variables: UpdateInsurerVariables) => {
        return runMutation(doUpdateInsurer, variables, 'updateInsurer')
    }

    return {
        run,
    }
}

export const useDeleteInsurer = () => {
    const [doDeleteInsurer] = useMutation<DeleteInsurer, DeleteInsurerVariables>(mutations.DeleteInsurer)

    const run = (variables: DeleteInsurerVariables) => {
        return runMutation(doDeleteInsurer, variables, 'deleteInsurer', {
            queriesToRefetch: [createQueryToRefetch(queries.GetInsurers_Minimal, {})],
        })
    }

    return {
        run,
    }
}

/*******************
 *
 * BROKERS
 *
 ******************/

export const useCreateBroker = () => {
    const [doCreateBroker] = useMutation<CreateBroker, CreateBrokerVariables>(mutations.CreateBroker)

    const run = (variables: CreateBrokerVariables) => {
        return runMutation(doCreateBroker, variables, 'createBroker', {
            queriesToRefetch: [createQueryToRefetch(queries.GetBrokers_Minimal, {})],
        })
    }

    return {
        run,
    }
}

export const useUpdateBroker = () => {
    const [doUpdateBroker] = useMutation<UpdateBroker, UpdateBrokerVariables>(mutations.UpdateBroker)

    const run = (variables: UpdateBrokerVariables) => {
        return runMutation(doUpdateBroker, variables, 'updateBroker')
    }

    return {
        run,
    }
}

export const useDeleteBroker = () => {
    const [doDeleteBroker] = useMutation<DeleteBroker, DeleteBrokerVariables>(mutations.DeleteBroker)

    const run = (variables: DeleteBrokerVariables) => {
        return runMutation(doDeleteBroker, variables, 'deleteBroker', {
            queriesToRefetch: [createQueryToRefetch(queries.GetBrokers_Minimal, {})],
        })
    }

    return {
        run,
    }
}
