import Place, { PlaceTabName } from '../../../../components/customer/places/place/Place'
import LoadingDisplay from '../../../../components/LoadingDisplay'
import RoutesHelper, { useRouteInput } from '../../../../helpers/RoutesHelper'
import usePlace, { ProvidePlace } from '../../../../hooks/usePlace'

const AEACustomerPlace = () => {
    const { placeId, tab } = useRouteInput(RoutesHelper.views.AEA.customer.places.Place).params
    return (
        <ProvidePlace placeId={placeId}>
            <AEACustomerPlaceLoading tab={tab} />
        </ProvidePlace>
    )
}

const AEACustomerPlaceLoading = (props: AEACustomerPlaceReadyProps) => {
    const { placeLoading } = usePlace()

    if (placeLoading) return <LoadingDisplay />
    else return <AEACustomerPlaceReady {...props} />
}

type AEACustomerPlaceReadyProps = {
    tab?: PlaceTabName
}

const AEACustomerPlaceReady = (props: AEACustomerPlaceReadyProps) => {
    return <Place {...props} />
}

export default AEACustomerPlace
