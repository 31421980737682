import { useMemo } from 'react'
import queries from '../../../../../../graphql/queries'
import { GetEnterpriseContracts_contracts } from '../../../../../../graphql/queries/typings/GetEnterpriseContracts'
import {
    GetEnterprise_Guarantees,
    GetEnterprise_GuaranteesVariables,
    GetEnterprise_Guarantees_enterprise_guarantees,
    GetEnterprise_Guarantees_enterprise_guarantees_contract,
} from '../../../../../../graphql/queries/typings/GetEnterprise_Guarantees'
import { EnumGuaranteeTarget } from '../../../../../../graphql/typings/global_types'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useEnterpriseContracts } from '../../../../../hooks/useContracts'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import useQueryWrapper from '../../../../../hooks/useQueryWrapper'
import LoadingDisplay from '../../../../LoadingDisplay'
import ContractsList from '../../../contracts/ContractsList'
import ObjectContracts, { ContractWrapper } from '../../../contracts/ObjectContracts'

const EnterpriseContracts = () => {
    const { customer } = useCustomerLoadedExisting()
    const { enterprise } = useEnterpriseLoadedExisting()
    const { contracts, contractsLoading } = useEnterpriseContracts({
        enterpriseId: enterprise.id,
        customerId: customer.id,
    })
    const queryResult = useQueryWrapper<GetEnterprise_Guarantees, GetEnterprise_GuaranteesVariables>(
        queries.GetEnterprise_Guarantees,
        {
            variables: {
                enterpriseId: enterprise.id,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (contractsLoading || queryLoading) return <LoadingDisplay />
    else if (!contracts || !responseData)
        return <ErrorDisplay message="Erreur lors du chargement des contrats de l'entreprise" debug={contracts} />
    else return <EnterpriseContractsReady contracts={contracts} guarantees={responseData.enterprise.guarantees} />
}

type EnterpriseContractsReadyProps = {
    contracts: GetEnterpriseContracts_contracts[]
    guarantees: GetEnterprise_Guarantees_enterprise_guarantees[]
}

const EnterpriseContractsReady = ({ contracts, guarantees }: EnterpriseContractsReadyProps) => {
    const { enterprise } = useEnterpriseLoadedExisting()

    return (
        <>
            <ContractsList
                title={"Contrats portés par l'entreprise"}
                contracts={contracts}
                enterpriseId={enterprise.id}
            />
            <div className="mb-5" />
            <EnterpriseGuarantees guarantees={guarantees} />
        </>
    )
}

type EnterpriseGuaranteesProps = {
    guarantees: GetEnterprise_Guarantees_enterprise_guarantees[]
}

const EnterpriseGuarantees = ({ guarantees }: EnterpriseGuaranteesProps) => {
    const { enterprise } = useEnterpriseLoadedExisting()

    const contractsWrappers: ContractWrapper[] = useMemo(() => {
        const contractsWrappers: ContractWrapper[] = []

        guarantees.forEach((guarantee) => {
            const contract = guarantee.contract

            const existingWrapper = contractsWrappers.find((wrapper) => wrapper.contract.id === contract.id)

            if (existingWrapper) {
                existingWrapper.guarantees.push(guarantee)
            } else {
                contractsWrappers.push({
                    contract,
                    guarantees: [guarantee],
                })
            }
        })

        return contractsWrappers
    }, [guarantees])

    return (
        <ObjectContracts
            title="Contrats couvrant cette entreprise"
            onSuccessText="Garanties de l'entreprise modifiées !"
            confirmText="Voulez-vous vraiment retirer cette entreprise de toutes les garanties de ce contrat ?"
            contractsWrappers={contractsWrappers}
            guaranteeTarget={{
                targetId: enterprise.id,
                targetType: EnumGuaranteeTarget.Enterprise,
            }}
        />
    )
}

export default EnterpriseContracts
