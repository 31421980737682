import queries from '../../../graphql/queries'
import {
    GetDisaster_Documents,
    GetDisaster_DocumentsVariables,
    GetDisaster_Documents_disaster
} from '../../../graphql/queries/typings/GetDisaster_Documents'
import {
    GetDisaster_Full,
    GetDisaster_FullVariables,
    GetDisaster_Full_disaster
} from '../../../graphql/queries/typings/GetDisaster_Full'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type DisasterState = {
    disaster: GetDisaster_Full_disaster | null
    disasterLoading: boolean
}

const useGetDisaster = (disasterId: string): DisasterState => {
    const queryResult = useQueryWrapper<GetDisaster_Full, GetDisaster_FullVariables>(queries.GetDisaster_Full, {
        variables: { disasterId: disasterId },
    })

    if (queryResult.error) {
        throw 'Erreur lors de la récupération du sinistre'
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const disaster = responseData && responseData.disaster ? responseData.disaster : null

        return {
            disaster,
            disasterLoading: false,
        }
    } else {
        return {
            disaster: null,
            disasterLoading: true,
        }
    }
}

type DisasterDocumentsState = {
    disaster: GetDisaster_Documents_disaster | null
    disasterLoading: boolean
}

export const useGetDisasterDocuments = (disasterId: string): DisasterDocumentsState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetDisaster_Documents, GetDisaster_DocumentsVariables>(
        queries.GetDisaster_Documents,
        {
            variables: { disasterId: disasterId },
        }
    )

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des documents du sinistre', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const disaster = responseData && responseData.disaster ? responseData.disaster : null

        return {
            disaster,
            disasterLoading: false,
        }
    } else {
        return {
            disaster: null,
            disasterLoading: true,
        }
    }
}

export default useGetDisaster
