import { PreventionCategory_Minimal } from '../../graphql/fragments/Prevention/PreventionCategory/typings/PreventionCategory_Minimal'
import { DocumentInput, EnumDocumentCategory, EnumDocumentStatus } from '../../graphql/typings/global_types'
import { Place_Minimal } from './data/models/Place'
import { Prevention_Minimal, Prevention_PlaceMinimal } from './data/models/prevention/Prevention'
import { PreventionCategory } from './data/models/prevention/PreventionCategory'
import DateHelper from './DateHelper'

type LastPreventionsProvider<T> = {
    placeId: string
    categoryId: string
    prevention: T
}

// Provides the last prevention for each place
const getLastPreventions = <T extends Prevention_PlaceMinimal>(preventions: T[]): T[] => {
    const lastPreventions: LastPreventionsProvider<T>[] = []

    preventions.forEach((prevention) => {
        const existingPrevention = lastPreventions.find(
            (lastPrev) =>
                lastPrev.placeId === prevention.place.id && lastPrev.categoryId === prevention.preventionCategory.id
        )

        if (existingPrevention) {
            const existingStartAtDate = new Date(existingPrevention.prevention.startAt)
            const newPrevStartAtDate = new Date(prevention.startAt)

            if (newPrevStartAtDate > existingStartAtDate) existingPrevention.prevention = prevention
        } else {
            lastPreventions.push({
                placeId: prevention.place.id,
                categoryId: prevention.preventionCategory.id,
                prevention: prevention,
            })
        }
    })

    return lastPreventions.map((lastPrev) => lastPrev.prevention)
}

const getAllPreventionCategories = (preventions: Prevention_PlaceMinimal[]): PreventionCategory_Minimal[] => {
    const allPreventionCategories: PreventionCategory[] = []

    preventions.forEach((prevention) => {
        const existingPrevCat = allPreventionCategories.find(
            (prevCat) => prevCat.id === prevention.preventionCategory.id
        )

        if (!existingPrevCat) allPreventionCategories.push(prevention.preventionCategory)
    })

    return allPreventionCategories
}

export type PreventionByCategories = {
    place: Place_Minimal
    categoriesData: {
        [key: string]: Prevention_Minimal
    }
}

const getLastPreventionsByCategories = (preventions: Prevention_PlaceMinimal[]): PreventionByCategories[] => {
    const activePreventions = getLastPreventions(preventions)
    const preventionsByCategories: PreventionByCategories[] = []

    activePreventions.forEach((prevention) => {
        const existingPrevByCat = preventionsByCategories.find(
            (prevByCat) => prevByCat.place.id === prevention.place.id
        )

        if (existingPrevByCat) {
            existingPrevByCat.categoriesData = {
                ...existingPrevByCat.categoriesData,
                [prevention.preventionCategory.id]: prevention,
            }
        } else {
            preventionsByCategories.push({
                place: prevention.place,
                categoriesData: {
                    [prevention.preventionCategory.id]: prevention,
                },
            })
        }
    })

    return preventionsByCategories
}

export enum PreventionCriticality {
    OK = 'ok',
    URGENT = 'urgent',
    PAST = 'past',
}

const getPreventionCriticality = (prevention: Prevention_Minimal): PreventionCriticality => {
    const now = new Date()
    const delay_in_months = 2
    const endAtDate = new Date(prevention.endAt)
    const urgentDeadline = new Date(endAtDate)
    urgentDeadline.setMonth(urgentDeadline.getMonth() - delay_in_months)

    if (now < urgentDeadline) return PreventionCriticality.OK
    else if (now < endAtDate) return PreventionCriticality.URGENT
    else return PreventionCriticality.PAST
}

const getPreventionCriticalityColor = (criticality: PreventionCriticality): string => {
    if (criticality === PreventionCriticality.OK) {
        return 'unset'
    } else if (criticality === PreventionCriticality.URGENT) {
        return 'orange'
    } else if (criticality === PreventionCriticality.PAST) {
        return 'red'
    } else return 'unset'
}

const getPreventionCriticalityText = (prevention: Prevention_Minimal) => {
    const { fromServertoString } = DateHelper()

    const startDateString = fromServertoString(prevention.startAt)
    const endDateString = fromServertoString(prevention.endAt)
    const criticality = getPreventionCriticality(prevention)

    return criticality === PreventionCriticality.OK
        ? `Réalisé - ${startDateString}`
        : criticality === PreventionCriticality.URGENT
        ? `A renouveler avant le ${endDateString}`
        : `Expiré depuis le ${endDateString}`
}

const formatDocumentInput = (title: string, fileDescriptor: string): DocumentInput => {
    return {
        title,
        category: EnumDocumentCategory.prevention,
        status: EnumDocumentStatus.confirmed,
        fileDescriptor,
    }
}

const EnterpriseHelper = {
    getLastPreventions,
    getAllPreventionCategories,
    getLastPreventionsByCategories,
    getPreventionCriticality,
    getPreventionCriticalityColor,
    getPreventionCriticalityText,
    formatDocumentInput,
}

export default EnterpriseHelper
