import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useState } from 'react'
import { EnumDisasterPaymentStatus } from '../../../../../../graphql/typings/global_types'
import { isStringInputValid } from '../../../../../../utilsTs'
import { DisasterPayment_Minimal } from '../../../../../helpers/data/models/disaster/DisasterPayment'
import DisasterHelper from '../../../../../helpers/DisasterHelper'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useDisasterLoadedExisting } from '../../../../../hooks/useDisaster'
import { useCreateDisasterPayment, useUpdateDisasterPayment } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import MoneyInput from '../../../../customInputs/MoneyInput'
import { Dialog } from '../../../../Dialog'

type PaymentDialogProps = {
    payment?: DisasterPayment_Minimal
    open: boolean
    closeDialog: () => void
    onDone: (disasterPayment: DisasterPayment_Minimal) => void
}

type Data = {
    amount: number | null
    paid_at: string | null
    scheduled_at: string | null
    description: string | null
    status: EnumDisasterPaymentStatus | null
}

const emptyData: Data = {
    amount: null,
    paid_at: null,
    scheduled_at: null,
    description: null,
    status: null,
}

const PaymentDialog = ({ payment, onDone, open, closeDialog }: PaymentDialogProps) => {
    const [data, setData] = useState<Data>(
        payment
            ? {
                  amount: payment.amount,
                  scheduled_at: payment.scheduled_at,
                  paid_at: payment.paid_at,
                  description: payment.description,
                  status: payment.status,
              }
            : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const { errorAlert } = useErrorService()
    const { disaster } = useDisasterLoadedExisting()

    const createDisasterPaymentMutation = useCreateDisasterPayment()
    const updateDisasterPaymentMutation = useUpdateDisasterPayment()

    const handleDescriptionChange = (value: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                description: value,
            }
        })
    }

    const handleAmountChange = (value: string) => {
        const amount = parseFloat(value)

        setData((oldData) => {
            return {
                ...oldData,
                amount,
            }
        })
    }

    const handleStatusChange = (value: string) => {
        const status = value as EnumDisasterPaymentStatus

        setData((oldData) => {
            return {
                ...oldData,
                status,
            }
        })
    }

    const handleDateChange = (date: MaterialUiPickersDate, prop: 'scheduled_at' | 'paid_at') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: date,
            }
        })
    }

    const handleSubmit = async () => {
        if (data.amount === null) return
        if (data.description === null) return
        if (data.status === null) return

        setLoading(true)

        try {
            if (payment) {
                //Update

                const response = await updateDisasterPaymentMutation.run({
                    id: payment.id,
                    disasterId: disaster.id,
                    amount: data.amount,
                    scheduledAt: data.scheduled_at,
                    description: data.description,
                    paidAt: data.paid_at,
                    status: data.status,
                })
                if (response.disasterPayment) {
                    onDone(response.disasterPayment)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du paiement'
                }
            } else {
                //Create

                const response = await createDisasterPaymentMutation.run({
                    disasterId: disaster.id,
                    amount: data.amount,
                    scheduledAt: data.scheduled_at,
                    description: data.description,
                    paidAt: data.paid_at,
                    status: data.status,
                })

                if (response.disasterPayment) {
                    onDone(response.disasterPayment)
                } else {
                    throw 'Une erreur est survenue pendant la création du paiement'
                }
                
                setData(emptyData)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = data.amount !== null && isStringInputValid(data.description) && data.status !== null

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un paiement</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Les paiements sont les échéances de versement notifiés par l'assurance.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Libellé *"
                    type="text"
                    fullWidth
                    value={data.description || ''}
                    onChange={(event) => handleDescriptionChange(event.target.value)}
                />
                <MoneyInput
                    margin="dense"
                    id="amount"
                    label="Montant *"
                    fullWidth
                    value={data.amount || ''}
                    onChange={(event) => handleAmountChange(event.target.value)}
                />
                <TextField
                    className="mt-5"
                    name="status"
                    margin="dense"
                    label="Statut"
                    fullWidth
                    select
                    onChange={(event) => handleStatusChange(event.target.value)}
                    value={data.status}>
                    {Object.values(EnumDisasterPaymentStatus).map((status, index) => {
                        return (
                            <MenuItem value={status} key={index}>
                                {DisasterHelper.getDisasterPaymentStatusLabel(status)}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <DatePicker
                    fullWidth
                    margin="dense"
                    id="mui-pickers-date"
                    label="Echéance de paiement"
                    value={data.scheduled_at}
                    onChange={(date) => handleDateChange(date, 'scheduled_at')}
                    format="dd/MM/yyyy"
                    clearable={true}
                    clearLabel="Supprimer"
                    autoOk={true}
                />
                <DatePicker
                    fullWidth
                    margin="dense"
                    id="mui-pickers-date"
                    label="Paiement réalisé le"
                    value={data.paid_at}
                    onChange={(date) => handleDateChange(date, 'paid_at')}
                    format="dd/MM/yyyy"
                    clearable={true}
                    clearLabel="Supprimer"
                    autoOk={true}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default PaymentDialog
