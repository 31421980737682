import { useParams } from 'react-router-dom'
import { ContractTabName } from '../components/customer/contracts/contract/Contract'
import { DisasterTabName } from '../components/customer/disasters/disaster/Disaster'
import { DocumentTabName } from '../components/customer/documents/Documents'
import { EnterpriseTabName } from '../components/customer/enterprises/enterprise/EnterpriseZone'
import { PlaceTabName } from '../components/customer/places/place/Place'
import { VehicleTabName } from '../components/customer/vehicles/vehicle/Vehicle'

export type HistoryRoute = {
    pathname: string
}

export type RouteDescriptor<TParams extends { [key: string]: string | undefined } | undefined = undefined> = {
    path: string
    to: TParams extends undefined ? () => HistoryRoute : (params: TParams) => HistoryRoute
}

const zonePaths = {
    common: '',
    root: '/admin',
    aea: '/clients',
    customer: '/client',
    external: '/external',
}

const aeaZonesPaths = {
    rootZone: `${zonePaths.root}`,
    customerZone: `${zonePaths.aea}/:customerId`,
    // home: `${zonePaths.aea}/:customerId`,
    // enterprises: `${zonePaths.aea}/:customerId/enterprises`,
    // contracts: `${zonePaths.aea}/:customerId/contracts`,
    // disasters: `${zonePaths.aea}/:customerId/disasters`,
    // documents: `${zonePaths.aea}/:customerId/documents`,
    // prevention: `${zonePaths.aea}/:customerId/prevention`,
    // roadmap: `${zonePaths.aea}/:customerId/roadmap`,
    // users: `${zonePaths.aea}/:customerId/users`,
}

const customerZonesPaths = {
    // home: `${zonePaths.customer}`,
    // contracts: `${zonePaths.customer}/contracts`,
    // disasters: `${zonePaths.customer}/disasters`,
    // documents: `${zonePaths.customer}/documents`,
    // prevention: `${zonePaths.customer}/prevention`,
    // roadmap: `${zonePaths.customer}/roadmap`,
}

export type ParamsOf<TRouteDescriptor> = TRouteDescriptor extends RouteDescriptor<infer S> ? S : never

export function useRouteInput<TDescriptor extends RouteDescriptor<any>>(descriptor: TDescriptor) {
    const params = useParams<ParamsOf<TDescriptor>>()
    return { params }
}

const Root: RouteDescriptor = {
    path: `/`,
    to: () => ({
        pathname: `/`,
    }),
}

const Login: RouteDescriptor = {
    path: `${zonePaths.common}`,
    to: () => ({
        pathname: `${zonePaths.common}`,
    }),
}

const ForgotPassword: RouteDescriptor = {
    path: `${zonePaths.common}/forgot`,
    to: () => ({
        pathname: `${zonePaths.common}/forgot`,
    }),
}

/**********************
 * External
 *********************/

const AccountConfirm: RouteDescriptor = {
    path: `${zonePaths.external}/confirm`,
    to: () => ({
        pathname: `${zonePaths.external}/confirm`,
    }),
}

const PasswordChange: RouteDescriptor = {
    path: `${zonePaths.external}/password`,
    to: () => ({
        pathname: `${zonePaths.external}/password`,
    }),
}

/***********
 * AEA
 **********/

const AEAHome: RouteDescriptor = {
    path: `${zonePaths.root}/dashboard`,
    to: () => ({
        pathname: `${zonePaths.root}/dashboard`,
    }),
}

const AEACustomers: RouteDescriptor = {
    path: `${zonePaths.root}/clients`,
    to: () => ({
        pathname: `${zonePaths.root}/clients`,
    }),
}

const AEAAdministrators: RouteDescriptor = {
    path: `${zonePaths.root}/admins`,
    to: () => ({
        pathname: `${zonePaths.root}/admins`,
    }),
}

const AEAInsurances: RouteDescriptor = {
    path: `${zonePaths.root}/assurances`,
    to: () => ({
        pathname: `${zonePaths.root}/assurances`,
    }),
}

const AEAEvents: RouteDescriptor = {
    path: `${zonePaths.root}/evenements`,
    to: () => ({
        pathname: `${zonePaths.root}/evenements`,
    }),
}

const AEAPreventionCategories: RouteDescriptor = {
    path: `${zonePaths.root}/categories_prevention`,
    to: () => ({
        pathname: `${zonePaths.root}/categories_prevention`,
    }),
}

const AEADisasterCategories: RouteDescriptor = {
    path: `${zonePaths.root}/categories_sinistres`,
    to: () => ({
        pathname: `${zonePaths.root}/categories_sinistres`,
    }),
}

const AEAGuaranteeCategories: RouteDescriptor = {
    path: `${zonePaths.root}/categories_garanties`,
    to: () => ({
        pathname: `${zonePaths.root}/categories_garanties`,
    }),
}

/**********************
 * AEA > Customer (Root)
 *********************/

const AEACustomerHome: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/dashboard`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/dashboard`,
    }),
}

const AEACustomerAdminContract: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/contrat_admin`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/contrat_admin`,
    }),
}

const AEACustomerEnterprises: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/entreprises`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/entreprises`,
    }),
}

const AEACustomerEnterprise: RouteDescriptor<{
    customerId: string
    enterpriseId: string
    tab?: EnterpriseTabName
}> = {
    path: `${zonePaths.aea}/:customerId/entreprises/:enterpriseId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/entreprises/${params.enterpriseId}${
            params.tab ? `/${params.tab}` : ''
        }`,
    }),
}

const AEACustomerOrgChart: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/orgchart`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/:customerId/orgchart`,
    }),
}

const AEACustomerContracts: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/contrats`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/contrats`,
    }),
}

const AEACustomerContract: RouteDescriptor<{
    customerId: string
    contractId: string
    tab?: ContractTabName
}> = {
    path: `${zonePaths.aea}/:customerId/contrats/:contractId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/contrats/${params.contractId}${
            params.tab ? `/${params.tab}` : ''
        }`,
    }),
}

const AEACustomerPlaces: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/locaux`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/locaux`,
    }),
}

const AEACustomerPlace: RouteDescriptor<{
    customerId: string
    placeId: string
    tab?: PlaceTabName
}> = {
    path: `${zonePaths.aea}/:customerId/locaux/:placeId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/locaux/${params.placeId}${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const AEACustomerVehicles: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/vehicules`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/vehicules`,
    }),
}

const AEACustomerVehicle: RouteDescriptor<{
    customerId: string
    vehicleId: string
    tab?: VehicleTabName
}> = {
    path: `${zonePaths.aea}/:customerId/vehicules/:vehicleId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/vehicules/${params.vehicleId}${
            params.tab ? `/${params.tab}` : ''
        }`,
    }),
}

const AEACustomerSites: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/sites`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/sites`,
    }),
}

const AEACustomerDisasters: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/sinistres`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/sinistres`,
    }),
}
const AEACustomerDisaster: RouteDescriptor<{ customerId: string; disasterId: string; tab?: DisasterTabName }> = {
    path: `${zonePaths.aea}/:customerId/sinistres/:disasterId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/sinistres/${params.disasterId}${
            params.tab ? `/${params.tab}` : ''
        }`,
    }),
}

const AEACustomerDocuments: RouteDescriptor<{ customerId: string; tab?: DocumentTabName }> = {
    path: `${zonePaths.aea}/:customerId/documents/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/documents${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const AEACustomerPreventions: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/preventions`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/preventions`,
    }),
}

const AEACustomerPrevention: RouteDescriptor<{ customerId: string; placeId: string; categoryId: string }> = {
    path: `${zonePaths.aea}/:customerId/preventions/:placeId/:categoryId`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/preventions/${params.placeId}/${params.categoryId}`,
    }),
}

const AEACustomerRoadmap: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/roadmap`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/roadmap`,
    }),
}

const AEACustomerUsers: RouteDescriptor<{ customerId: string }> = {
    path: `${zonePaths.aea}/:customerId/utilisateurs`,
    to: (params) => ({
        pathname: `${zonePaths.aea}/${params.customerId}/utilisateurs`,
    }),
}

/*************************
 * AEA > Customer > Enterprise
 ************************/

// const AEACustomerEnterpriseDisaster: RouteDescriptor<{
//     customerId: string
//     enterpriseId: string
//     disasterId: string
//     tab?: DisasterTabName
// }> = {
//     path: `${zonePaths.aea}/:customerId/entreprises/:enterpriseId/sinistre/:disasterId/:tab?`,
//     to: (params) => ({
//         pathname: `${zonePaths.aea}/${params.customerId}/entreprises/${params.enterpriseId}/sinistre/${
//             params.disasterId
//         }/${params.tab ? `/${params.tab}` : ''}`,
//     }),
// }

// const AEACustomerEnterpriseContract: RouteDescriptor<{
//     customerId: string
//     enterpriseId: string
//     contractId: string
//     tab?: ContractTabName
// }> = {
//     path: `${zonePaths.aea}/:customerId/entreprises/:enterpriseId/contrat/:contractId/:tab?`,
//     to: (params) => ({
//         pathname: `${zonePaths.aea}/${params.customerId}/entreprises/${params.enterpriseId}/contrat/${
//             params.contractId
//         }/${params.tab ? `/${params.tab}` : ''}`,
//     }),
// }

/***********
 * Customer
 **********/

const CustomerHome: RouteDescriptor = {
    path: `${zonePaths.customer}`,
    to: () => ({
        pathname: `${zonePaths.customer}`,
    }),
}

const CustomerAdminContract: RouteDescriptor = {
    path: `${zonePaths.customer}/contract_admin`,
    to: () => ({
        pathname: `${zonePaths.customer}/contract_admin`,
    }),
}

const CustomerEnterprises: RouteDescriptor = {
    path: `${zonePaths.customer}/entreprises`,
    to: () => ({
        pathname: `${zonePaths.customer}/entreprises`,
    }),
}

const CustomerEnterprise: RouteDescriptor<{
    enterpriseId: string
    tab?: EnterpriseTabName
}> = {
    path: `${zonePaths.customer}/enterprises/:enterpriseId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/enterprises/${params.enterpriseId}${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const CustomerOrgChart: RouteDescriptor = {
    path: `${zonePaths.customer}/orgchart`,
    to: () => ({
        pathname: `${zonePaths.customer}/orgchart`,
    }),
}

const CustomerContracts: RouteDescriptor = {
    path: `${zonePaths.customer}/contrats`,
    to: () => ({
        pathname: `${zonePaths.customer}/contrats`,
    }),
}

const CustomerContract: RouteDescriptor<{
    contractId: string
    tab?: ContractTabName
}> = {
    path: `${zonePaths.customer}/contrats/:contractId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/contrats/${params.contractId}${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const CustomerVehicles: RouteDescriptor = {
    path: `${zonePaths.customer}/vehicules`,
    to: () => ({
        pathname: `${zonePaths.customer}/vehicules`,
    }),
}

const CustomerVehicle: RouteDescriptor<{
    vehicleId: string
    tab?: VehicleTabName
}> = {
    path: `${zonePaths.customer}/vehicules/:vehicleId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/vehicules/${params.vehicleId}${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const CustomerPlaces: RouteDescriptor = {
    path: `${zonePaths.customer}/locaux`,
    to: () => ({
        pathname: `${zonePaths.customer}/locaux`,
    }),
}

const CustomerPlace: RouteDescriptor<{
    placeId: string
    tab?: PlaceTabName
}> = {
    path: `${zonePaths.customer}/locaux/:placeId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/locaux/${params.placeId}${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const CustomerDisasters: RouteDescriptor = {
    path: `${zonePaths.customer}/sinistres`,
    to: () => ({
        pathname: `${zonePaths.customer}/sinistres`,
    }),
}

const CustomerDisaster: RouteDescriptor<{
    disasterId: string
    tab?: DisasterTabName
}> = {
    path: `${zonePaths.customer}/sinistres/:disasterId/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/sinistres/${params.disasterId}${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const CustomerDocuments: RouteDescriptor<{
    tab?: DocumentTabName
}> = {
    path: `${zonePaths.customer}/documents/:tab?`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/documents${params.tab ? `/${params.tab}` : ''}`,
    }),
}

const CustomerPreventions: RouteDescriptor = {
    path: `${zonePaths.customer}/preventions`,
    to: () => ({
        pathname: `${zonePaths.customer}/preventions`,
    }),
}

const CustomerPrevention: RouteDescriptor<{
    placeId: string
    categoryId: string
}> = {
    path: `${zonePaths.customer}/preventions/:placeId/:categoryId`,
    to: (params) => ({
        pathname: `${zonePaths.customer}/preventions/${params.placeId}/${params.categoryId}`,
    }),
}

const CustomerRoadmap: RouteDescriptor = {
    path: `${zonePaths.customer}/roadmap`,
    to: () => ({
        pathname: `${zonePaths.customer}/roadmap`,
    }),
}

const CustomerUsers: RouteDescriptor = {
    path: `${zonePaths.customer}/utilisateurs`,
    to: () => ({
        pathname: `${zonePaths.customer}/utilisateurs`,
    }),
}

const RoutesHelper = {
    bazeZones: zonePaths,
    aeaZones: aeaZonesPaths,
    customerZones: customerZonesPaths,
    views: {
        Login,
        ForgotPassword,
        external: {
            AccountConfirm,
            PasswordChange,
        },
        Root,
        AEA: {
            Home: AEAHome,
            customers: {
                Home: AEACustomers,
            },
            admins: {
                Home: AEAAdministrators,
            },
            insurances: {
                Home: AEAInsurances,
            },
            events: {
                Home: AEAEvents,
            },
            preventionCategories: {
                Home: AEAPreventionCategories,
            },
            disasterCategories: {
                Home: AEADisasterCategories,
            },
            guaranteeCategories: {
                Home: AEAGuaranteeCategories,
            },
            customer: {
                Home: AEACustomerHome,
                AdminContract: {
                    Home: AEACustomerAdminContract,
                },
                contracts: {
                    Home: AEACustomerContracts,
                    Contract: AEACustomerContract,
                },
                vehicles: {
                    Home: AEACustomerVehicles,
                    Vehicle: AEACustomerVehicle,
                },
                places: {
                    Home: AEACustomerPlaces,
                    Place: AEACustomerPlace,
                },
                sites: {
                    Home: AEACustomerSites,
                },
                disasters: {
                    Home: AEACustomerDisasters,
                    Disaster: AEACustomerDisaster,
                },
                Documents: AEACustomerDocuments,
                preventions: {
                    Home: AEACustomerPreventions,
                    Prevention: AEACustomerPrevention,
                },
                Roadmap: AEACustomerRoadmap,
                Users: AEACustomerUsers,
                enterprises: {
                    Home: AEACustomerEnterprises,
                    Enterprise: AEACustomerEnterprise,
                },
                orgChart: {
                    Home: AEACustomerOrgChart,
                },
            },
        },
        Customer: {
            Home: CustomerHome,
            AdminContract: {
                Home: CustomerAdminContract,
            },
            enterprises: {
                Home: CustomerEnterprises,
                Enterprise: CustomerEnterprise,
            },
            orgChart: {
                Home: CustomerOrgChart,
            },
            contracts: {
                Home: CustomerContracts,
                Contract: CustomerContract,
            },
            vehicles: {
                Home: CustomerVehicles,
                Vehicle: CustomerVehicle,
            },
            places: {
                Home: CustomerPlaces,
                Place: CustomerPlace,
            },
            disasters: {
                Home: CustomerDisasters,
                Disaster: CustomerDisaster,
            },
            Documents: CustomerDocuments,
            preventions: {
                Home: CustomerPreventions,
                Prevention: CustomerPrevention,
            },
            Roadmap: CustomerRoadmap,
            Users: CustomerUsers,
        },
    },
}

export default RoutesHelper
