import queries from '../../../graphql/queries'
import { GetFleets_Minimal, GetFleets_MinimalVariables } from '../../../graphql/queries/typings/GetFleets_Minimal'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Fleet_Minimal } from './models/fleet/Fleet'

type FleetsData = {
    fleets: Fleet_Minimal[] | null
    fleetsLoading: boolean
}

type GetFleetsProps = {
    customerId: string
    enterpriseId?: string
}

const useGetFleets = ({ customerId, enterpriseId }: GetFleetsProps): FleetsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetFleets_Minimal, GetFleets_MinimalVariables>(queries.GetFleets_Minimal, {
        variables: {
            customerId,
            enterpriseId,
        },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const fleets = responseData && responseData.fleets ? responseData.fleets : null

        return {
            fleets,
            fleetsLoading: false,
        }
    } else {
        return {
            fleets: null,
            fleetsLoading: true,
        }
    }
}

export default useGetFleets
