import queries from '../../../graphql/queries'
import {
    GetGood_Documents,
    GetGood_DocumentsVariables,
    GetGood_Documents_good
} from '../../../graphql/queries/typings/GetGood_Documents'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type GoodData = {
    good: GetGood_Documents_good | null
    goodLoading: boolean
}

type GetGoodDocumentsProps = {
    id: string
}

const useGetGoodDocuments = ({ id }: GetGoodDocumentsProps): GoodData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetGood_Documents, GetGood_DocumentsVariables>(queries.GetGood_Documents, {
        variables: {
            id,
        },
    })

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const good = responseData && responseData.good ? responseData.good : null

        return {
            good,
            goodLoading: false,
        }
    } else {
        return {
            good: null,
            goodLoading: true,
        }
    }
}

export default useGetGoodDocuments
