import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Enterprise_Full, Enterprise_Minimal } from '../../../../../helpers/data/models/Enterprise'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import useNavContext from '../../../../../hooks/useNavContext'
import useSnackBar from '../../../../../hooks/useSnackBar'
import Panel from '../../../../Panel'
import TableHeader from '../../../../TableHeader'
import EnterpriseRelationDialog, { EnterpriseRelationDialogMode } from './EnterpriseRelationDialog'

type EditRelationData =
    | {
          open: false
      }
    | {
          open: true
          mode: EnterpriseRelationDialogMode
      }

const EnterpriseGeneralRelations = () => {
    const { enterprise } = useEnterpriseLoadedExisting()
    const history = useHistory()
    const { getEnterpriseRoute } = useNavContext()
    const { openSnack } = useSnackBar()

    const [dialogData, setDialogData] = useState<EditRelationData>({ open: false })

    const enterpriseDaughters = enterprise.children
    const enterpriseParents = enterprise.parents

    const openEdit = (type: EnterpriseRelationDialogMode) => {
        setDialogData({ open: true, mode: type })
    }

    const goToEnterprise = (enterpriseID: string) => {
        history.push(getEnterpriseRoute(enterprise.id))
    }

    const onLinkDone = () => {
        openSnack({
            message: 'Relations mises à jour !',
            type: 'success',
        })
    }

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Panel>
                        <Fragment>
                            <TableHeader title={'Entreprises filles'} editClick={() => openEdit('daughter')} />
                            {enterpriseDaughters.length === 0 && <div className="p-4">Aucune entreprise fille</div>}
                            {enterpriseDaughters.length > 0 && (
                                <Table>
                                    <TableBody>
                                        {enterpriseDaughters.map((enterprise, ind) => (
                                            <TableRow
                                                key={ind}
                                                hover
                                                onClick={() => goToEnterprise(enterprise.id)}
                                                style={{ cursor: 'pointer' }}>
                                                <TableCell className="pl-4">{enterprise.title}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Fragment>
                    </Panel>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Panel>
                        <Fragment>
                            <TableHeader title={'Entreprises mères'} editClick={() => openEdit('mother')} />
                            {enterpriseParents.length === 0 && <div className="p-4">Aucune entreprise mère</div>}
                            {enterpriseParents.length > 0 && (
                                <Table>
                                    <TableBody>
                                        {enterpriseParents.map((enterprise, ind) => (
                                            <TableRow
                                                key={ind}
                                                hover
                                                onClick={() => goToEnterprise(enterprise.id)}
                                                style={{ cursor: 'pointer' }}>
                                                <TableCell className="pl-4">{enterprise.title}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Fragment>
                    </Panel>
                </Grid>
            </Grid>
            {dialogData.open && (
                <EnterpriseRelationDialog
                    open={dialogData.open}
                    enterprise={enterprise}
                    mode={dialogData.mode}
                    closeDialog={() => setDialogData({ open: false })}
                    onDone={onLinkDone}
                />
            )}
        </Fragment>
    )
}

export default EnterpriseGeneralRelations
