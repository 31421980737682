import queries from '../../../graphql/queries'
import {
    GetVehicle_Documents,
    GetVehicle_DocumentsVariables,
} from '../../../graphql/queries/typings/GetVehicle_Documents'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { Vehicle_Documents } from './models/fleet/Vehicle'

type VehicleData = {
    vehicle: Vehicle_Documents | null
    vehicleLoading: boolean
}

type GetVehicleDocumentsProps = {
    id: string
}

const useGetVehicleDocuments = ({ id }: GetVehicleDocumentsProps): VehicleData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetVehicle_Documents, GetVehicle_DocumentsVariables>(
        queries.GetVehicle_Documents,
        {
            variables: {
                id,
            },
        }
    )

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des clients', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const vehicle = responseData && responseData.vehicle ? responseData.vehicle : null

        return {
            vehicle,
            vehicleLoading: false,
        }
    } else {
        return {
            vehicle: null,
            vehicleLoading: true,
        }
    }
}

export default useGetVehicleDocuments
