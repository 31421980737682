import { GraphQLError } from 'graphql'
import { useAuth } from '../../hooks/useAuth'
import useSnackBar from '../../hooks/useSnackBar'

const isQueryUserTokenError = (queryError: GraphQLError) => {
    if (!queryError.extensions) return false

    return queryError.extensions.code === 'USER_NOT_FOUND'
}

export const useQueryErrorManager = () => {
    const auth = useAuth()
    const { openSnack } = useSnackBar()

    const manageQueryError = (error: any) => {
        if (error.graphQLErrors.length > 0) {
            const graphQLError = error.graphQLErrors[0]

            if (ErrorHelper.isQueryUserTokenError(graphQLError)) {
                openSnack({
                    message: 'Session expirée : redirection vers la page de connexion',
                    type: 'error',
                })
                auth.clearUserData()
            }
        }
    }

    return {
        manageQueryError,
    }
}

const ErrorHelper = {
    isQueryUserTokenError,
}

export default ErrorHelper
