import { Icon } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GetCustomers_Minimal_customers } from '../../../../../graphql/queries/typings/GetCustomers_Minimal'
import DataTable from '../../../../components/DataTable'
import { ConfirmDialog } from '../../../../components/Dialog'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { useCustomersLoadedExisting } from '../../../../hooks/useCustomers'
import { useDeleteCustomer } from '../../../../hooks/useMutations'
import useSnackBar from '../../../../hooks/useSnackBar'
import CustomerDialog from './CustomerDialog'

type DialogState =
    | {
          open: true
          customer: GetCustomers_Minimal_customers
      }
    | { open: false }

const AEACustomersList = () => {
    const history = useHistory()
    const { openSnack } = useSnackBar()
    const { customers } = useCustomersLoadedExisting()
    const deleteCustomerMutation = useDeleteCustomer()
    const { errorAlert } = useErrorService()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const onCustomerClick = (customer: GetCustomers_Minimal_customers) => {
        history.push(RoutesHelper.views.AEA.customer.Home.to({ customerId: customer.id }))
    }

    const onEditHandler = (
        customer: GetCustomers_Minimal_customers,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, customer })
    }

    const onDeleteHandler = (
        customer: GetCustomers_Minimal_customers,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, customer })
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
    ]

    const onCustomerCreated = () => {
        openSnack({
            type: 'success',
            message: `Client créé avec succès !`,
        })
    }

    const onCustomerEdited = () => {
        openSnack({
            type: 'success',
            message: `Client modifié avec succès !`,
        })
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un client`,
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const customer = openDeleteDialog.customer

            const response = await deleteCustomerMutation.run({
                id: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du client.'
            } else {
                openSnack({
                    message: 'Client supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        const customer = customers[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le client">
                    <IconButton onClick={(event) => onEditHandler(customer, event)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le client">
                    <IconButton onClick={(event) => onDeleteHandler(customer, event)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <>
            <DataTable
                title={'Clients'}
                data={customers}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                rowClick={onCustomerClick}
                actionsColumn={actionsColumn}
            />
            <CustomerDialog
                onDone={onCustomerCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
            />
            {openEditDialog.open && (
                <CustomerDialog
                    onDone={onCustomerEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    customer={openEditDialog.customer}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un client"
                    text={`Voulez-vous vraiment supprimer le client "${openDeleteDialog.customer.title}" ? Cette action est irreversible.`}
                    loading={deleteLoading}
                />
            )}
        </>
    )
}

export default AEACustomersList
