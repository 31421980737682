import queries from '../../../graphql/queries'
import { GetCommonData } from '../../../graphql/queries/typings/GetCommonData'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'

type CommonDataState = {
    commonDataLoading: boolean
    commonData: GetCommonData | null
}

const useGetCommonData = (): CommonDataState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQueryWrapper<GetCommonData>(queries.GetCommonData)

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des données communes', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const commonData = responseData && responseData ? responseData : null

        return {
            commonData,
            commonDataLoading: false,
        }
    } else {
        return {
            commonData: null,
            commonDataLoading: true,
        }
    }
}

export default useGetCommonData
