import Cytoscape from 'cytoscape'
import DagreLayout from 'cytoscape-dagre'
import { Fragment, useMemo } from 'react'
import CytoscapeComponent from 'react-cytoscapejs'
import queries from '../../../../graphql/queries'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import {
    GetCustomer_EnterprisesForChart,
    GetCustomer_EnterprisesForChartVariables,
} from '../../../../graphql/queries/typings/GetCustomer_EnterprisesForChart'
import Breadcrumb from '../../../../matx/components/Breadcrumb'
import IconHelper from '../../../helpers/IconHelper'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import useNavContext from '../../../hooks/useNavContext'
import useQueryWrapper from '../../../hooks/useQueryWrapper'
import ErrorView from '../../ErrorView'
import LoadingDisplay from '../../LoadingDisplay'

Cytoscape.use(DagreLayout)

// https://js.cytoscape.org/
// https://github.com/plotly/react-cytoscapejs

type EnterpriseBaseForChart = Pick<EnterpriseGeneric_enterprise, 'id' | 'title'>

type EnterpriseForChart = EnterpriseBaseForChart & {
    children: EnterpriseBaseForChart[]
    parents: EnterpriseBaseForChart[]
}

const OrgChart = () => {
    const { customer } = useCustomerLoadedExisting()
    const { getOrgChartRoute } = useNavContext()

    const queryResult = useQueryWrapper<GetCustomer_EnterprisesForChart, GetCustomer_EnterprisesForChartVariables>(
        queries.GetCustomer_EnterprisesForChart,
        {
            variables: {
                customerID: customer.id,
            },
        }
    )

    const content = queryResult.loading ? (
        <LoadingDisplay />
    ) : queryResult.error || !queryResult.data ? (
        <ErrorView
            message={queryResult.error?.message || 'Une erreur est survenue pendant la récupération des entreprises'}
        />
    ) : (
        <OrgChartReady enterprises={queryResult.data.project.enterprises} />
    )

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Organigramme (en cours de dev)', icon: IconHelper.elementIcons.contractIcon },
                    ]}
                    homePath={getOrgChartRoute().pathname}
                />
                {content}
            </div>
        </div>
    )
}

type OrgChartReadyProps = {
    enterprises: EnterpriseForChart[]
}

const OrgChartReady = ({ enterprises }: OrgChartReadyProps) => {
    /**
     * Piste 1 : https://github.com/ErikGartner/dTree
     * Family Tree, autorise plusieurs parents mais pas très beau, pas interactif
     *
     * Piste 2 : http://bl.ocks.org/robschmuecker/6afc2ecb05b191359862
     * Hack for Dendogram but not working when the tree is collapsed
     */

    // JSON must look like
    const elementsMaker = () => {
        const elements: cytoscape.ElementDefinition[] = []

        enterprises.forEach((enterprise) => {
            const nodeDefinition: Cytoscape.NodeDataDefinition = {
                id: enterprise.id,
                label: enterprise.title,
                grabbable: false,
            }
            elements.push({
                data: nodeDefinition,
                css: {
                    shape: 'rectangle',
                },
            })

            enterprise.children.forEach((child) => {
                const edgeDefinition: Cytoscape.EdgeDataDefinition = {
                    source: enterprise.id,
                    target: child.id,
                }
                elements.push({
                    data: edgeDefinition,
                })
            })
        })

        return elements
    }

    const elements = useMemo(() => {
        return elementsMaker()
    }, [enterprises])

    // Cytoscape options : https://github.com/cytoscape/cytoscape.js-dagre
    const layoutData = {
        name: 'dagre',
        nodeSep: 10,
        rankDir: 'LR',
        nodeDimensionsIncludeLabels: true,
    }

    return (
        <Fragment>
            <CytoscapeComponent
                elements={elements}
                layout={layoutData}
                style={{ width: 600, height: 400, border: '1px solid grey' }}
                stylesheet={[
                    {
                        selector: 'node',
                        style: {
                            backgroundColor: 'rgba(34, 42, 69)',
                            opacity: 0.92,
                            color: 'white',
                        },
                    },
                    {
                        selector: 'node[label]',
                        style: {
                            label: 'data(label)',
                            'text-valign': 'center',
                            'text-halign': 'center',
                            width: '200px',
                        },
                    },
                    {
                        selector: 'edge',
                        style: {
                            width: 3,
                            'line-color': '#6f7790',
                            'target-arrow-color': '#6f7790',
                            'target-arrow-shape': 'triangle', // there are far more options for this property here: http://js.cytoscape.org/#style/edge-arrow
                            'arrow-scale': 1.5,
                            'curve-style': 'taxi',
                        },
                    },
                ]}
            />
        </Fragment>
    )
}

export default OrgChart
