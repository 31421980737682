import queries from '../../../graphql/queries'
import {
    GetAdminDashboardData,
    GetAdminDashboardDataVariables,
} from '../../../graphql/queries/typings/GetAdminDashboardData'
import useQueryWrapper from '../../hooks/useQueryWrapper'
import { useErrorService } from '../errors/ErrorService'
import { AdminDashboardData } from './models/AdminDashboardData'

type GetAdminDashboardQuery = {
    dashboardData: AdminDashboardData | null
    dashboardDataLoading: boolean
}

export type GetAdminDashboardDataProps = GetAdminDashboardDataVariables

const useGetAdminDashboardData = (props: GetAdminDashboardDataProps): GetAdminDashboardQuery => {
    const queryResult = useQueryWrapper<GetAdminDashboardData, GetAdminDashboardDataVariables>(
        queries.GetAdminDashboardData,
        {
            variables: props,
            fetchPolicy: 'cache-and-network',
        }
    )

    const { errorAlert } = useErrorService()

    if (queryResult.error) {
        errorAlert('Erreur lors de la récupération des données du dashboard', queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const dashboardData = responseData && responseData.dashboardAea ? responseData.dashboardAea : null

        return {
            dashboardData,
            dashboardDataLoading: false,
        }
    } else {
        return {
            dashboardData: null,
            dashboardDataLoading: true,
        }
    }
}

export default useGetAdminDashboardData
