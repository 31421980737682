import React from 'react'
import { GetContract_Full_contract } from '../../../../../graphql/queries/typings/GetContract_Full'
import { BreadcrumbSegment } from '../../../../../matx/components/Breadcrumb'
import ContractHelper from '../../../../helpers/ContractHelper'
import IconHelper from '../../../../helpers/IconHelper'
import { RouteDescriptor, useRouteInput } from '../../../../helpers/RoutesHelper'
import useContract, { ProvideContract, useContractLoadedExisting } from '../../../../hooks/useContract'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import { useNavContext } from '../../../../hooks/useNavContext'
import CustomTabs, { CustomTab } from '../../../CustomTabs'
import LoadingDisplay from '../../../LoadingDisplay'
import ContractDisasters from './disasters/ContractDisasters'
import ContractDocuments from './documents/ContractDocuments'
import ContractElements from './elements/ContractElements'
import ContractGeneral from './general/ContractGeneral'
import ContractGuarantees from './guarantees/ContractGuarantees'

export type ContractTabName = 'general' | 'garanties' | 'elements' | 'sinistres' | 'documents'

const Contract = <T extends { contractId: string; tab?: ContractTabName }>({
    route,
}: {
    route: RouteDescriptor<T>
}) => {
    const { contractId, tab } = useRouteInput(route).params

    return (
        <ProvideContract contractId={contractId}>
            <ContractLoading tab={tab} />
        </ProvideContract>
    )
}

type ContractNavProps = {
    tab?: ContractTabName
}

const ContractLoading = (props: ContractNavProps) => {
    const { contractLoading } = useContract()

    if (contractLoading) return <LoadingDisplay />
    else return <ContractReady {...props} />
}

const ContractReady = ({ tab }: ContractNavProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { contract } = useContractLoadedExisting()

    const { getContractRoute, getContractsRoute } = useNavContext()

    const tabs: CustomTab<ContractTabName>[] = [
        {
            id: 'general',
            label: 'Général',
            children: <ContractGeneral />,
            to: getContractRoute(contract.id),
        },
        {
            id: 'garanties',
            label: 'Garanties',
            children: <ContractGuarantees />,
            to: getContractRoute(contract.id, 'garanties'),
        },
        {
            id: 'sinistres',
            label: 'Sinistres',
            children: <ContractDisasters customerId={customer.id} contractId={contract.id} />,
            to: getContractRoute(contract.id, 'sinistres'),
        },
        {
            id: 'elements',
            label: 'Eléments',
            children: <ContractElements />,
            to: getContractRoute(contract.id, 'elements'),
        },
        {
            id: 'documents',
            label: 'Documents',
            children: <ContractDocuments />,
            to: getContractRoute(contract.id, 'documents'),
        },
    ]

    const breadcrumbSegments: BreadcrumbSegment[] = [
        {
            name: 'Contrats et attestations',
            path: getContractsRoute().pathname,
        },
        {
            name: ContractHelper.getContractLabel(contract),
            icon: IconHelper.elementIcons.contractIcon,
            path: getContractRoute(contract.id).pathname,
        },
    ]

    return (
        <CustomTabs<GetContract_Full_contract, ContractTabName>
            breadcrumbSegments={breadcrumbSegments}
            activeTab={tab || 'general'}
            tabs={tabs}
        />
    )
}

export default Contract
