import { createContext, ReactElement, useContext } from 'react'
import { GetContract_Full_contract } from '../../graphql/queries/typings/GetContract_Full'
import useGetContract from '../helpers/data/getContract'

type ContractContext = {
    contract: GetContract_Full_contract | null
    contractLoading: boolean
}

const ContractContext = createContext<ContractContext>({
    contract: null,
    contractLoading: true,
})

type ProvideContractProps = {
    children: ReactElement
    contractId: string
}

export function ProvideContract({ children, contractId }: ProvideContractProps) {
    const contractData = useProvideContract(contractId)

    return <ContractContext.Provider value={contractData}>{children}</ContractContext.Provider>
}

export const useContract = () => {
    return useContext(ContractContext)
}

export const useContractLoaded = () => {
    const context = useContext(ContractContext)
    if (context.contractLoading) {
        console.error('Current contract is loading !')
        throw new Error('Current contract is loading')
    }

    return context
}

export const useContractLoadedExisting = () => {
    const context = useContext(ContractContext)
    if (context.contractLoading) {
        console.error('Current contract is loading !')
        throw new Error('Current contract is loading')
    }
    if (!context.contract) {
        console.error(`No contract found for id !`)
        throw new Error('No contract found for id !')
    }

    return {
        contract: context.contract,
        contractLoading: context.contractLoading,
    }
}

const useProvideContract = (contractId: string) => {
    const { contract, contractLoading } = useGetContract(contractId)

    return {
        contract,
        contractLoading,
    }
}

export default useContract
