import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { PlaceGeneric_place } from '../../../../graphql/queries/typings/PlaceGeneric'
import { SiteGeneric_site } from '../../../../graphql/queries/typings/SiteGeneric'
import { EnumUser } from '../../../../graphql/typings/global_types'
import { Place_Minimal } from '../../../helpers/data/models/Place'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import IconHelper from '../../../helpers/IconHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeletePlace } from '../../../hooks/useMutations'
import useNavContext from '../../../hooks/useNavContext'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import Panel from '../../Panel'
import PlaceDialog from './PlaceDialog'

type PlacesListProps = {
    places: PlaceForList[]
}

type DialogState =
    | {
          open: true
          place: PlaceForList
      }
    | { open: false }

type FormattedData = {
    id: string
    title: string
    address: string
    site: string
    enterprise: string
}

export type PlaceForList = Pick<PlaceGeneric_place, 'id' | 'title' | 'address' | 'wallsOwner'> & {
    enterprise: Pick<EnterpriseGeneric_enterprise, 'id' | 'address' | 'title'>
    site: Pick<SiteGeneric_site, 'id' | 'title'> | null
}

const PlacesList = ({ places }: PlacesListProps) => {
    const history = useHistory()
    const { openSnack } = useSnackBar()
    const { getPlaceRoute } = useNavContext()
    const { errorAlert } = useErrorService()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const deletePlaceMutation = useDeletePlace()
    const { customer } = useCustomerLoadedExisting()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const formatData = (): FormattedData[] => {
        return places.map((place) => {
            return {
                id: place.id,
                title: place.title,
                address: place.address || '-',
                site: place.site ? place.site.title : '-',
                enterprise: place.enterprise.title,
            }
        })
    }

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'title',
                label: 'Libellé',
            },
            {
                name: 'address',
                label: 'Adresse',
            },
            {
                name: 'site',
                label: 'Site',
            },
            {
                name: 'enterprise',
                label: 'Entreprise',
            },
        ],
        [places]
    )

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un local`,
    }

    const onRowClick = (formattedData: FormattedData) => {
        history.push(getPlaceRoute(formattedData.id))
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Local créé avec succès !`,
        })
    }

    const onEdited = () => {
        openSnack({
            message: 'Local modifié avec succès !',
            type: 'success',
        })
    }

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, place: PlaceForList) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, place })
    }

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, place: PlaceForList) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, place })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const place = openDeleteDialog.place

            const response = await deletePlaceMutation.run({
                enterpriseId: place.enterprise.id,
                id: place.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du local.'
            } else {
                openSnack({
                    message: 'Local supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const place = places[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le local">
                    <IconButton onClick={(event) => onEditClick(event, place)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le local">
                    <IconButton onClick={(event) => onDeleteClick(event, place)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const formattedData = formatData()

    return (
        <Fragment>
            <Panel>
                <DataTable<FormattedData>
                    title={'Locaux'}
                    icon={IconHelper.elementIcons.placeIcon}
                    data={formattedData}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    rowClick={onRowClick}
                    actionsColumn={actionsColumn}
                />
            </Panel>
            <PlaceDialog open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} onDone={onCreated} />
            {openEditDialog.open && (
                <PlaceDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    place={openEditDialog.place}
                    placeEnterprise={openEditDialog.place.enterprise}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un local"
                    text={`Voulez-vous vraiment supprimer le local "${openDeleteDialog.place.title}" ?`}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default PlacesList
