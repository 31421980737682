export const uniqMergeId = <T extends { id: string }>(array1: T[], array2: T[]): T[] => {
    const list: T[] = array1

    array2.forEach((element) => {
        const existing = array1.find((el) => el.id === element.id)

        if (!existing) list.push(element)
    })

    return list
}

export const filterUniqId = <T extends { id: string }>(array: T[]): T[] => {
    const result: T[] = []
    const map = new Map()
    for (const item of array) {
        if (!map.has(item.id)) {
            map.set(item.id, true) // set any value to Map
            result.push(item)
        }
    }
    return result
}

export const uniqId = (): string => {
    var n = Math.floor(Math.random() * 11)
    var k = Math.floor(Math.random() * 1000000)
    return String.fromCharCode(n) + k
}

export const isStringInputValid = (value: string | null) => {
    return value !== null && value !== ''
}

export const getMondayOfThisWeek = (): Date => {
    const date = new Date()
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
}

/*********************************************
 * ERROR HANDLER FOR SNACK => AS STRING
 *********************************************/

type _ErrorWithMessage = {
    message: string
}

const _isErrorWithMessage = (error: unknown): error is _ErrorWithMessage => {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as Record<string, unknown>).message === 'string'
    )
}

const _toErrorWithMessage = (maybeError: unknown): _ErrorWithMessage => {
    if (_isErrorWithMessage(maybeError)) return maybeError

    try {
        return new Error(JSON.stringify(maybeError))
    } catch {
        // fallback in case there's an error stringifying the maybeError
        // like with circular references for example.
        return new Error(String(maybeError))
    }
}

export const getErrorMessage = (error: unknown) => {
    return _toErrorWithMessage(error).message
}
