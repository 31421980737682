import { ReactNode, useMemo } from 'react'
import useGetFleets from '../../../../../../helpers/data/getFleets'
import ErrorDisplay from '../../../../../../helpers/errors/ErrorDisplay'
import { useCustomerLoadedExisting } from '../../../../../../hooks/useCustomer'
import LoadingDisplay from '../../../../../LoadingDisplay'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'
import DocumentManagerAssociationElementTextField from './DocumentManagerAssociationElementTextFields'

type DMA_FleetProps = DMAButtonsProps & {
    data: DMA_Data
    setData: DMA_SetData
}

const DMA_Fleet = ({ data, setData, ...buttonsProps }: DMA_FleetProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { fleets, fleetsLoading } = useGetFleets({ customerId: customer.id })

    const content: ReactNode = useMemo(() => {
        if (fleetsLoading) {
            return <LoadingDisplay size={1} />
        } else if (!fleets) {
            return <ErrorDisplay message="Erreur lors du chargement des flottes" debug={fleets} />
        } else {
            const elements = fleets.map((fleet) => {
                return { label: fleet.title, id: fleet.id }
            })

            return (
                <DocumentManagerAssociationElementTextField
                    data={data}
                    setData={setData}
                    elementLabel={'Flotte'}
                    elements={elements}
                />
            )
        }
    }, [fleets, fleetsLoading, data, setData])

    return (
        <>
            {content}
            <DMAButtons {...buttonsProps} />
        </>
    )
}

export default DMA_Fleet
