import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo, useState } from 'react'
import { GetCustomer_Users_project_users } from '../../../../graphql/queries/typings/GetCustomer_Users'
import { EnumUser } from '../../../../graphql/typings/global_types'
import IconHelper from '../../../helpers/IconHelper'
import UserHelper from '../../../helpers/UserHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteUser } from '../../../hooks/useMutations'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import UserDialog from './UserDialog'

type DialogData =
    | {
          open: false
      }
    | {
          open: true
          user: GetCustomer_Users_project_users
      }

type FormattedUser = {
    firstname: string
    lastname: string | null
    phone: string
    email: string
    accessRights: string
    baseUser: GetCustomer_Users_project_users
}

const UsersList = () => {
    const auth = useAuthUser()
    const { customer } = useCustomerLoadedExisting()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DialogData>({ open: false })

    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const { openSnack } = useSnackBar()
    const deleteUserMutation = useDeleteUser()

    const isUserAdmin = auth.user.userType === EnumUser.admin
    const users = customer.users.filter((user) => user.userType === EnumUser.user)

    const formattedUsers: FormattedUser[] = useMemo(() => {
        return users.map((user) => {
            const accessRights =
                user.restrictedToEnterprises.length > 0
                    ? UserHelper.getCustomerRestrictedEnterprises(user, customer)
                          .map((enterprise) => enterprise.title)
                          .join(', ')
                    : 'Accès global'

            return {
                firstname: user.firstname,
                lastname: user.lastname,
                phone: user.phone || '-',
                email: user.email,
                accessRights,
                baseUser: user,
            }
        })
    }, [users])

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Utilisateur créé avec succès !`,
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Utilisateur édité avec succès !`,
        })
    }

    const onEditClick = (user: GetCustomer_Users_project_users) => {
        setOpenEditDialog({ open: true, user })
    }

    const onDeleteClick = (user: GetCustomer_Users_project_users) => {
        setOpenDeleteConfirm({ open: true, user })
    }

    const onDeleteCancelled = () => {
        setOpenDeleteConfirm({ open: false })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        await deleteUserMutation.run({
            id: openDeleteConfirm.user.id,
            customerId: customer.id,
        })

        openSnack({
            type: 'success',
            message: `Utilisateur supprimé avec succès !`,
        })
        setOpenDeleteConfirm({ open: false })
        setDeleteLoading(false)
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un utilisateur`,
    }

    const accessRightsColumn: MUIDataTableColumnDef = {
        name: 'accessRights',
        label: "Droits d'accès",
    }

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'firstname',
                label: 'Prénom',
            },
            {
                name: 'lastname',
                label: 'Nom de famille',
            },
            {
                name: 'email',
                label: 'Adresse mail',
            },
            {
                name: 'phone',
                label: 'Numéro de téléphone',
            },
            ...(isUserAdmin ? [accessRightsColumn] : []),
        ],
        [formattedUsers]
    )

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isUserAdmin) return undefined

        const user = formattedUsers[dataIndex].baseUser

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier l'utilisateur">
                    <IconButton onClick={() => onEditClick(user)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer l'utilisateur">
                    <IconButton onClick={() => onDeleteClick(user)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            <DataTable<FormattedUser>
                title={`Utilisateurs de ${customer.title} `}
                icon={IconHelper.elementIcons.usersIcon}
                data={formattedUsers}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                actionsColumn={isUserAdmin ? actionsColumn : undefined}
            />
            <UserDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <UserDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    user={openEditDialog.user}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                />
            )}
            {openDeleteConfirm.open && (
                <ConfirmDialog
                    open={openDeleteConfirm.open}
                    title="Suppression d'un utilisateur"
                    text="Voulez-vous vraiment supprimer cet utilisateur ? Il n'aura plus accès à l'application"
                    onConfirmed={onDeleteConfirmed}
                    onCancelled={onDeleteCancelled}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default UsersList
