import { gql } from '@apollo/client/core'

export default gql`
    query GetEnterprise_Full($enterpriseId: ID!) {
        enterprise(id: $enterpriseId) {
            id
            address
            title
            notificationUsers {
                id
                firstname
                lastname
            }
            children {
                id
                title
            }
            parents {
                id
                title
            }
            fleets {
                id
                title
                vehicles {
                    id
                }
            }
            goods {
                id
                title
                place: local {
                    id
                    title
                }
            }
            places: locals {
                id
                title
                address
                wallsOwner
                site {
                    id
                    title
                }
            }
            vehicles {
                id
                title
                registration: matriculation
                description
                fleet {
                    id
                }
                enterprise {
                    id
                }
            }
        }
    }
`
