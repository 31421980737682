import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useNavContext from '../../../../../hooks/useNavContext'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import useSnackBar from '../../../../../hooks/useSnackBar'
import IconText from '../../../../IconText'
import Panel from '../../../../Panel'
import TableHeader from '../../../../TableHeader'
import PlaceDialog from '../../PlaceDialog'

const PlaceGeneralData = () => {
    const { place } = usePlaceLoadedExisting()
    const { openSnack } = useSnackBar()
    const history = useHistory()
    const { getEnterpriseRoute } = useNavContext()

    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Local ${place.title} modifié avec succès !`,
        })
    }

    const onEnterpriseClick = () => {
        history.push(getEnterpriseRoute(place.enterprise.id))
    }

    return (
        <Panel>
            <Fragment>
                <TableHeader title={'Informations générales'} editClick={() => setEditDialogOpen(true)} />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className="pl-4">Libellé</TableCell>
                            <TableCell>{place.title}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Adresse</TableCell>
                            <TableCell>{place.address}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Entreprise</TableCell>
                            <TableCell>
                                <IconText
                                    text={place.enterprise.title}
                                    side="right"
                                    icon="logout"
                                    iconClick={onEnterpriseClick}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Site</TableCell>
                            <TableCell>{place.site ? place.site.title : '-'}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <PlaceDialog
                    open={editDialogOpen}
                    closeDialog={() => setEditDialogOpen(false)}
                    place={place}
                    placeEnterprise={place.enterprise}
                    onDone={onEdited}
                />
            </Fragment>
        </Panel>
    )
}

export default PlaceGeneralData
