import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { EnterpriseGeneric_enterprise } from '../../../../../../graphql/queries/typings/EnterpriseGeneric'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { isStringInputValid } from '../../../../../../utilsTs'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import UserHelper from '../../../../../helpers/UserHelper'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useCreateEnterprise, useUpdateEnterprise } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import MultiSelectCheckbox from '../../../../customInputs/MultiSelectCheckbox'
import { Dialog } from '../../../../Dialog'

type EnterpriseDialogProps = {
    enterprise?: EnterpriseForDialog
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

type Data = {
    title: string
    address: string
    notificationUsersIDs: string[]
}

type EnterpriseForDialog = Pick<EnterpriseGeneric_enterprise, 'id' | 'title' | 'address'> & {
    notificationUsers: {
        id: string
    }[]
}

const EnterpriseDialog = ({ enterprise, onDone, open, closeDialog }: EnterpriseDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()

    const users = customer.users.filter((user) => user.userType === EnumUser.user)

    const emptyData: Data = {
        title: '',
        address: '',
        notificationUsersIDs: users.map((user) => user.id),
    }

    const [data, setData] = useState<Data>(
        enterprise
            ? {
                  title: enterprise.title,
                  address: enterprise.address || '',
                  notificationUsersIDs: enterprise.notificationUsers.map((user) => user.id),
              }
            : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createEnterpriseMutation = useCreateEnterprise()
    const updateEnterpriseMutation = useUpdateEnterprise()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, name: 'title' | 'address' | 'amount_employees') => {
        setData({
            ...data,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        setLoading(true)

        try {
            if (enterprise) {
                //Updating
                const response = await updateEnterpriseMutation.run({
                    id: enterprise.id,
                    title: data.title,
                    address: data.address,
                    notificationUserIds: data.notificationUsersIDs,
                })

                if (response.enterprise) {
                    onDone()
                } else {
                    throw "Une erreur est survenue pendant la mise à jour de l'entreprise"
                }
            } else {
                //Creating
                const response = await createEnterpriseMutation.run({
                    customerId: customer.id,
                    title: data.title,
                    address: data.address,
                    notificationUserIds: data.notificationUsersIDs,
                })

                if (response.enterprise) {
                    onDone()
                } else {
                    throw "Une erreur est survenue pendant la création de l'entreprise"
                }

                setData(emptyData)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const handleMultiSelectChange = (values: string[]) => {
        setData((oldData) => {
            return {
                ...oldData,
                notificationUsersIDs: values,
            }
        })
    }

    const getUserName = (id: string): string => {
        const user = users.find((user) => user.id === id)

        if (!user) return 'Bien inconnu'
        else return UserHelper.getFullName(user.firstname, user.lastname)
    }

    const isFormValid = isStringInputValid(data.title) && isStringInputValid(data.address)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'une entreprise</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom de l'entreprise *"
                    type="text"
                    fullWidth
                    value={data.title}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="address"
                    label="Adresse de l'entreprise *"
                    type="text"
                    fullWidth
                    value={data.address}
                    onChange={(event) => handleChange(event.target.value, 'address')}
                />
                <MultiSelectCheckbox
                    selectTitle={users.length === 0 ? 'Aucun utilisateur disponible' : 'Utilisateurs notifiés'}
                    name="goods"
                    value={data.notificationUsersIDs}
                    onChange={handleMultiSelectChange}
                    getLabel={getUserName}
                    fullWidth
                    disabled={users.length === 0}
                    availableIds={users.map((user) => user.id)}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default EnterpriseDialog
